import { Unpackr } from 'msgpackr'
import { create } from 'zustand'

import { createAuthToken, waitForWasm } from 'js/zklighter-js-sdk/sdk'

import { useAccountsStore } from '../accounts-store'
import { useUserStore } from '../user-store'

import { wsMessageSchema } from './schemas'
import { handleChangeDesiredMarket, handleWsMessage } from './utils'

type Encoding = 'msgpack' | 'json'

function getEncoding(): Encoding {
  // Check URL parameters first (if running in browser)
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    const encodingParam = urlParams.get('encoding')?.toLowerCase()

    // Validate the encoding parameter
    if (encodingParam === 'json' || encodingParam === 'msgpack') {
      return encodingParam
    }
  }

  // Fall back to domain-based logic
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    return 'json'
  }

  // Default to msgpack
  return 'msgpack'
}

const unpackr = new Unpackr({ int64AsNumber: true, mapsAsObjects: true })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decodeWsMessage = (e: MessageEvent<any>, encoding: Encoding) => {
  try {
    switch (encoding) {
      case 'json': {
        return JSON.parse(e.data)
      }
      case 'msgpack': {
        return unpackr.unpack(new Uint8Array(e.data))
      }
    }
  } catch {
    return null
  }
}

interface WsSubStore {
  marketIndex: number | null
  accountIndex: number | null
  poolIndexes: number[]
  ws: WebSocket | null
  wsLoading: boolean
  actions: {
    logout: () => void
    init: () => void
    deinit: () => void
    switchMarket: (newMarketIndex: number) => void
    subscribePublicPool: (publicPoolIndex: number) => void
    unsubscribePublicPool: (publicPoolIndex: number) => void
    switchAccount: (newAccountIndex: number | null) => void
  }
}

let pongTimeout: NodeJS.Timeout | null = null
let pingTimeout: NodeJS.Timeout | null = null

export const useWsSubStore = create<WsSubStore>((set, get) => ({
  marketIndex: null,
  accountIndex: null,
  poolIndexes: [],
  ws: null,
  wsLoading: false,
  actions: {
    logout() {
      const accountIndex = useUserStore.getState().accountIndex!
      useAccountsStore.setState((state) => {
        if (state.accounts[accountIndex]) {
          delete state.accounts[accountIndex]
        }

        if (state.portfolioStats[accountIndex]) {
          delete state.portfolioStats[accountIndex]
        }

        return state
      })

      useUserStore.setState({ accountIndex: null })
    },
    deinit() {
      get().ws?.close()
      set({ ws: null, wsLoading: false })
    },
    init() {
      if (get().ws || get().wsLoading) {
        return
      }

      set({ wsLoading: true })

      const encoding = getEncoding()
      const ws = new WebSocket(import.meta.env.VITE_WS_API_BASE + `?encoding=${encoding}`)
      if (encoding === 'msgpack') {
        ws.binaryType = 'arraybuffer'
      }
      ws.onopen = () => {
        set({ ws, wsLoading: false })

        ws.send(MSG.SUBSCRIBE_MARKETS_STATS())
        ws.send(MSG.SUBSCRIBE_BLOCK_HEIGHT())
        pingTimeout = setTimeout(() => ws.send(JSON.stringify({ type: 'ping' })), 5 * 1000)
      }
      ws.onerror = () => get().actions.deinit()
      ws.onclose = () => {
        set({ ws: null })
        if (pingTimeout) {
          clearTimeout(pingTimeout)
        }
        if (pongTimeout) {
          clearTimeout(pongTimeout)
        }
      }
      ws.onmessage = (e) => {
        const message = decodeWsMessage(e, encoding)

        const { success, error, data } = wsMessageSchema.safeParse(decodeWsMessage(e, encoding))

        if (!success) {
          console.error('ZodError in WebSockets Message', error.message, message)
          // time to sentry?
          return
        }

        if (data.type === 'pong') {
          if (pongTimeout) {
            clearTimeout(pongTimeout)
          }
          pingTimeout = setTimeout(() => {
            ws.send(JSON.stringify({ type: 'ping' }))
            pongTimeout = setTimeout(() => useWsSubStore.getState().actions.deinit(), 5 * 1000)
          }, 5 * 1000)
        }

        handleWsMessage(data)
      }
    },
    switchMarket: (newMarketIndex) => {
      const { ws } = get()

      if (!ws) {
        return
      }

      handleChangeDesiredMarket(newMarketIndex)

      ws.send(MSG.SUBSCRIBE_PUBLIC_MARKET_DATA(newMarketIndex))

      set({ marketIndex: newMarketIndex })
    },
    subscribePublicPool: (publicPoolIndex: number) => {
      const { ws, poolIndexes } = get()

      if (poolIndexes.includes(publicPoolIndex)) {
        return
      }

      ws?.send(MSG.SUBSCRIBE_POOL_DATA(publicPoolIndex))
      ws?.send(MSG.SUBSCRIBE_USER_STATS(publicPoolIndex))
      ws?.send(MSG.SUBSCRIBE_POOL_INFO(publicPoolIndex))

      set({ poolIndexes: [...poolIndexes, publicPoolIndex] })
    },
    unsubscribePublicPool: (publicPoolIndex: number) => {
      const { ws, poolIndexes } = get()

      if (!poolIndexes.includes(publicPoolIndex)) {
        return
      }

      ws?.send(MSG.UNSUBSCRIBE_POOL_DATA(publicPoolIndex))
      ws?.send(MSG.UNSUBSCRIBE_USER_STATS(publicPoolIndex))
      ws?.send(MSG.UNSUBSCRIBE_POOL_INFO(publicPoolIndex))

      set({
        poolIndexes: poolIndexes.filter((index) => index !== publicPoolIndex),
      })
    },
    switchAccount: (newAccountIndex: number | null) => {
      const { ws, accountIndex } = get()

      if (!ws) {
        return
      }

      if (accountIndex !== null) {
        ws.send(MSG.UNSUBSCRIBE_ACCOUNT(accountIndex))
        ws.send(MSG.UNSUBSCRIBE_USER_STATS(accountIndex))
        ws.send(MSG.UNSUBSCRIBE_ACCOUNT_TRANSACTIONS(accountIndex))
        waitForWasm().then(async () => {
          const t = await createAuthToken({ accountIndex })
          ws.send(MSG.UNSUBSCRIBE_ACCOUNT_ORDERS(accountIndex, t.token))
        })
      }

      if (newAccountIndex !== null) {
        ws.send(MSG.SUBSCRIBE_ACCOUNT(newAccountIndex))
        ws.send(MSG.SUBSCRIBE_USER_STATS(newAccountIndex))
        ws.send(MSG.SUBSCRIBE_ACCOUNT_TRANSACTIONS(newAccountIndex))
        waitForWasm().then(async () => {
          const t = await createAuthToken({ accountIndex: newAccountIndex })
          ws.send(MSG.SUBSCRIBE_ACCOUNT_ORDERS(newAccountIndex, t.token))
        })
      }

      set({ accountIndex: newAccountIndex })
    },
  },
}))

const MSG = {
  SUBSCRIBE_ACCOUNT: (account: number) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `account_all/${account}`,
    }),
  UNSUBSCRIBE_ACCOUNT: (account: number) =>
    JSON.stringify({
      type: 'unsubscribe',
      channel: `account_all/${account}`,
    }),
  SUBSCRIBE_ACCOUNT_ORDERS: (account: number, auth: string) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `account_orders/all/${account}`,
      auth,
    }),
  UNSUBSCRIBE_ACCOUNT_ORDERS: (account: number, auth: string) =>
    JSON.stringify({
      type: 'unsubscribe',
      channel: `account_orders/all/${account}`,
      auth,
    }),
  SUBSCRIBE_USER_STATS: (account: number) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `user_stats/${account}`,
    }),
  UNSUBSCRIBE_USER_STATS: (account: number) =>
    JSON.stringify({
      type: 'unsubscribe',
      channel: `user_stats/${account}`,
    }),
  SUBSCRIBE_POOL_INFO: (account: number) =>
    JSON.stringify({ type: 'subscribe', channel: `pool_info/${account}` }),
  SUBSCRIBE_POOL_DATA: (account: number) =>
    JSON.stringify({ type: 'subscribe', channel: `pool_data/${account}` }),
  UNSUBSCRIBE_POOL_DATA: (account: number) =>
    JSON.stringify({ type: 'unsubscribe', channel: `pool_data/${account}` }),
  UNSUBSCRIBE_POOL_INFO: (account: number) =>
    JSON.stringify({ type: 'unsubscribe', channel: `pool_info/${account}` }),
  SUBSCRIBE_PUBLIC_MARKET_DATA: (marketIndex: number) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `public_market_data/${marketIndex}`,
    }),
  SUBSCRIBE_MARKETS_STATS: () => JSON.stringify({ type: 'subscribe', channel: 'market_stats/all' }),
  UNSUBSCRIBE_MARKETS_STATS: () =>
    JSON.stringify({ type: 'unsubscribe', channel: 'market_stats/all' }),
  SUBSCRIBE_BLOCK_HEIGHT: () => JSON.stringify({ type: 'subscribe', channel: 'height' }),
  SUBSCRIBE_ACCOUNT_TRANSACTIONS: (account: number) =>
    JSON.stringify({
      type: 'subscribe',
      channel: `account_tx/${account}`,
    }),
  UNSUBSCRIBE_ACCOUNT_TRANSACTIONS: (account: number) =>
    JSON.stringify({
      type: 'unsubscribe',
      channel: `account_tx/${account}`,
    }),
}
