import { useEffect, useRef, useState } from 'react'

import { USDC_SYMBOL } from 'js/constants/shared'
import { ORDER_SIDE_TABS, type OrderSide } from 'js/constants/trades'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import {
  useMaxTotalSize,
  useMidPrice,
  usePrunedOrderbook,
  useSpread,
} from 'js/providers/order-book-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import HeaderCell from 'js/shared-components/HeaderCell'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import { formatMarketPrice } from 'js/util/formatting'

import { useOrderInputStore } from '../../place-order-panel/PlaceOrder'
import { OrderBookItem } from '../components/OrderBookItem'
import { OrderBookList } from '../components/OrderBookList'

export const OrderBook = () => {
  const { isMobile } = useResponsiveness()
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')
  const [isSizeInUsd, setIsSizeInUsd] = useState(false)
  const [isTotalSelected, setIsTotalSelected] = useState(false)

  const { asks, bids } = usePrunedOrderbook()

  const maxTotalSize = useMaxTotalSize()
  const spread = useSpread()
  const spreadPercentage = spread === 0 ? 0 : (spread / Number(asks[0]?.price ?? 0)) * 100
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const symbol = currentMarket.symbol
  const alreadyScrolled = useRef(false)
  const obContainerRef = useRef<HTMLDivElement>(null)

  const sizeOptions = [
    { key: symbol, title: symbol, onClick: () => setIsSizeInUsd(false) },
    { key: USDC_SYMBOL, title: USDC_SYMBOL, onClick: () => setIsSizeInUsd(true) },
  ]

  const sizeOrTotalOptions = [
    { key: 'size', title: 'Size', onClick: () => setIsTotalSelected(false) },
    { key: 'total', title: 'Total', onClick: () => setIsTotalSelected(true) },
  ]

  const spreadPercentageLabel =
    spreadPercentage === Infinity ? '' : `(${spreadPercentage.toFixed(3)}%)`
  const midPrice = useMidPrice()
  const midPriceLabel = formatMarketPrice(midPrice, currentMarket, false)

  useEffect(() => {
    if (
      !isMobile &&
      obContainerRef.current !== null &&
      !alreadyScrolled.current &&
      (asks.length > 0 || bids.length > 0)
    ) {
      const parent = obContainerRef.current.parentElement
      if (parent) {
        const parentRect = parent.getBoundingClientRect()
        const childRect = obContainerRef.current.getBoundingClientRect()
        const offset = childRect.top - parentRect.top
        parent.scrollTop += offset - parent.clientHeight / 2 + childRect.height / 2
        alreadyScrolled.current = true
      }
    }
  }, [obContainerRef, alreadyScrolled, asks, bids, isMobile])

  useEffect(() => {
    alreadyScrolled.current = false
  }, [currentMarketId])

  if (isMobile) {
    return (
      <>
        <div className="flex min-h-10 w-full items-center justify-between border-b bg-white-transparent px-3 max-mobile:border-t">
          <p className="typography-body-2 text-white">Order Book</p>
          <div className="flex gap-2">
            <SelectorDropdown
              options={sizeOrTotalOptions}
              selectedOption={isTotalSelected ? 'total' : 'size'}
            />
            <SelectorDropdown
              options={sizeOptions}
              selectedOption={isSizeInUsd ? USDC_SYMBOL : symbol}
            />
          </div>
        </div>
        <div className="flex max-h-[calc(100%-40px)] flex-col gap-4 px-6 py-2 max-mobile:px-0">
          <div className="flex gap-2 self-end"></div>
          {asks.length > 0 && bids.length > 0 && (
            <div className="grid w-full grid-cols-3 items-center border-b py-1 text-white-opaque max-mobile:px-2">
              <span className="typography-body-2 text-start text-white">Spread</span>
              <span className="typography-body-2 text-center text-white">
                {formatMarketPrice(spread, currentMarket, false)} {spreadPercentageLabel}
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  useOrderInputStore.getState().update('limitPrice', midPrice.toString())
                }}
                className="typography-body-2 text-end text-white"
              >
                {midPriceLabel}
              </span>
            </div>
          )}
          <div className="flex items-center justify-between gap-2 text-white">
            <HeaderCell title="Bid Price" className="!px-0 text-white" />
            <HeaderCell
              title={isTotalSelected ? 'Total' : 'Size'}
              symbol={isSizeInUsd ? USDC_SYMBOL : symbol}
              className="!px-0 text-white"
            />
            <HeaderCell title="Ask Price" className="!px-0 text-white" />
          </div>
          <div className="flex gap-2 overflow-x-hidden overflow-y-scroll">
            <div className="relative mx-auto flex size-full flex-col max-tablet:h-fit max-tablet:flex-row">
              <OrderBookList
                reverseCols
                rows={bids}
                isAsk={false}
                className="h-full"
                maxTotalSize={maxTotalSize}
                isSizeInUsd={isSizeInUsd}
                isTotalSelected={isTotalSelected}
              />
              <OrderBookList
                rows={[...asks].reverse()}
                isAsk
                className="h-full"
                maxTotalSize={maxTotalSize}
                isSizeInUsd={isSizeInUsd}
                isTotalSelected={isTotalSelected}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">Order Book</p>
        <div className="flex gap-2">
          <SelectorDropdown
            options={sizeOptions}
            selectedOption={isSizeInUsd ? USDC_SYMBOL : symbol}
          />
          <SelectorDropdown
            selectedOption={selectedSide}
            options={ORDER_SIDE_TABS.map((tab) => ({
              ...tab,
              onClick: () => setSelectedSide(tab.key),
            }))}
          />
        </div>
      </div>
      <div className="relative flex h-[calc(100%_-_40px)] flex-col">
        <div className="pointer-events-none absolute bottom-0 left-0 z-[1] w-full" />
        <div className="flex size-full flex-col">
          <div className="z-[1] grid h-10 min-h-10 w-full grid-cols-[25%_35%_40%] items-center px-2 py-1 text-white backdrop-blur-sm max-tablet:py-3 max-tablet:pl-0 max-tablet:pr-2 max-mobile:p-2">
            <OrderBookItem title="Price" symbol={USDC_SYMBOL} />
            <OrderBookItem title="Size" symbol={isSizeInUsd ? USDC_SYMBOL : symbol} />
            <OrderBookItem title="Total" symbol={isSizeInUsd ? USDC_SYMBOL : symbol} />
          </div>
          <div className="relative h-[calc(100%_-_8px)] overflow-y-scroll">
            <div className="min-h-full" />
            <div className="absolute left-0 top-0 w-full " ref={obContainerRef}>
              {selectedSide !== 'bids' && asks.length > 0 && (
                <OrderBookList
                  rows={[...asks].reverse()}
                  isAsk
                  className="flex justify-end"
                  maxTotalSize={maxTotalSize}
                  isSizeInUsd={isSizeInUsd}
                  dataTestId="orderbook-bids"
                />
              )}
              {selectedSide === 'all' &&
                spreadPercentage !== Infinity &&
                asks.length > 0 &&
                bids.length > 0 && (
                  <div className="my-2 grid grid-cols-3 border-y px-2 py-1">
                    <OrderBookItem className="justify-start" title="Spread" />
                    <div className="flex justify-end gap-1">
                      <OrderBookItem title={formatMarketPrice(spread, currentMarket, false)} />
                      <OrderBookItem title={spreadPercentageLabel} />
                    </div>
                    <OrderBookItem
                      onClick={(e) => {
                        e.stopPropagation()
                        useOrderInputStore.getState().update('limitPrice', midPrice.toString())
                      }}
                      title={midPriceLabel}
                    />
                  </div>
                )}
              {selectedSide !== 'asks' && bids.length > 0 && (
                <OrderBookList
                  rows={bids}
                  isAsk={false}
                  maxTotalSize={maxTotalSize}
                  isSizeInUsd={isSizeInUsd}
                  dataTestId="orderbook-asks"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
