import type { OrderBookDetail } from 'zklighter-perps'

export const formatUSD = (value: number | string, showCurrency = true) =>
  Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const formatLeverage = (value: number, decimals = 0) => `${value.toFixed(decimals)}x`

export const formatMarketPrice = (
  value: number | string,
  market: OrderBookDetail,
  showCurrency = true,
) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: market.price_decimals,
    maximumFractionDigits: market.price_decimals,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const formatMarketSize = (value: number | string, market: OrderBookDetail) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: market.size_decimals,
    maximumFractionDigits: market.size_decimals,
  })

export const formatMarketPriceSize = (
  value: number | string,
  market: OrderBookDetail,
  showCurrency = true,
) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: market.price_decimals + market.size_decimals,
    maximumFractionDigits: market.price_decimals + market.size_decimals,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const formatOpenInterest = (value: number | string, showCurrency = true) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })
