import { useState } from 'react'

import { useAccountsQuery, useIsWhitelistedQuery } from 'js/providers/hooks/useAccountQuery'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import { isMainnet } from 'js/util/util'

import { Button } from './Button'
import { Modal } from './Modal'

export const WelcomeModal = () => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true)
  const accountsQuery = useAccountsQuery()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const { data } = readSSUserSelections()

  const shouldShowWelcomeModal =
    isMainnet() &&
    isWelcomeModalOpen &&
    !data?.hasClosedWelcomeModal &&
    isWhitelistedQuery.data === true &&
    accountsQuery.isError

  return (
    <Modal
      title="Welcome to the Lighter Private Beta"
      open={shouldShowWelcomeModal}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          writeSSUserSelection({ ...data, hasClosedWelcomeModal: true })
        }

        setIsWelcomeModalOpen(newOpen)
      }}
    >
      <p className="typography-label-1 text-white">
        Thank you for joining our Private Beta! You will be among the earliest users to earn Points,
        have direct access to our core team and the rest of the community, and provide us valuable
        feedback as we shape the product before its public launch.
        <br />
        <br />
        Happy trading!
      </p>
      <Button className="mt-4 w-full" onClick={() => setIsWelcomeModalOpen(false)}>
        Start Trading
      </Button>
    </Modal>
  )
}
