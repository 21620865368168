import cn from 'js/util/cn'

interface OrderBookItemProps {
  title: string
  symbol?: string
  onClick?: (e: React.MouseEvent) => void
  className?: string
}

export const OrderBookItem = ({ title, symbol, onClick, className }: OrderBookItemProps) => (
  <div
    onClick={onClick}
    className={cn(
      'flex justify-end',
      {
        'cursor-pointer': !!onClick,
        'cursor-default': !onClick,
      },
      className,
    )}
  >
    <div className="flex items-center gap-1">
      <p className="typography-body-2 text-white">{title}</p>
      {symbol && (
        <div className="rounded-lg bg-grey-light px-2 py-1 max-mobile:hidden">
          <p className="typography-body-1 text-white">{symbol}</p>
        </div>
      )}
    </div>
  </div>
)
