import { Chart } from 'js/pages/trade/components/chart'
import { InfoBox } from 'js/pages/trade/components/info-box/InfoBox'
import { UserOrders } from 'js/pages/trade/components/orders/user-orders/'

import { PageContainer } from '../page-container/PageContainer'

import { OrderBook } from './components/orders/all-orders/OrderBook'
import { TradesList } from './components/orders/all-orders/TradesList'
import { LiquidationNotifications } from './components/place-order-panel/components/LiquidationNotifications'
import { PlaceOrder } from './components/place-order-panel/PlaceOrder'

const DesktopViewTrade = () => (
  <PageContainer className="!max-h-none mobile:h-[unset]">
    <LiquidationNotifications />
    <div className="flex size-full flex-col overflow-hidden p-2 pt-0">
      <div className="relative z-[3] w-full">
        <InfoBox />
      </div>
      <div className="grid h-[110dvh] grid-cols-[[col1]_1fr_[col2]_minmax(200px,350px)] gap-2 overflow-y-hidden max-tablet:grid-cols-[[col1]_1fr_[col2]_minmax(150px,280px)]">
        <div className="col-start-1 row-start-1 grid max-h-full grid-cols-[[col1]_1fr_[col2]_minmax(200px,_330px)] grid-rows-[55%_45%] overflow-y-hidden rounded-xl border max-tablet:grid-cols-[[col1]_1fr_[col2]_minmax(200px,_300px)]">
          <div className="col-start-[col1] row-start-1 size-full overflow-y-hidden border-b border-r">
            <Chart />
          </div>
          <div className="relative col-start-[col1] size-full overflow-y-hidden border-r">
            <UserOrders />
          </div>
          <div className="col-start-[col2] row-start-1 size-full overflow-y-hidden border-b">
            <OrderBook />
          </div>
          <div className="col-start-[col2] size-full overflow-hidden">
            <TradesList />
          </div>
        </div>
        <div className="col-start-[col2] row-start-1 max-h-full overflow-y-hidden rounded-xl border">
          <PlaceOrder />
        </div>
      </div>
    </div>
  </PageContainer>
)

export default DesktopViewTrade
