import { DialogTitle } from '@radix-ui/react-dialog'
import React from 'react'

import { Clickable } from './Clickable'
import { Dialog, DialogBody, DialogClose, DialogContent } from './uikit/Dialog'
import Icon from './uikit/Icon'

interface ModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  children: React.ReactNode
  title: React.ReactNode
  dataTestId?: string
  preventClose?: boolean
  preventCloseOutside?: boolean
}

/** Generic function that prevents any default event behavior. */
const avoidDefaultDomBehavior = (e: Event) => e.preventDefault()

export const Modal = ({
  open,
  onOpenChange,
  children,
  title,
  dataTestId,
  preventClose = false,
  preventCloseOutside = false,
}: ModalProps) => (
  <Dialog
    open={open}
    onOpenChange={(newOpen) => {
      if (preventClose) {
        return
      }

      onOpenChange(newOpen)
    }}
  >
    <DialogContent
      data-testid={dataTestId}
      onPointerDownOutside={preventCloseOutside ? avoidDefaultDomBehavior : undefined}
      onInteractOutside={preventCloseOutside ? avoidDefaultDomBehavior : undefined}
    >
      <div className="flex items-center justify-between border-b border-white/5 bg-white/10 p-6">
        <DialogTitle className="typography-text-6 text-white">{title}</DialogTitle>
        {!preventClose && (
          <DialogClose asChild>
            <Clickable>
              <Icon icon="x" className="size-5" />
            </Clickable>
          </DialogClose>
        )}
      </div>
      <DialogBody>{children}</DialogBody>
    </DialogContent>
  </Dialog>
)
