import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Clickable } from 'js/shared-components/Clickable'

export const GeoBanBanner = () => {
  const userAddress = useUserAddress()

  return (
    <>
      <div className="flex w-full items-center justify-center bg-red-500 px-6 py-2 text-white">
        {userAddress ? (
          <p className="typography-body-2 text-white">
            You are accessing Lighter from a restricted jurisdiction so functionality is limited to
            account withdrawals. For more information, see the{' '}
            <a
              target="_blank"
              className="underline"
              href="https://lighter.xyz/terms/"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            .
          </p>
        ) : (
          <p className="typography-body-2 text-white">
            You are accessing Lighter from a restricted jurisdiction so functionality is blocked.
            For more information, see the{' '}
            <a
              target="_blank"
              className="underline"
              href="https://lighter.xyz/terms/"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            . You can also check out the{' '}
            <a
              target="_blank"
              href="https://testnet.app.lighter.xyz"
              className="underline"
              rel="noreferrer"
            >
              Lighter Testnet
            </a>{' '}
            environment or apply for the private beta program{' '}
            <span>
              <Clickable onClick={() => useUserStore.setState({ showWhitelist: true })}>
                <p className="typography-body-2 text-white underline">here</p>
              </Clickable>
            </span>
            .
          </p>
        )}
      </div>
    </>
  )
}
