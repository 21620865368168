import {
  AccountApi,
  Configuration,
  CandlestickApi,
  TransactionApi,
  OrderApi,
  InfoApi,
  LiquidationApi,
  BridgeApi,
} from 'zklighter-perps'

const configuration = new Configuration({ basePath: import.meta.env.VITE_REST_API_BASE })
export const accountApi = new AccountApi(configuration)
export const candlesticksApi = new CandlestickApi(configuration)
export const transactionApi = new TransactionApi(configuration)
export const orderApi = new OrderApi(configuration)
export const infoApi = new InfoApi(configuration)
export const liquidationApi = new LiquidationApi(configuration)
export const bridgeApi = new BridgeApi(configuration)

export const parseErrorFromCodegen = async (error: unknown) => {
  if (
    !!error &&
    typeof error === 'object' &&
    'response' in error &&
    error.response instanceof Response
  ) {
    return (await error.response.json()) as { code: number; message: string }
  }

  return null as never
}
