import { Button } from 'js/shared-components'

const ErrorPage = () => (
  <div className="flex size-full items-center justify-center">
    <div className="flex w-fit flex-col items-center justify-center gap-12 max-mobile:gap-4">
      <p className="text-9xl font-light text-white">Oops!</p>
      <p className="text-2xl font-light text-white">Something unexpected happened</p>
      <p className="typography-body-2 text-white max-mobile:mx-auto max-mobile:my-7">
        Our engineering team has been notified and is looking into it.
      </p>
      <a href="/">
        <Button className="w-full">Return to homepage</Button>
      </a>
    </div>
  </div>
)

export default ErrorPage
