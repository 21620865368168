import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useUserStore } from 'js/providers/user-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Clickable } from 'js/shared-components/Clickable'
import { Collapsible, CollapsibleContent } from 'js/shared-components/uikit/Collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'
import { getFrontendEnv } from 'js/util/util'

import { Footer } from '../page-container/Footer'

export const LinksMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMoreOpen, setIsMoreOpen] = useState(false)
  const { isMobile } = useResponsiveness()
  const userAddress = useUserAddress()

  const disconnectWallet = useWallet().disconnect

  return (
    <>
      {isMobile && <Backdrop isVisible={isMenuOpen} onClick={() => setIsMenuOpen(false)} />}
      <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Clickable className="group flex size-5 items-center justify-center">
            <div
              className={
                'absolute h-0.5 w-5 bg-white transition-transform group-data-[state=open]:bg-transparent'
              }
            >
              <div
                className={
                  'absolute h-0.5 w-5 bg-white transition-transform duration-500 group-data-[state=closed]:-mt-1.5 group-data-[state=open]:rotate-[405deg]'
                }
              />
              <div
                className={
                  'absolute h-0.5 w-5 bg-white transition-transform duration-500 group-data-[state=closed]:mt-1.5 group-data-[state=open]:rotate-[-405deg]'
                }
              />
            </div>
          </Clickable>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="max-h-full min-w-[250px] max-mobile:w-screen max-mobile:rounded-none max-mobile:border-0"
          sideOffset={18}
        >
          <Link to={`/trade/${localStorage.getItem('last_symbol')}`}>
            <DropdownMenuItem>Trade</DropdownMenuItem>
          </Link>
          {userAddress && (
            <Link to="/portfolio">
              <DropdownMenuItem>Portfolio</DropdownMenuItem>
            </Link>
          )}
          {userAddress && (
            <Link to="/public-pools">
              <DropdownMenuItem>Public Pools</DropdownMenuItem>
            </Link>
          )}
          {userAddress && (
            <Link to="/leaderboard">
              <DropdownMenuItem>Leaderboard</DropdownMenuItem>
            </Link>
          )}
          {userAddress && (
            <Link to="/sub-accounts">
              <DropdownMenuItem>Sub-Accounts</DropdownMenuItem>
            </Link>
          )}
          <DropdownMenuItem
            onClick={(e) => {
              e.preventDefault()
              setIsMoreOpen((prevIsMoreOpen) => !prevIsMoreOpen)
            }}
          >
            <div className="flex items-baseline gap-2">
              <span>More</span>
              <Icon
                icon="chevron"
                className={cn('size-2.5 transition-all', { 'rotate-180': isMoreOpen })}
              />
            </div>
          </DropdownMenuItem>
          <Collapsible open={isMoreOpen}>
            <CollapsibleContent>
              {getFrontendEnv() === 'Mainnet' ? (
                <a href="https://testnet.app.lighter.xyz" target="_blank" rel="noopener noreferrer">
                  <DropdownMenuItem className="pl-8">Testnet</DropdownMenuItem>
                </a>
              ) : (
                <a href="https://app.lighter.xyz" target="_blank" rel="noopener noreferrer">
                  <DropdownMenuItem className="pl-8">Mainnet</DropdownMenuItem>
                </a>
              )}
              <a
                href="https://assets.lighter.xyz/whitepaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DropdownMenuItem className="pl-8">Whitepaper</DropdownMenuItem>
              </a>
              <a href="https://docs.lighter.xyz" target="_blank" rel="noopener noreferrer">
                <DropdownMenuItem className="pl-8">Docs</DropdownMenuItem>
              </a>
              <DropdownMenuItem
                className="pl-8"
                onClick={() => useUserStore.setState({ showFeedback: true })}
              >
                Support
              </DropdownMenuItem>
              <DropdownMenuItem className="pl-8">
                <a href="https://lighter.xyz/terms" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>
              </DropdownMenuItem>
            </CollapsibleContent>
          </Collapsible>
          {userAddress && (
            <div className="mt-8 flex border-t border-grey-main">
              <Clickable onClick={disconnectWallet} className="flex flex-row items-center p-3">
                <p className="typography-label-1 text-white-opaque">Disconnect</p>
                <Icon icon="logout" className="size-9 text-white-opaque" />
              </Clickable>
            </div>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {isMobile && isMenuOpen && <Footer />}
    </>
  )
}
