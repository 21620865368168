import { PnlBalanceCharts } from 'js/shared-components/PnlBalanceCharts'

import { usePublicPoolQuery } from './utils'

const PublicPoolPnl = () => {
  const publicPoolQuery = usePublicPoolQuery()

  return (
    <div className="flex flex-[2] flex-col overflow-hidden rounded-lg max-mobile:flex-none mobile:min-h-[300px]">
      <PnlBalanceCharts accountIndex={publicPoolQuery.data.index} isPublicPool={true} />
    </div>
  )
}

export default PublicPoolPnl
