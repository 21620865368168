import { useInfiniteQuery } from '@tanstack/react-query'
import { formatDate } from 'date-fns'
import { useMemo } from 'react'
import { DepositHistoryItemStatusEnum, type DepositHistoryRequest } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import HeaderCell from 'js/shared-components/HeaderCell'
import { NoOrdersText } from 'js/shared-components/tables/NoOrdersText'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { transactionApi } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { useLastTableItemRef } from 'js/util/table'

const DepositsHeader = () => (
  <TableHeader>
    <TableHeaderRow>
      <HeaderCell title="Date" />
      <HeaderCell title="Amount" />
      <HeaderCell title="Status" />
    </TableHeaderRow>
  </TableHeader>
)

const DEPOSIT_STATUS_LABELS = {
  [DepositHistoryItemStatusEnum.Completed]: 'Completed',
  [DepositHistoryItemStatusEnum.Failed]: 'Failed',
  [DepositHistoryItemStatusEnum.Pending]: 'Pending',
} as const

export const DepositHistory = () => {
  const userAddress = useUserAddress()
  const params: DepositHistoryRequest = useMemo(
    () => ({ l1_address: userAddress, filter: 'all' }),
    [userAddress],
  )
  const depositHistoryQuery = useInfiniteQuery({
    queryKey: ['depositHistory', params],
    queryFn: ({ pageParam }) => transactionApi.depositHistory({ ...params, cursor: pageParam }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.cursor || undefined,
    enabled: !!userAddress,
  })
  const lastDepositRef = useLastTableItemRef(depositHistoryQuery)

  const deposits = useMemo(
    () => (depositHistoryQuery.data?.pages ?? []).map(({ deposits }) => deposits).flat(),
    [depositHistoryQuery.data],
  )

  if (depositHistoryQuery.isLoading) {
    return (
      <Table>
        <DepositsHeader />
        <TableBody>
          <TableLoader rows={4} columns={3} />
        </TableBody>
      </Table>
    )
  }

  if (!deposits.length) {
    return <NoOrdersText type="deposits" />
  }

  return (
    <Table>
      <DepositsHeader />
      <TableBody>
        {deposits.map((deposit, index) => (
          <TableRow
            key={deposit.id}
            ref={index === deposits.length - 1 ? lastDepositRef : undefined}
          >
            <TableCell>
              <p className="typography-body-1 text-white">
                {formatDate(deposit.timestamp, 'yyyy-MM-dd HH:mm:ss')}
              </p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-white">{formatUSD(deposit.amount)}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-left text-white">
                {DEPOSIT_STATUS_LABELS[deposit.status]}
              </p>
            </TableCell>
          </TableRow>
        ))}
        {depositHistoryQuery.isFetchingNextPage && <TableLoader rows={1} columns={3} />}
      </TableBody>
    </Table>
  )
}
