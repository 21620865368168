import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Trade } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface TradePriceCellProps {
  trade: Trade
}

const TradePriceCell = ({ trade }: TradePriceCellProps) => {
  const market = useOrderBookMetasQuery().data[trade.market_id]!

  return (
    <TableCell>
      <p className="typography-body-1 text-end text-white">
        {formatMarketPrice(trade.price, market)}
      </p>
    </TableCell>
  )
}
export default TradePriceCell
