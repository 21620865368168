/* eslint-disable no-empty */
import { useCallback, useEffect, useRef, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import smoothscroll from 'smoothscroll-polyfill'
import { OrderTypeEnum, type Candlestick, type OrderBookDetail } from 'zklighter-perps'

import 'css/index.css'
import { colors, fonts } from 'css/css'
import { useUserAccountActiveOrders, useUserAccountPositions } from 'js/providers/accounts-store'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats, useOrderBookTrades } from 'js/providers/order-book-store'
import { type Trade } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/user-store'
import { useWsSubStore } from 'js/providers/wsStore'
import { useCancelOrderMutation } from 'js/shared-components/tables/ActiveOrderTable/CancelOrderButton'
import { candlesticksApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatMarketPrice } from 'js/util/formatting'
import { getPositionPnl, usePositionsWithLiqPrices } from 'js/util/positions'

import {
  widget,
  type Bar,
  type ChartingLibraryWidgetOptions,
  type IChartingLibraryWidget,
  type ResolutionString,
  type Timezone,
  type IPositionLineAdapter,
  type IOrderLineAdapter,
} from '../../../../../../public/charting_library'

import { ChartLoader } from './ChartLoader'

type ExtendedBar = Bar & {
  lastTradeId: number
}

const getInitialInterval = (): ResolutionString => {
  const lastUsedTimeBasedResolution = localStorage.getItem(
    'tradingview.chart.lastUsedTimeBasedResolution',
  )

  if (
    typeof lastUsedTimeBasedResolution === 'string' &&
    resolutions.includes(lastUsedTimeBasedResolution)
  ) {
    return lastUsedTimeBasedResolution as ResolutionString
  }

  return '5' as ResolutionString
}

const candleToBar = (candle: Candlestick): ExtendedBar => ({
  time: candle.timestamp,
  open: candle.open,
  close: candle.close,
  high: candle.high,
  low: candle.low,
  volume: candle.volume1,
  lastTradeId: candle.last_trade_id,
})

const resolutions = ['1', '5', '15', '60', '240', '1D'] as ResolutionString[]

const tvResToObRes = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return '1m'
    case '5':
      return '5m'
    case '15':
      return '15m'
    case '60':
      return '1h'
    case '240':
      return '4h'
    case '1D':
      return '1d'
    default:
      return '' as never
  }
}

const tvResToResInMiliseconds = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return 60 * 1000
    case '5':
      return 5 * 60 * 1000
    case '15':
      return 15 * 60 * 1000
    case '60':
      return 60 * 60 * 1000
    case '240':
      return 4 * 60 * 60 * 1000
    case '1D':
      return 24 * 60 * 60 * 1000
    default:
      return 0 as never
  }
}

// Polyfill invocation
smoothscroll.polyfill()

const lastBarsCache = new Map<number, ExtendedBar>()

export const TradingViewChart = () => {
  const isChangingSymbolRef = useRef(false)
  const resetRef = useRef<(() => void) | null>(null)
  const trades = useOrderBookTrades()
  const [isChartLoading, setIsChartLoading] = useState(true)
  const [showOrderLines, setShowOrderLines] = useState(true)
  const positionLineRef = useRef<IPositionLineAdapter | null>(null)
  const liquidationLineRef = useRef<IPositionLineAdapter | null>(null)
  const orderLinesRef = useRef<IOrderLineAdapter[] | null>(null)
  const currentMarket = useCurrentMarket()
  const currentMarketRef = useRef<OrderBookDetail>(currentMarket)
  const currentMarketStats = useCurrentMarketStats()
  const markPrice = Number(currentMarketStats?.mark_price ?? '0')
  const accountIndex = useAccountIndex()
  const positions = useUserAccountPositions()
  const position = usePositionsWithLiqPrices(positions, accountIndex ?? -1)[currentMarket.market_id]
  const activeOrders = useUserAccountActiveOrders()[currentMarket.market_id]
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const widgetRef = useRef<IChartingLibraryWidget | null>(null)
  const newTradesCb = useRef<((trades: Trade[] | null) => void) | null>(null)
  const ws = useWsSubStore((state) => state.ws)
  const cancelOrderMutation = useCancelOrderMutation()

  const resetChart = useCallback(() => {
    if (!widgetRef.current) {
      return
    }

    resetRef.current?.()
    widgetRef.current.activeChart().resetData()
    widgetRef.current.activeChart().getPanes()[0]?.getRightPriceScales()[0]?.setAutoScale(true)
    widgetRef.current.activeChart().setSymbol(currentMarketRef.current.symbol)
    isChangingSymbolRef.current = true
  }, [])

  useEffect(() => {
    currentMarketRef.current = currentMarket
    resetChart()
  }, [currentMarket, resetChart])

  useEffect(() => {
    widgetRef.current?.activeChart().dataReady(() => {
      if (isChangingSymbolRef.current) {
        return
      }
      // these try/catch are needed because it will crash when removing a position for absolutely no reason :)
      try {
        positionLineRef.current?.remove()
      } catch {}
      try {
        liquidationLineRef.current?.remove()
      } catch {}

      if (!position) {
        return
      }

      if (position.liquidation_price) {
        liquidationLineRef.current = widgetRef
          .current!.activeChart()
          .createPositionLine()
          .setLineLength(60, 'percentage')
          .setLineStyle(2)
          .setBodyBackgroundColor(colors.black)
          .setBodyTextColor(colors.whiteOpaque)
          .setText('Liq. Price')
          .setQuantity('')
          .setPrice(Number(position.liquidation_price))
      }

      if (!showOrderLines) {
        return
      }

      const positionPnl = getPositionPnl(position, markPrice)
      const positionLineColor = positionPnl < 0 ? colors.redMain : colors.greenMain

      positionLineRef.current = widgetRef
        .current!.activeChart()
        .createPositionLine()
        .setLineLength(80, 'percentage')
        .setLineStyle(2)
        .setLineColor(positionLineColor)
        .setBodyBorderColor(positionLineColor)
        .setBodyBackgroundColor(colors.whiteOpaque)
        .setQuantityBackgroundColor(colors.black)
        .setPrice(Number(position.avg_entry_price))
        .setText(`PNL ${formatMarketPrice(positionPnl, currentMarket)}`)
        .setQuantity(position.position)
    })
  }, [position, markPrice, currentMarket, showOrderLines, activeOrders])

  const cancelOrderMutate = cancelOrderMutation.mutate
  useEffect(() => {
    widgetRef.current?.activeChart().dataReady(() => {
      if (isChangingSymbolRef.current) {
        return
      }
      // these try/catch are needed because it will crash when removing a position for absolutely no reason :)
      try {
        orderLinesRef.current?.forEach((line) => line.remove())
        orderLinesRef.current = []
      } catch {}

      if (!activeOrders) {
        return
      }

      activeOrders
        .filter(
          (order) =>
            order.type === OrderTypeEnum.StopLoss ||
            order.type === OrderTypeEnum.StopLossLimit ||
            order.type === OrderTypeEnum.TakeProfit ||
            order.type === OrderTypeEnum.TakeProfitLimit ||
            order.type === OrderTypeEnum.Limit,
        )
        .forEach((order) => {
          const orderLine = widgetRef
            .current!.activeChart()
            .createOrderLine()
            .setLineLength(40, 'percentage')
            .setLineStyle(2)
            .setBodyBackgroundColor(colors.black)
            .setBodyTextColor(colors.whiteOpaque)
            .setCancelButtonBackgroundColor(colors.black)
            .setCancelButtonIconColor(colors.whiteOpaque)
            .setCancelTooltip('Click to Cancel')
            .setQuantity(order.remaining_base_amount)
            .onCancel(() => {
              cancelOrderMutate(order)
              orderLine.setCancellable(false)
            })

          if (order.type === OrderTypeEnum.Limit) {
            const formattedLimitPrice = formatMarketPrice(order.price, currentMarket)

            return orderLinesRef.current?.push(
              orderLine.setText(`Limit ${formattedLimitPrice}`).setPrice(Number(order.price)),
            )
          }

          const isSL =
            order.type === OrderTypeEnum.StopLoss || order.type === OrderTypeEnum.StopLossLimit

          const sign = order.is_ask !== isSL ? '<' : '>'
          const formattedTriggerPrice = formatMarketPrice(order.trigger_price, currentMarket)
          const sltpLabel = isSL ? 'SL' : 'TP'

          orderLinesRef.current?.push(
            orderLine
              .setText(`${sltpLabel} ${sign} ${formattedTriggerPrice}`)
              .setPrice(Number(order.trigger_price)),
          )
        })
    })
  }, [activeOrders, currentMarket, cancelOrderMutate])

  useEffect(() => {
    newTradesCb.current?.(trades)
  }, [trades])

  useEffect(() => {
    const datafeed: ChartingLibraryWidgetOptions['datafeed'] = {
      onReady(callback) {
        setTimeout(() => callback({ supported_resolutions: resolutions }), 0)
      },
      resolveSymbol(name, resolve) {
        setTimeout(
          () =>
            resolve({
              ticker: name ?? '',
              listed_exchange: '',
              has_intraday: true,
              format: 'price',
              name: name ?? '',
              description: currentMarketRef.current.symbol,
              type: 'crypto',
              session: '24x7',
              timezone: 'Etc/UTC',
              exchange: '',
              minmov: 1,
              pricescale: 10 ** currentMarketRef.current.price_decimals,
              visible_plots_set: 'ohlcv',
              supported_resolutions: resolutions,
              volume_precision: 2,
              data_status: 'streaming',
            }),
          0,
        )
      },
      unsubscribeBars: () => {},
      subscribeBars(
        _symbolnfo,
        resolution,
        onRealtimeCallback,
        _listenerGuid,
        onResetCacheNeededCallback,
      ) {
        newTradesCb.current = (trades) => {
          const marketId = currentMarketRef.current.market_id
          trades?.toReversed().forEach((trade) => {
            const lastBar = lastBarsCache.get(marketId)

            if (!lastBar || trade.market_id !== marketId || lastBar.lastTradeId >= trade.trade_id) {
              return
            }

            const tradePrice = Number(trade.price)
            const tradeSize = Number(trade.size) * tradePrice

            if (trade.timestamp >= lastBar.time + tvResToResInMiliseconds(resolution)) {
              const newLastBar: ExtendedBar = {
                high: tradePrice,
                low: tradePrice,
                time: lastBar.time + tvResToResInMiliseconds(resolution),
                open: lastBar.close,
                close: tradePrice,
                volume: tradeSize,
                lastTradeId: trade.trade_id,
              }

              lastBarsCache.set(marketId, newLastBar)
              onRealtimeCallback(newLastBar)
              return
            }

            lastBar.volume = lastBar.volume! + tradeSize
            lastBar.high = Math.max(lastBar.high, tradePrice)
            lastBar.low = Math.min(lastBar.low, tradePrice)
            lastBar.close = tradePrice
            lastBar.lastTradeId = trade.trade_id
            lastBarsCache.set(marketId, lastBar)

            onRealtimeCallback(lastBar)
          })
        }
        resetRef.current = onResetCacheNeededCallback
      },
      searchSymbols() {},
      getBars: async (_symbol, res, period, resolve, _err) => {
        const marketId = currentMarketRef.current.market_id

        const returnBars = async ({
          period,
        }: {
          period: { to: number; from: number; countBack: number }
        }) => {
          try {
            const { candlesticks } = await candlesticksApi.candlesticks({
              market_id: marketId,
              resolution: tvResToObRes(res),
              // TradingView period uses seconds instead of miliseconds
              start_timestamp: period.from * 1000,
              end_timestamp: period.to * 1000,
              count_back: period.countBack,
            })

            return candlesticks
              .filter((candlestick) => candlestick.timestamp < Date.now())
              .map(candleToBar)
          } catch {
            return []
          }
        }
        try {
          const bars = await returnBars({ period })

          if (period.firstDataRequest) {
            lastBarsCache.set(marketId, bars.at(-1)!)
          }

          resolve(bars, { noData: bars.length === 0 })
        } catch (error) {
          _err(error as string)
        }
      },
    }

    const widgetOpts: ChartingLibraryWidgetOptions = {
      container: chartContainerRef.current!,
      symbol: currentMarketRef.current.symbol,
      locale: 'en',
      load_last_chart: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
      study_count_limit: 5,
      disabled_features: [
        'symbol_search_hot_key',
        'study_templates',
        'save_chart_properties_to_local_storage',
        'chart_property_page_trading',
        'hide_last_na_study_output',
        'go_to_date',
        'header_compare',
        'header_symbol_search',
        'timeframes_toolbar',
        'control_bar',
        'display_market_status',
        'symbol_info',
      ],
      debug: false,
      enabled_features: [
        'hide_left_toolbar_by_default',
        'lock_visible_time_range_on_resize',
        'shift_visible_range_on_new_bar',
      ],
      interval: getInitialInterval(),
      custom_font_family: fonts.satoshi,
      datafeed,
      library_path: '/charting_library/',
      custom_css_url: '/tradingview.css',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      theme: 'dark',
      toolbar_bg: colors.black,
      overrides: {
        'paneProperties.background': colors.black,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.legendProperties.showStudyArguments': false,
        'paneProperties.legendProperties.showStudyValues': true,
        'mainSeriesProperties.statusViewStyle.showInterval': false,
        'mainSeriesProperties.candleStyle.upColor': colors.greenMain,
        'mainSeriesProperties.candleStyle.downColor': colors.redMain,
        'paneProperties.legendProperties.showSeriesTitle': true,
        'scalesProperties.scaleSeriesOnly': true,
        'scalesProperties.showStudyLastValue': false,
        // 'mainSeriesProperties.statusViewStyle.showSymbol': false,
        'symbolWatermarkProperties.visibility': false,
      },
    }

    const tvWidget = new widget(widgetOpts)

    tvWidget.onChartReady(() => {
      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, () => resetChart())

      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          isChangingSymbolRef.current = false
        })
      setIsChartLoading(false)

      const volumeStudyId = tvWidget.activeChart().getAllStudies()[0]?.id

      if (volumeStudyId) {
        tvWidget.activeChart().getStudyById(volumeStudyId).unmergeDown()
      }

      widgetRef.current = tvWidget
    })

    tvWidget.headerReady().then(() => {
      const button = tvWidget.createButton()
      const toggle = document.createElement('div')
      toggle.className = 'toggle-switcher on'
      toggle.innerHTML = renderToStaticMarkup(<div className="toggle-switcher-dot" />)
      button.setAttribute('title', 'Display or hide position lines')
      button.classList.add('trading-view-button')
      button.addEventListener('click', () => {
        setShowOrderLines((prev) => !prev)
        if (toggle.classList.contains('on')) {
          toggle.classList.remove('on')
        } else {
          toggle.classList.add('on')
        }
      })
      button.textContent = 'Position Lines'
      button.appendChild(toggle)
    })

    return () => tvWidget.remove()
  }, [resetChart])

  useEffect(() => {
    if (!ws) {
      resetChart()
    }
  }, [ws, resetChart])

  return (
    <div className="relative size-full">
      {isChartLoading && <ChartLoader />}
      <div className={cn('size-full', { hidden: isChartLoading })} ref={chartContainerRef} />
    </div>
  )
}
