import { OrderTypeEnum } from 'zklighter-perps'
import { type RefinementCtx, z, ZodIssueCode } from 'zod'

const parseJsonPreprocessor = (value: unknown, ctx: RefinementCtx) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value)
    } catch (e) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: (e as Error).message,
      })
    }
  }

  return value
}

const signatureSchema = z.preprocess(
  parseJsonPreprocessor,
  z.record(z.string(), z.object({ pk: z.string(), seed: z.string() })),
)

export const readLSSignature = () => signatureSchema.safeParse(localStorage.getItem('signature'))
export const writeLSSignature = (value: z.infer<typeof signatureSchema>) =>
  localStorage.setItem('signature', JSON.stringify(value))

const accountIndexSchema = z.preprocess(parseJsonPreprocessor, z.number())

export const readLSLastAccountIndex = () =>
  accountIndexSchema.safeParse(localStorage.getItem('lastAccountIndex'))
export const writeLSLastAccountIndex = (value: z.infer<typeof accountIndexSchema>) =>
  localStorage.setItem('lastAccountIndex', JSON.stringify(value))

const userSelectionsSchema = z.preprocess(
  parseJsonPreprocessor,
  z.object({
    maxSlippage: z.string().optional(),
    orderType: z
      .enum([
        OrderTypeEnum.Limit,
        OrderTypeEnum.Market,
        OrderTypeEnum.StopLoss,
        OrderTypeEnum.StopLossLimit,
        OrderTypeEnum.TakeProfit,
        OrderTypeEnum.TakeProfitLimit,
        OrderTypeEnum.Twap,
        OrderTypeEnum.TwapSub,
        OrderTypeEnum.Liquidation,
      ])
      .optional(),
    isAmountInUSD: z.boolean().optional(),
    hasClosedWelcomeModal: z.boolean().optional(),
  }),
)

export const readSSUserSelections = () =>
  userSelectionsSchema.safeParse(sessionStorage.getItem('user_selections'))

export const writeSSUserSelection = (value: z.infer<typeof userSelectionsSchema>) =>
  sessionStorage.setItem('user_selections', JSON.stringify(value))

export const clientRegistrationDataSchema = z.object({
  pk: z.string(),
  body: z.string(),
})

export const txResponseSchama = z.object({
  txHash: z.string(),
  txInfo: z.string(),
})
