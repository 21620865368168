import heroLight from 'img/shared/lights.svg'

export const BackgroundLights = () => {
  return (
    <img
      src={heroLight}
      alt="Hero Light"
      className="pointer-events-none absolute left-0 top-[-50px] z-[9999] max-h-screen min-w-full mix-blend-color-dodge blur-3xl"
    />
  )
}
