import * as RadixCollapsible from '@radix-ui/react-collapsible'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

export const CollapsibleContent = forwardRef<
  ElementRef<typeof RadixCollapsible.Content>,
  ComponentPropsWithoutRef<typeof RadixCollapsible.Content>
>(({ className, ...rest }, forwardedRef) => (
  <RadixCollapsible.Content
    className={cn(
      'overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown',
      className,
    )}
    ref={forwardedRef}
    {...rest}
  />
))

CollapsibleContent.displayName = 'CollapsibleContent'
