import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { formatMarketSize } from 'js/util/formatting'

import { useOrderInputStore } from '../PlaceOrder'

interface TwapStatsProps {
  orderSize: string
}

const FREQUENCY = 30

export const TwapStats = ({ orderSize }: TwapStatsProps) => {
  const { runtimeMinutes, runtimeHours } = useOrderInputStore()
  const currentMarket = useCurrentMarket()

  const runtimeInSeconds = Number(runtimeMinutes) * 60 + Number(runtimeHours) * 60 * 60
  const hours = Math.floor(runtimeInSeconds / 60 / 60)
  const minutes = Math.floor((runtimeInSeconds - hours * 60 * 60) / 60)
  const runtime = (hours > 0 ? `${hours}h` : '') + `${minutes}m`

  const numOrders = runtimeInSeconds / FREQUENCY + 1

  const summary = [
    { title: 'Frequency', value: `${FREQUENCY} seconds` },
    { title: 'Runtime', value: runtime },
    { title: 'Number of Orders', value: numOrders.toString() },
    {
      title: 'Size per Suborder',
      value: formatMarketSize(Number(orderSize) / numOrders, currentMarket),
    },
    {
      title: 'Fee',
      value: `Maker: ${Number(currentMarket.maker_fee ?? 0)}% | Taker: ${Number(
        currentMarket.taker_fee ?? 0,
      )}%`,
    },
  ] as const

  return (
    <div className="flex w-full flex-col gap-3 rounded-md border bg-white-transparent p-3">
      {summary.map(({ title, value }) => (
        <div key={title} className="flex w-full justify-between">
          <p className="typography-body-2 text-white-opaque">{title}:</p>
          <div className="flex items-center gap-1">
            <p className="typography-body-2 text-white">{value}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
