import { useQuery } from '@tanstack/react-query'
import { type ChangeEvent, useMemo, useState } from 'react'
import type { FeeBucketRequest } from 'zklighter-perps'

import { useUserAccountPositions } from 'js/providers/accounts-store'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats } from 'js/providers/order-book-store'
import { useAccountIndex } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { Modal } from 'js/shared-components/Modal'
import PercentageInput from 'js/shared-components/PercentageInput'
import Icon from 'js/shared-components/uikit/Icon'
import { accountApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatLeverage, formatMarketPrice, formatMarketSize } from 'js/util/formatting'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import {
  computePositionLeverage,
  computePositionMarginReq,
  marginFractionToLeverage,
  useGetInitialMarginFraction,
  usePortfolioStats,
  usePositionsWithLiqPrices,
  useUpdatedPositions,
} from 'js/util/positions'

export const PriceImpact = ({
  isLimit,
  isClosingPosition,
  slippage,
  isLongOrder,
  maxSlippage,
  setMaxSlippage,
  estPrice,
  orderValue,
  orderSize,
}: {
  isLimit: boolean
  isClosingPosition: boolean
  slippage: number
  isLongOrder?: boolean
  maxSlippage?: number
  setMaxSlippage?: (value: number) => void
  estPrice?: string
  orderValue?: string
  orderSize?: string
}) => {
  const [isSlippageModalOpen, setIsSlippageModalOpen] = useState(false)
  const [inputSlippage, setInputSlippage] = useState(maxSlippage?.toString() ?? '0')
  const accountIndex = useAccountIndex() ?? -1
  const params: FeeBucketRequest = useMemo(() => ({ account_index: accountIndex }), [accountIndex])
  const feeBucketQuery = useQuery({
    queryKey: ['feeBucket', params],
    queryFn: () => accountApi.feeBucket(params),
    enabled: accountIndex !== -1,
  })
  const currentMarket = useCurrentMarket()
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarketStats = useCurrentMarketStats()
  const markPrice = Number(currentMarketStats?.mark_price ?? 0)
  const { symbol } = currentMarket
  const rawPositions = useUserAccountPositions()
  const portfolioValue = usePortfolioStats(rawPositions, accountIndex)?.portfolioValue
  const positions = usePositionsWithLiqPrices(rawPositions, accountIndex)
  const position = positions[currentMarket.market_id]
  const initialMarginFraction = getInitialMarginFraction(currentMarket.market_id)
  const positionSize = Number(position?.position ?? 0)
  const positionMargin = computePositionMarginReq(positionSize, markPrice, initialMarginFraction)
  const positionLeverage = computePositionLeverage(positionSize, markPrice, portfolioValue ?? 0)
  const positionLiqPrice = position?.liquidation_price

  const rawUpdatedPositions = useUpdatedPositions(
    Number(orderSize ?? 0),
    !isLongOrder,
    estPrice ?? '0',
  )
  const updatedPortfolioValue = usePortfolioStats(rawUpdatedPositions, accountIndex)?.portfolioValue
  const updatedPositions = usePositionsWithLiqPrices(rawUpdatedPositions, accountIndex)
  const updatedPosition = updatedPositions[currentMarket.market_id]
  const updatedPositionSize = Number(updatedPosition?.position ?? 0)
  const updatedPositionMargin = computePositionMarginReq(
    updatedPositionSize,
    markPrice,
    initialMarginFraction,
  )
  const updatedPositionLeverage = computePositionLeverage(
    updatedPositionSize,
    markPrice,
    updatedPortfolioValue ?? 0,
  )
  const updatedPositionLiqPrice = updatedPosition?.liquidation_price

  const orderMarginReq = Number(orderValue) / marginFractionToLeverage(initialMarginFraction)

  const onMaxSlippageChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/,/g, '.')

    const regex = /^[0-9]*\.?[0-9]*$/
    if (
      inputValue.length <= 6 &&
      regex.test(inputValue) &&
      inputValue.split('.').length <= 2 &&
      (inputValue.split('.').length !== 2 || inputValue.split('.')[1]!.length <= 2)
    ) {
      setInputSlippage(inputValue)
    }
  }

  const onConfirmMaxSlippage = () => {
    if (!setMaxSlippage || !inputSlippage) return
    setMaxSlippage(Number(inputSlippage))
    const { data: userSelections = {} } = readSSUserSelections()

    writeSSUserSelection({ ...userSelections, maxSlippage: inputSlippage })
    setIsSlippageModalOpen(false)
  }

  const marketSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Order Size',
      value: orderSize ? `${formatMarketSize(orderSize, currentMarket)} ${symbol}` : '-',
    },
    {
      title: 'Order Value',
      value: orderValue ? `${formatMarketPrice(orderValue, currentMarket)}` : '-',
    },
    {
      title: 'Est. Liq. Price',
      value: positionLiqPrice ? formatMarketPrice(positionLiqPrice, currentMarket) : '-',
      updatedValue: updatedPositionLiqPrice
        ? formatMarketPrice(updatedPositionLiqPrice, currentMarket)
        : '-',
    },
    {
      title: 'Position',
      value: `${formatMarketSize(positionSize, currentMarket)} ${symbol}`,
      updatedValue: `${formatMarketSize(updatedPositionSize, currentMarket)} ${symbol}`,
    },
    {
      title: 'Position Margin',
      value: formatMarketPrice(positionMargin, currentMarket),
      updatedValue: formatMarketPrice(updatedPositionMargin, currentMarket),
    },
    {
      title: 'Position Leverage',
      value: isNaN(positionLeverage) ? '-' : formatLeverage(positionLeverage, 2),
      updatedValue: formatLeverage(updatedPositionLeverage, 2),
      explanation: `Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks.`,
    },
    {
      title: 'Est. Price',
      value: estPrice ? formatMarketPrice(estPrice, currentMarket) : '-',
    },
    {
      title: 'Slippage',
      value: `Est: ${(isNaN(slippage) ? 0 : slippage).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}% | Max: ${maxSlippage}%`,
      onClick: () => setIsSlippageModalOpen(true),
      buttonExplanation: 'Adjust maximum slippage',
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const limitSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Margin Required',
      value: orderMarginReq ? formatMarketPrice(orderMarginReq, currentMarket) : '-',
    },
    {
      title: 'Order Size',
      value: orderSize ? `${formatMarketSize(orderSize, currentMarket)} ${symbol}` : '-',
    },
    {
      title: 'Order Value',
      value: orderValue ? formatMarketPrice(orderValue, currentMarket) : '-',
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const closePositionSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    {
      title: 'Slippage',
      value: `${(isNaN(slippage) ? 0 : slippage).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}%`,
    },
    {
      title: 'Position',
      value: `${formatMarketSize(positionSize, currentMarket)} ${symbol}`,
      updatedValue: `${formatMarketSize(updatedPositionSize, currentMarket)} ${symbol}`,
    },
    {
      title: 'Fees',
      value: feeBucketQuery.data
        ? `Taker: ${feeBucketQuery.data.taker_fee / 10 ** 6}% | Maker: ${
            feeBucketQuery.data.maker_fee / 10 ** 6
          }%`
        : 'Taker: 0% | Maker: 0%',
    },
  ]

  const summary = isClosingPosition ? closePositionSummary : isLimit ? limitSummary : marketSummary

  return (
    <>
      <Modal
        title="Adjust Max Slippage"
        open={isSlippageModalOpen}
        onOpenChange={setIsSlippageModalOpen}
      >
        <div className="flex w-full flex-col items-center gap-6">
          <p className="typography-label-1 text-white">
            Set the maximum slippage you are willing to accept. If the price changes more than this
            percentage, your order will not be executed.
          </p>

          <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
            <PercentageInput
              type="text"
              value={inputSlippage}
              onChange={onMaxSlippageChange}
              className="w-full text-left text-sm max-mobile:text-base max-mobile:placeholder:text-base"
            />
            <p className="typography-body-2 text-white">%</p>
          </div>
          <Button
            disabled={!Number(inputSlippage)}
            className="w-full"
            onClick={onConfirmMaxSlippage}
          >
            {!Number(inputSlippage) ? 'Please enter a valid number' : 'Confirm'}
          </Button>
        </div>
      </Modal>

      <div className="flex w-full flex-col gap-3 rounded-lg border bg-white-transparent p-3">
        {summary.map(({ title, explanation, value, updatedValue, onClick, buttonExplanation }) => (
          <div key={title} className="flex w-full justify-between">
            {explanation ? (
              <Explanation
                explanation={explanation}
                trigger={<p className="typography-body-2 text-white-opaque underline">{title}:</p>}
              />
            ) : (
              <p className="typography-body-2 text-white-opaque">{title}:</p>
            )}
            <div className="flex items-center gap-1">
              {buttonExplanation ? (
                <Explanation
                  explanation={buttonExplanation}
                  trigger={
                    <div
                      className={cn('flex gap-2', {
                        'cursor-pointer': !!onClick,
                        'text-primary-blue-main': !!onClick,
                        'text-white': !onClick,
                        'font-normal': !!onClick,
                      })}
                      onClick={() => onClick?.()}
                    >
                      <p className="typography-body-2">{value}</p>
                      <Icon icon="edit" className="size-4" />
                    </div>
                  }
                />
              ) : (
                <p
                  className={cn('typography-body-2', {
                    'cursor-pointer': !!onClick,
                    'text-primary-blue-main': !!onClick,
                    'text-white': !onClick,
                    'font-normal': !!onClick,
                  })}
                  onClick={() => onClick?.()}
                >
                  {value}
                </p>
              )}

              {updatedValue && Number(orderSize ?? 0) !== 0 && (
                <>
                  <Icon icon="arrow" className="size-4 text-white" />
                  <p className="typography-body-2 text-white">{updatedValue}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
