import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'sonner'
import type { AccountRequest } from 'zklighter-perps'

import {
  useIsGeoLocBlockedQuery,
  useIsRegisteredQuery,
  useIsWhitelistedQuery,
  useMainAccount,
  useUserAccount,
  useUserAddress,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import Input from 'js/shared-components/uikit/Input'
import Toast from 'js/shared-components/uikit/Toast'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import WarningContainer from 'js/shared-components/WarningContainer'
import { accountApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { createSubAccount, setAccountMetadata } from 'js/zklighter-js-sdk/sdk'

const wait = () => new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))

const CreateSubAccount = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const userAccount = useUserAccount()
  const mainAccount = useMainAccount()
  const isRegisteredQuery = useIsRegisteredQuery()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const geoLocationQuery = useIsGeoLocBlockedQuery()

  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()

  if (
    geoLocationQuery.isPending ||
    geoLocationQuery.data ||
    isWhitelistedQuery.isPending ||
    !isWhitelistedQuery.data ||
    !userAccount ||
    isRegisteredQuery.data === null
  ) {
    return null
  }

  if (userAccount.index !== mainAccount!.index) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount!)}
            isLoading={verifyAndSwitchAccountMutation.isPending}
          >
            Switch
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">
            Switch to the main account to create a sub-account
          </p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isRegisteredQuery.data) {
    return (
      <Button onClick={() => useUserStore.setState({ showOnboarding: true })}>Authenticate</Button>
    )
  }

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Create Sub-Account</Button>
      <CreateSubAccountModal open={modalOpen} onOpenChange={setModalOpen} />
    </>
  )
}

interface CreateSubAccountModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const CreateSubAccountModal = ({ open, onOpenChange }: CreateSubAccountModalProps) => {
  const [subAccountName, setSubAccountName] = useState('')
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const queryClient = useQueryClient()
  const createSubAccountMutation = useMutation({
    mutationFn: async (accountIndex: number) => {
      const { event_info } = await createSubAccount({ accountIndex })
      const { a: newSubAccountIndex } = JSON.parse(event_info) as { a: number } // TODO zod schema for event_info broadly speaking

      await setAccountMetadata({
        masterAccountIndex: accountIndex,
        targetAccountIndex: newSubAccountIndex,
        name: subAccountName,
        description: '',
      })

      const params: AccountRequest = { by: 'l1_address', value: userAddress }

      await wait()
      let newSubAccounts = await accountApi.account(params)

      while (!newSubAccounts.accounts.some(({ index }) => index === newSubAccountIndex)) {
        await wait()
        newSubAccounts = await accountApi.account(params)
      }

      return { newSubAccounts, params }
    },
    onSuccess: ({ newSubAccounts, params }) => {
      queryClient.setQueryData(['account', params], newSubAccounts)
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Sub-account created successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    },
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => {
      setSubAccountName('')
      onOpenChange(false)
    },
  })

  if (!userAccount) {
    return null
  }

  return (
    <Modal open={open} title="Create Sub-Account" onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="typography-body-1 text-white">Enter a name for your Sub-Account</p>
          <Input
            autoFocus
            placeholder="Sub-Account Name"
            value={subAccountName}
            onChange={(e) => setSubAccountName(e.target.value)}
          />
        </div>
        <WarningContainer
          className={cn({
            'opacity-0': subAccountName.length === 0 || subAccountName.length >= 3,
          })}
        >
          Sub-Account name must be at least 3 letters long.
        </WarningContainer>
        <div className="flex justify-end gap-2">
          <Button
            className="w-fit"
            onClick={() => onOpenChange(false)}
            color="grey"
            disabled={createSubAccountMutation.isPending && !createSubAccountMutation.isError}
          >
            Cancel
          </Button>
          <Button
            className="w-fit"
            onClick={() => createSubAccountMutation.mutate(userAccount.index)}
            disabled={subAccountName.length < 3}
            isLoading={createSubAccountMutation.isPending && !createSubAccountMutation.isError}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateSubAccount
