import { useMutation } from '@tanstack/react-query'
import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { OrderTypeEnum } from 'zklighter-perps'

import { useUserAccountPositions } from 'js/providers/accounts-store'
import {
  useCurrentMarket,
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { useIsGeoLocBlockedQuery } from 'js/providers/hooks/useAccountQuery'
import { useOrderBook } from 'js/providers/order-book-store'
import { type Position } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Clickable } from 'js/shared-components/Clickable'
import { CurrencyInput } from 'js/shared-components/CurrencyInput'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import type { TxEventInfo } from 'js/types/api-types'
import { TxOrderTypes, TxTimeInForceTypes } from 'js/types/user'
import { createOrder } from 'js/zklighter-js-sdk/sdk'

import { useIsClosingPosition, useOrderInputStore, useOrderToPlaceUpdater } from '../PlaceOrder'

import { BuyingStats } from './BuyingStats'
import { OrderNotification } from './OrderNotification'
import { PriceImpact } from './PriceImpact'

const percentButtons = [
  { value: 0.25, label: '25%' },
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: 'Full Close' },
]

interface ClosePositionProps {
  slippage: number
  maxSlippage: number
  setLiquidationWarning: Dispatch<SetStateAction<boolean>>
}

export const ClosePosition = ({
  slippage,
  maxSlippage,
  setLiquidationWarning,
}: ClosePositionProps) => {
  const orderInputs = useOrderInputStore()
  const toastIdRef = useRef<number | string | null>(null)
  const [selectedPercent, setSelectedPercent] = useState(1)
  const orderBook = useOrderBook() ?? { asks: [], bids: [] }
  const accountIndex = useAccountIndex()!
  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const positions = useUserAccountPositions()
  const isClosingPosition = useIsClosingPosition()
  const geoLocationQuery = useIsGeoLocBlockedQuery()
  const position = positions[currentMarketId]
  const { matchInfo } = useOrderToPlaceUpdater()
  const { amount0: orderSize, estPrice } = matchInfo
  const update = orderInputs.update

  useEffect(() => {
    if (position) {
      update('size', position.position)
    }
  }, [update, position])

  const closePositionMutation = useMutation({
    mutationFn: async (position: Position) => {
      const market = orderBookMetasQuery.data[position.market_id]!
      const isAsk = position.sign === 1

      const amount0 = Math.floor(Number(orderSize) * 10 ** market.size_decimals)
      const orders = isAsk ? orderBook.asks : orderBook.bids
      const bestPrice = Number(orders[0]?.price)
      const worstExecutionPrice = bestPrice * ((100 + maxSlippage * (isAsk ? -1 : 1)) / 100)
      const price = worstExecutionPrice * 10 ** market.price_decimals

      toastIdRef.current = toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(orderSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            onClose={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )

      return createOrder({
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: position.market_id,
        baseAmount: Math.floor(amount0),
        price: isAsk ? Math.floor(price) : Math.ceil(price),
        orderExpiry: 0,
        isAsk: Number(isAsk),
        orderType: TxOrderTypes.OrderTypeMarket,
        timeInForce: TxTimeInForceTypes.OrderImmediateOrCancel,
        reduceOnly: 0,
        triggerPrice: 0,
      })
    },
    onSuccess: (tx, position) => {
      const eventInfo = JSON.parse(tx.event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i
      const isAsk = position.sign === 1

      toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(orderSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            orderIndex={orderIndex}
            onClose={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { id: toastIdRef.current! },
      )

      useOrderInputStore.getState().changeIsClosingPosition(false)
      setSelectedPercent(1)
    },
    onError: () => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))

      toast.dismiss(toastIdRef.current!)
    },
  })

  if (!position) return null

  return (
    <BottomAnimatedDropdown className="h-full overflow-hidden bg-black" isOpen={isClosingPosition}>
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">Close Position</p>
        <Clickable onClick={() => useOrderInputStore.getState().changeIsClosingPosition(false)}>
          <Icon icon="x" className="size-5" />
        </Clickable>
      </div>
      <BuyingStats
        setLiquidationWarning={setLiquidationWarning}
        isShort={position.sign === 1}
        newSize={Number(orderSize)}
      />
      <div className="flex flex-col gap-4 p-2">
        <CurrencyInput
          value={orderSize}
          onChange={(e) => {
            if (Number(e) <= Number(position.position)) update('size', e)
          }}
          symbol={currentMarket.symbol}
          decimal={currentMarket.size_decimals}
        />
        <div className="flex w-full justify-between gap-2">
          {percentButtons.map((button) => (
            <Clickable
              key={button.label}
              className="w-full rounded-lg border bg-white-transparent py-3"
              color={selectedPercent === button.value ? 'blue' : 'white'}
              onClick={() => {
                update(
                  'size',
                  (Number(position.position) * button.value).toFixed(currentMarket.size_decimals),
                )
                setSelectedPercent(button.value)
              }}
            >
              <p className="typography-body-1">{button.label}</p>
            </Clickable>
          ))}
        </div>
        {!geoLocationQuery.data && (
          <Button
            className="w-full"
            color="red"
            onClick={() => closePositionMutation.mutate(position)}
            disabled={Number(orderSize) === 0}
            isLoading={closePositionMutation.isPending && !closePositionMutation.isError}
          >
            Close Position
          </Button>
        )}
        <PriceImpact
          orderSize={orderSize}
          isLimit={false}
          slippage={slippage}
          isClosingPosition
          isLongOrder={position.sign === -1}
        />
      </div>
    </BottomAnimatedDropdown>
  )
}
