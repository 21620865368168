import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'

import TableCell from './TableCell'
import TableRow from './TableRow'

interface TableLoaderProps {
  rows: number
  columns: number
}

const TableLoader = ({ rows, columns }: TableLoaderProps) => (
  <>
    {Array(rows)
      .fill(null)
      .map((_, i) => (
        <TableRow key={i}>
          {new Array(columns).fill(null).map((_, j) => (
            <TableCell key={j} className="h-6 p-2">
              <SkeletonRectangle />
            </TableCell>
          ))}
        </TableRow>
      ))}
  </>
)

export default TableLoader
