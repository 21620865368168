import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import Leaderboard from './pages/leaderboard'
import NotFound from './pages/not-found'
import Portfolio from './pages/portfolio'
import PublicPool from './pages/public-pool'
import PublicPools from './pages/public-pools'
import SubAccounts from './pages/sub-accounts'
import Trade from './pages/trade'
import Wrapper from './Wrapper'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Wrapper />,
    children: [
      {
        index: true,
        element: <Navigate to={'/trade'} replace />,
      },
      {
        path: '/trade',
        // rely on the logic within TradesRoute
        element: <Navigate to={'/trade/unknown'} replace />,
      },
      { path: '/trade/:symbol', element: <Trade /> },
      { path: '/portfolio', element: <Portfolio /> },
      { path: '/sub-accounts', element: <SubAccounts /> },
      { path: '/public-pools', element: <PublicPools /> },
      { path: '/public-pools/:public_pool_index', element: <PublicPool /> },
      { path: '/leaderboard', element: <Leaderboard /> },
      { path: '*', element: <NotFound /> },
    ],
  },
])

const App = () => <RouterProvider router={router} />

export default App
