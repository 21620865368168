import { useState } from 'react'

import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import cn from 'js/util/cn'

import DepositForm from './DepositForm'
import PublicPoolActionsBlocker from './PublicPoolActionsBlocker'
import WithdrawForm from './WithdrawForm'

const PublicPoolActions = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false)
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)
  const isRegisteredQuery = useIsRegisteredQuery()

  const { isMobile } = useResponsiveness()

  return (
    <>
      {isMobile && (
        <Backdrop
          fullScreen
          className="top-0 h-full"
          isVisible={isDepositModalOpen || isWithdrawModalOpen}
          onClick={() => {
            setIsDepositModalOpen(false)
            setIsWithdrawModalOpen(false)
          }}
        />
      )}
      <div className="flex size-full max-h-full flex-1 flex-col justify-start gap-2 max-mobile:min-h-0 mobile:relative">
        {(!isMobile || isRegisteredQuery.data) && (
          <TabBar className="flex h-[unset] min-h-[unset] items-center justify-between rounded-lg border-none bg-white/5 p-1 max-mobile:gap-4 max-mobile:bg-transparent max-mobile:p-0">
            <div className="relative flex w-full gap-6 max-mobile:gap-2 max-mobile:px-2">
              <SelectorIndicator
                width={`calc(100% / 2 - 12px)`}
                left={`calc(100% / 2 * ${selectedTabIndex} + ${selectedTabIndex * 12}px)`}
                numButtons={2}
                selectedElemIdx={selectedTabIndex}
                className={cn(
                  'bottom-px after:h-0.5 after:w-10 after:rounded-full max-mobile:hidden',
                  {
                    'after:bg-green-main': selectedTabIndex === 0,
                    'after:bg-red-main': selectedTabIndex === 1,
                  },
                )}
              />
              <TabButton
                className={cn(
                  'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all max-mobile:order-2 max-mobile:bg-green-main max-mobile:text-white',
                  {
                    'text-white-opaque bg-transparent': selectedTabIndex !== 0,
                    'text-white bg-white/10': selectedTabIndex === 0,
                  },
                )}
                isSelected={selectedTabIndex === 0}
                onClick={() => (isMobile ? setIsDepositModalOpen(true) : setSelectedTabIndex(0))}
              >
                Deposit
              </TabButton>
              <TabButton
                className={cn(
                  'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all max-mobile:order-1 max-mobile:bg-red-main max-mobile:text-white',
                  {
                    'text-white-opaque bg-transparent': selectedTabIndex !== 1,
                    'text-white bg-white/10': selectedTabIndex === 1,
                  },
                )}
                isSelected={selectedTabIndex === 1}
                onClick={() => (isMobile ? setIsWithdrawModalOpen(true) : setSelectedTabIndex(1))}
              >
                Withdraw
              </TabButton>
            </div>
          </TabBar>
        )}
        {isMobile ? (
          <>
            <PublicPoolActionsBlocker className="mx-auto mb-0 mt-auto w-full">
              {isDepositModalOpen && <DepositForm />}
              {isWithdrawModalOpen && <WithdrawForm />}
            </PublicPoolActionsBlocker>
          </>
        ) : (
          <div className="flex-1 rounded-lg bg-white/5 p-4">
            <PublicPoolActionsBlocker className="mx-auto mb-0 mt-auto w-full">
              {selectedTabIndex === 0 && <DepositForm />}
              {selectedTabIndex === 1 && <WithdrawForm />}
            </PublicPoolActionsBlocker>
          </div>
        )}
      </div>
    </>
  )
}

export default PublicPoolActions
