import { isEthereumWallet } from '@dynamic-labs/ethereum'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { createPublicClient, formatUnits, getContract, http, parseUnits } from 'viem'
import { mainnet } from 'viem/chains'

import { infoApi } from 'js/util/api/sdk'

import { useUserAddress } from './useAccountQuery'
import { ERC20_ABI } from './useERC20'
import { useWallet } from './useWallet'

export const useZklLighterInfoQuery = () =>
  useSuspenseQuery({
    queryKey: ['zklighterinfo'],
    queryFn: () => infoApi.layer1BasicInfo(),
  })

const useL1Addresses = () => {
  const zklInfoQuery = useZklLighterInfoQuery()

  return useMemo(() => {
    const zk = zklInfoQuery.data.contract_addresses.find((x) => x.name === 'ZkLighterContract')

    if (!zk) {
      console.error('No Address For ZkLighterContract')
      throw new Error('No Address For ZkLighterContract')
    }

    const usdc = zklInfoQuery.data.contract_addresses.find((x) => x.name === 'USDCContract')

    if (!usdc) {
      console.error('No Address For USDCContract')
      throw new Error('No Address For USDCContract')
    }

    return {
      zk: zk.address as `0x${string}`,
      usdc: usdc.address as `0x${string}`,
      network: zklInfoQuery.data.l1_providers[0],
    }
  }, [zklInfoQuery.data])
}

export const useZkLighterL1 = () => {
  const { primaryWallet } = useDynamicContext()
  const l1 = useL1Addresses()
  const userAddr = useUserAddress() as `0x${string}`

  const getClients = async () => {
    if (!primaryWallet || !isEthereumWallet(primaryWallet)) {
      throw new Error('No wallet connected')
    }
    if (!l1) {
      throw new Error('No l1 addresses')
    }
    if (!l1.network) {
      throw new Error('No l1 network')
    }
    const publicClient = await primaryWallet.getPublicClient()
    const walletClient = await primaryWallet.getWalletClient(l1.network.chainId.toString())
    return { l1, publicClient, walletClient }
  }

  const getLighter = async () => {
    const cli = await getClients()
    const publicClient = createPublicClient({
      chain: mainnet,
      transport: http(cli.l1.network?.NetworkRpc),
    })
    const contract = getContract({
      address: cli.l1.zk,
      abi: ZKLIGTHER_ABI,
      client: publicClient,
    })
    return contract
  }

  const getRWLighter = async () => {
    const cli = await getClients()
    const contract = getContract({
      address: cli.l1.zk,
      abi: ZKLIGTHER_ABI,
      client: {
        public: cli.publicClient,
        wallet: cli.walletClient,
      },
    })
    return contract
  }

  const getRWUsdc = async () => {
    const cli = await getClients()
    const erc20 = getContract({
      address: cli.l1.usdc,
      abi: ERC20_ABI,
      client: {
        public: cli.publicClient,
        wallet: cli.walletClient,
      },
    })
    return erc20
  }

  const wallet = useWallet()

  return {
    chainId: l1?.network?.chainId ?? 1,
    maxDepositAmount: async () => {
      const contract = await getLighter()
      const balance = await contract.read.MAX_DEPOSIT_AMOUNT()
      return Number(formatUnits(balance, 6)).toFixed(2)
    },
    getPendingBalance: async () => {
      const contract = await getLighter()
      const balance = await contract.read.getPendingBalance([userAddr])
      return Number(formatUnits(balance, 6)).toFixed(2)
    },
    withdrawPendingBalance: async (readableAmt: string) => {
      const contract = await getRWLighter()
      const parsedAmount = parseUnits(readableAmt, 6)
      return contract.write.withdrawPendingBalance([userAddr, parsedAmount])
    },
    deposit: async (readableAmt: string) => {
      const contract = await getRWLighter()
      const usdcContract = await getRWUsdc()
      const allowance = await usdcContract.read.allowance([userAddr, l1!.zk])
      const parsedAmount = parseUnits(readableAmt, 6) // todo check
      if (parsedAmount > allowance) {
        const h = await usdcContract.write.approve([l1!.zk, parsedAmount])
        const approveReceipt = await wallet.waitTx(h)
        if (approveReceipt.status === 'reverted') {
          throw new Error('Approve failed')
        }
      }
      return contract.write.deposit([parsedAmount, userAddr])
    },
  }
}

const ZKLIGTHER_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_address',
        type: 'address',
      },
    ],
    name: 'getPendingBalance',
    outputs: [
      {
        internalType: 'uint128',
        name: '',
        type: 'uint128',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: '_amount',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_owner',
        type: 'address',
      },
      {
        internalType: 'uint128',
        name: '_amount',
        type: 'uint128',
      },
    ],
    name: 'withdrawPendingBalance',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint48',
        name: '_fromAccountIndex',
        type: 'uint48',
      },
      {
        internalType: 'uint64',
        name: '_usdcAmount',
        type: 'uint64',
      },
    ],
    name: 'triggerWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint48',
        name: 'toAccountIndex',
        type: 'uint48',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'toAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint128',
        name: 'amount',
        type: 'uint128',
      },
    ],
    name: 'Deposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint32',
        name: 'fromAccountIndex',
        type: 'uint32',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'usdcAmount',
        type: 'uint64',
      },
    ],
    name: 'Withdraw',
    type: 'event',
  },
  {
    inputs: [],
    name: 'MAX_DEPOSIT_AMOUNT',
    outputs: [
      {
        internalType: 'uint64',
        name: '',
        type: 'uint64',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const
