import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'sonner'

import { useUserAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import Input from 'js/shared-components/uikit/Input'
import Toast from 'js/shared-components/uikit/Toast'
import { setAccountMetadata } from 'js/zklighter-js-sdk/sdk'

import { usePublicPoolQuery } from '../utils'

interface EditPublicPoolModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const EditPublicPoolModal = ({ open, onOpenChange }: EditPublicPoolModalProps) => {
  const userAccount = useUserAccount()
  const publicPoolQuery = usePublicPoolQuery()
  const [publicPoolName, setPublicPoolName] = useState(publicPoolQuery.data.name)
  const [publicPoolDescription, setPublicPoolDescription] = useState(
    publicPoolQuery.data.description,
  )
  const queryClient = useQueryClient()
  const editPublicPoolMutation = useMutation({
    mutationFn: () =>
      setAccountMetadata({
        masterAccountIndex: userAccount!.index,
        targetAccountIndex: publicPoolQuery.data.index,
        name: publicPoolName,
        description: publicPoolDescription,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['public_pool'] })
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Public Pool updated successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    },
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => onOpenChange(false),
  })
  return (
    <Modal open={open} title="Edit Public Pool" onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="typography-body-1 text-white">Enter a name for your Public Pool</p>
          <Input
            placeholder="Public Pool Name"
            value={publicPoolName}
            onChange={(e) => setPublicPoolName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="typography-body-1 text-white">Enter a description for your Public Pool</p>
          <Input
            placeholder="Public Pool Description"
            value={publicPoolDescription}
            onChange={(e) => setPublicPoolDescription(e.target.value)}
          />
        </div>
        <Button
          className="w-full"
          onClick={() => editPublicPoolMutation.mutate()}
          isLoading={editPublicPoolMutation.isPending && !editPublicPoolMutation.isError}
          disabled={
            (publicPoolName === publicPoolQuery.data.name &&
              publicPoolDescription === publicPoolQuery.data.description) ||
            publicPoolName.length < 3
          }
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default EditPublicPoolModal
