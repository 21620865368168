import type { InputHTMLAttributes } from 'react'

import cn from 'js/util/cn'

const Textarea = ({ className, ...rest }: InputHTMLAttributes<HTMLTextAreaElement>) => (
  <textarea
    className={cn(
      'h-24 resize-none overflow-hidden rounded-lg border bg-transparent text-white',
      'indent-3 text-lg placeholder:indent-3 placeholder:text-lg placeholder:text-white-opaque/50',
      className,
    )}
    {...rest}
  />
)

export default Textarea
