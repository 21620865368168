import { type PublicPool } from 'zklighter-perps'

import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatUSD } from 'js/util/formatting'

interface PublicPoolTVLCellProps {
  publicPool: PublicPool
}

const PublicPoolTVLCell = ({ publicPool }: PublicPoolTVLCellProps) => (
  <TableCell>
    <p className="typography-body-2 text-white">{formatUSD(publicPool.total_asset_value)}</p>
  </TableCell>
)

export default PublicPoolTVLCell
