import type { InputHTMLAttributes } from 'react'

import cn from 'js/util/cn'

const Input = ({ className, ...rest }: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    className={cn(
      'h-11 rounded-lg border bg-transparent text-white',
      'indent-3 text-sm placeholder:indent-3 placeholder:text-sm placeholder:text-white-opaque/50 max-mobile:text-base max-mobile:placeholder:text-base',
      className,
    )}
    {...rest}
  />
)

export default Input
