import { Component, Suspense, useEffect, type ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { z } from 'zod'

import { SubAccountType } from 'js/providers/types'
import { useWsSubStore } from 'js/providers/wsStore'
import { LoadingSpinner } from 'js/shared-components'
import { BackgroundLights } from 'js/shared-components/BackgroundLights'

import { PageContainer } from '../page-container/PageContainer'

import PublicPoolActions from './PublicPoolActions'
import PublicPoolHeader from './PublicPoolHeader'
import { PublicPoolPerformance } from './PublicPoolPerformance'
import PublicPoolPnl from './PublicPoolPnl'
import PublicPoolStats from './PublicPoolStats'
import PublicPoolTables from './PublicPoolTables'
import { usePublicPoolQuery } from './utils'

interface PublicPoolErrorBoundaryProps {
  children: ReactNode
  fallback: ReactNode
}

interface PublicPoolErrorBoundaryState {
  hasError: boolean
}

const apiErrorSchema = z.object({
  code: z.number(),
  message: z.string(),
})

class PublicPoolErrorBoundary extends Component<
  PublicPoolErrorBoundaryProps,
  PublicPoolErrorBoundaryState
> {
  constructor(props: PublicPoolErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    const { data } = apiErrorSchema.safeParse(error)

    return { hasError: data?.code === 29404 }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

const PublicPool = () => (
  <PublicPoolErrorBoundary fallback={<Navigate to="/public-pools" replace />}>
    <Suspense fallback={<LoadingSpinner />}>
      <PageContainer className="!h-[unset] !max-h-none min-h-screen">
        <BackgroundLights />
        <PublicPoolContent />
      </PageContainer>
    </Suspense>
  </PublicPoolErrorBoundary>
)

const PublicPoolContent = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const ws = useWsSubStore((state) => state.ws)

  useEffect(() => {
    if (!ws) {
      return
    }

    useWsSubStore.getState().actions.subscribePublicPool(publicPoolQuery.data.index)

    return () => useWsSubStore.getState().actions.unsubscribePublicPool(publicPoolQuery.data.index)
  }, [ws, publicPoolQuery.data.index])

  if (
    publicPoolQuery.data.account_type !== SubAccountType.public &&
    publicPoolQuery.data.account_type !== SubAccountType.lighterPublic
  ) {
    return <Navigate to={'/public-pools'} replace />
  }

  return (
    <div className="flex size-full min-h-[calc(100dvh_-_93px)] justify-center gap-6 p-6 max-mobile:max-w-screen-mobile max-mobile:flex-col max-mobile:p-2 max-mobile:py-4">
      <div className="flex flex-[1] flex-col gap-4">
        <PublicPoolHeader />
        <PublicPoolStats />
        <PublicPoolPerformance />
        <PublicPoolActions />
      </div>
      <div className="flex flex-[2] flex-col gap-2">
        <PublicPoolPnl />
        <PublicPoolTables />
      </div>
    </div>
  )
}

export default PublicPool
