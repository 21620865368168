import { useMemo } from 'react'
import type { OrderBookDetail } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountLoading, useAccountPositions } from 'js/providers/accounts-store'
import {
  useCurrentMarketId,
  useOrderBookMetasQuery,
} from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/order-book-store'
import MarketCell from 'js/shared-components/cells/MarketCell'
import PositionAvgEntryPriceCell from 'js/shared-components/cells/positions/PositionAvgEntryPriceCell'
import PositionLeverageCell from 'js/shared-components/cells/positions/PositionLeverageCell'
import PositionLiquidationPriceCell from 'js/shared-components/cells/positions/PositionLiquidationPriceCell'
import PositionMarkPriceCell from 'js/shared-components/cells/positions/PositionMarkPriceCell'
import PositionSizeCell from 'js/shared-components/cells/positions/PositionSizeCell'
import PositionUnrealizedPnlCell from 'js/shared-components/cells/positions/PositionUnrealizedPnlCell'
import SideCell from 'js/shared-components/cells/SideCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import cn from 'js/util/cn'
import { usePositionsWithLiqPrices } from 'js/util/positions'

import { NoItemsInMarket } from '../NoItemsInMarket'
import { NoOrdersText } from '../NoOrdersText'

import CancelPositionButton from './CancelPositionButton'

interface PositionsTableHeaderProps {
  showCloseColumn: boolean
}

const PositionsTableHeader = ({ showCloseColumn }: PositionsTableHeaderProps) => (
  <TableHeader>
    <TableHeaderRow>
      <HeaderCell title="Market" />
      <HeaderCell title="Side" />
      <HeaderCell title="Size" />
      <HeaderCell title="Liq Price" />
      <HeaderCell title="Mark Price" />
      <HeaderCell title="Leverage" />
      <HeaderCell title="Unrealized PnL" />
      <HeaderCell title="Avg Entry" className={cn({ 'justify-end': !showCloseColumn })} />
      {showCloseColumn && <HeaderCell title="Close" className="justify-end" />}
    </TableHeaderRow>
  </TableHeader>
)

interface PositionsTableProps {
  accountIndex: number
  showCloseColumn?: boolean
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

export const PositionsTable = ({
  accountIndex,
  showCloseColumn = false,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: PositionsTableProps) => {
  const marketsStats = useMarketsStats()
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const currentMarketId = useCurrentMarketId()
  const positionsLoading = useAccountLoading(accountIndex)
  const positions = useAccountPositions(accountIndex)
  const positionsWithLiqPrices = usePositionsWithLiqPrices(positions, accountIndex)

  const selectedPositions = selectedMarket
    ? [positionsWithLiqPrices[selectedMarket.market_id]].filter(Boolean)
    : Object.values(positionsWithLiqPrices)

  const positionsToDisplay = useMemo(
    () =>
      selectedPositions
        .filter((position) => {
          switch (selectedSide) {
            case 'asks':
              return position.sign === -1
            case 'bids':
              return position.sign === 1
            case 'all':
            default:
              return true
          }
        })
        .sort(
          (a, b) =>
            Number(b.position) * Number(marketsStats[b.market_id]?.mark_price ?? 0) -
            Number(a.position) * Number(marketsStats[a.market_id]?.mark_price ?? 0),
        ),

    [selectedSide, selectedPositions, marketsStats],
  )

  if (positionsLoading) {
    return (
      <Table className="max-mobile:w-[200%]">
        <PositionsTableHeader showCloseColumn={showCloseColumn} />
        <TableBody>
          <TableLoader rows={4} columns={8 + Number(showCloseColumn)} />
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(positionsWithLiqPrices).flat().length) {
    return <NoOrdersText type="positions" />
  }

  if (selectedMarket && !positionsWithLiqPrices[selectedMarket.market_id]) {
    return <NoItemsInMarket type="positions" buttonOnClick={() => setSelectedMarket(null)} />
  }

  return (
    <Table className="max-mobile:w-[200%]">
      <PositionsTableHeader showCloseColumn={showCloseColumn} />
      <TableBody>
        {positionsToDisplay.map((position) => (
          <TableRow
            key={position.market_id}
            onClick={
              position.market_id !== currentMarketId
                ? () => setSelectedMarket(orderBookMetasQuery.data[position.market_id]!)
                : undefined
            }
          >
            <MarketCell marketIndex={position.market_id} />
            <SideCell is_short={position.sign === -1} />
            <PositionSizeCell size={position.position} marketIndex={position.market_id} />
            <PositionLiquidationPriceCell position={position} />
            <PositionMarkPriceCell position={position} />
            <PositionLeverageCell position={position} accountIndex={accountIndex} />
            <PositionUnrealizedPnlCell position={position} />
            <PositionAvgEntryPriceCell position={position} />
            {showCloseColumn && <CancelPositionButton position={position} />}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
