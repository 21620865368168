import type { ChangeEvent } from 'react'

export const copyStringToClipboard = async (str: string) => {
  if (navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(str)
      console.log('Copied to clipboard:', str)
    } catch (err) {
      console.error('Failed to copy using Clipboard API:', err)
    }
  } else {
    // Fallback for older browsers and environments
    const el = document.createElement('textarea')
    el.value = str
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    try {
      const success = document.execCommand('copy')
      console.log('Copied using fallback:', success)
    } catch (err) {
      console.error('Failed to copy using fallback:', err)
    }
    document.body.removeChild(el)
  }
}

export const isZero = (value: string | undefined) => {
  if (value === undefined) return true
  if (Number(value) === 0) return true
  return false
}

export const enforceNumber = (e: ChangeEvent<HTMLInputElement>, decimal: number) => {
  let inputValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

  if (decimal === 0) {
    return /^[0-9]{0,12}$/.test(inputValue)
  }

  let split = inputValue.split('.')

  // 3,301.12 from chart -> 3301.12
  if (split.length > 2) {
    split = [split.slice(0, split.length - 1).join(''), split[split.length - 1]!]
    inputValue = split.join('.')
  }

  if (inputValue.length > 12 || !/^[0-9]*\.?[0-9]*$/.test(inputValue) || split.length > 2) {
    return false
  }

  if (split.length === 2 && split[1]!.length > decimal) {
    return false
  }

  e.target.value = inputValue
  return true
}

type BACKEND_ENV = 'staging' | 'mainnet' | 'testnet'
const getBackendEnv = () => import.meta.env.VITE_ENV as BACKEND_ENV
export const getBackendEnvTitle = () => {
  const env = getBackendEnv()
  if (env === 'mainnet') return ''
  return String(env[0]).toUpperCase() + env.slice(1)
}

type FE_ENV = 'Staging' | 'Mainnet' | 'Testnet'
export const getFrontendEnv = (): FE_ENV => {
  const host = window.location.hostname
  if (host.includes('mainnet')) return 'Mainnet'
  if (host.includes('beta')) return 'Mainnet'
  if (host.includes('testnet')) return 'Testnet'
  if (host.includes('staging')) return 'Staging'
  if (host.includes('localhost')) return 'Staging'
  return 'Mainnet' as FE_ENV
}

export const isMainnet = () => getBackendEnv() == 'mainnet'
