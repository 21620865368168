import { useDynamicContext } from '@dynamic-labs/sdk-react-core'

import { DepositBtnStatusStyle, useDepositStore } from 'js/pages/deposit/form'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import {
  useUserAccount,
  useIsWhitelistedQuery,
  useAccountsQuery,
} from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import Icon from 'js/shared-components/uikit/Icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import cn from 'js/util/cn'
import { isMainnet } from 'js/util/util'

interface ActionsButtonProps {
  children: React.ReactNode
}

const ActionButtonBlocker = ({ children }: ActionsButtonProps) => {
  const userAccount = useUserAccount()
  const depositStore = useDepositStore()
  const accountExistence = useAccountExistence()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const accountsQuery = useAccountsQuery()
  const { setShowAuthFlow } = useDynamicContext()

  switch (accountExistence) {
    case 'NoWallet':
      return (
        <Button onClick={() => setShowAuthFlow(true)} className="flex w-full gap-2">
          <Icon className="size-5" icon="wallet" />
          Connect Wallet
        </Button>
      )

    case 'NotWhitelisted':
      return (
        <Button
          className="w-full"
          isLoading={isWhitelistedQuery.isLoading}
          onClick={() => useUserStore.setState({ showOnboarding: true })}
        >
          Request Access
        </Button>
      )

    case 'KeysDontMatch':
      return (
        <Button className="w-full" onClick={() => useUserStore.setState({ showOnboarding: true })}>
          Authenticate
        </Button>
      )

    case 'ShouldDeposit':
      if (!isMainnet()) {
        return (
          <Button
            className="w-full"
            isLoading={accountsQuery.isLoading}
            onClick={() => useUserStore.setState({ showOnboarding: true })}
          >
            Create Account
          </Button>
        )
      }
      return (
        <Button className="w-full" onClick={() => depositStore.openModal()}>
          Create Account
        </Button>
      )
    case 'DepositInProgress':
      return (
        <Button
          className={cn('w-full animate-pulse gap-2', DepositBtnStatusStyle('pending'))}
          onClick={depositStore.openModal}
        >
          {depositStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
          Deposit in progress
        </Button>
      )
    case 'Creating':
      return (
        <Button className="w-full animate-pulse cursor-default gap-2 hover:bg-primary-blue-main">
          <Icon icon="spinner" className="size-5" />
          Creating Account
        </Button>
      )
    case 'Deciding':
      return <Button className="w-full" isLoading />
  }

  if (
    userAccount?.account_type === SubAccountType.lighterPublic ||
    userAccount?.account_type === SubAccountType.public
  ) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button disabled className="w-full">
            Unavailable
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">
            Only personal accounts can deposit into public pools
          </p>
        </TooltipContent>
      </Tooltip>
    )
  }

  return <>{children}</>
}

export default ActionButtonBlocker
