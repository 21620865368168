import { forwardRef, type HTMLAttributes } from 'react'

import cn from 'js/util/cn'

export const DialogBody = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <div
      className={cn('flex-1 overflow-y-scroll bg-white/5 px-6 py-4', className)}
      {...rest}
      ref={forwardedRef}
    />
  ),
)

DialogBody.displayName = 'DialogBody'
