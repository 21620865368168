import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { PublicPoolsRequest } from 'zklighter-perps'

import PublicPoolLeaderCell from 'js/pages/public-pools/PublicPoolList/cells/PublicPoolLeaderCell'
import PublicPoolNameCell from 'js/pages/public-pools/PublicPoolList/cells/PublicPoolNameCell'
import PublicPoolOperatorFeeCell from 'js/pages/public-pools/PublicPoolList/cells/PublicPoolOperatorFeeCell'
import PublicPoolTVLCell from 'js/pages/public-pools/PublicPoolList/cells/PublicPoolTVLCell'
import PublicPoolUserDepositCell from 'js/pages/public-pools/PublicPoolList/cells/PublicPoolUserDepositCell'
import { MaxAccountIndexLimit } from 'js/pages/public-pools/utils'
import { useAccountIndex } from 'js/providers/user-store'
import { accountApi } from 'js/util/api/sdk'
import { useLastTableItemRef } from 'js/util/table'

import HeaderCell from '../HeaderCell'
import Icon from '../uikit/Icon'
import Table from '../uikit/table/Table'
import TableBody from '../uikit/table/TableBody'
import TableHeader from '../uikit/table/TableHeader'
import TableHeaderRow from '../uikit/table/TableHeaderRow'
import TableLoader from '../uikit/table/TableLoader'
import TableRow from '../uikit/table/TableRow'

import { NoOrdersText } from './NoOrdersText'

const PAGE_SIZE = 20

export const UserPublicPoolsTable = () => {
  const accountIndex = useAccountIndex()
  const navigate = useNavigate()
  const params: Omit<PublicPoolsRequest, 'index'> = useMemo(
    () => ({ limit: PAGE_SIZE, filter: 'account_index', account_index: accountIndex! }),
    [accountIndex],
  )
  const publicPoolListQuery = useInfiniteQuery({
    queryKey: ['public_pools', params],
    queryFn: ({ pageParam }) => accountApi.publicPools({ index: pageParam, ...params }),
    enabled: !!accountIndex,
    refetchInterval: 5000,
    initialPageParam: MaxAccountIndexLimit,
    getNextPageParam: (lastPage) => lastPage.public_pools.at(PAGE_SIZE - 1)?.index,
  })
  const lastPoolRef = useLastTableItemRef(publicPoolListQuery)

  if (publicPoolListQuery.isPending) {
    return (
      <div className="flex h-[400px] w-full items-center justify-center">
        <Icon icon="spinner" className="size-6" />
      </div>
    )
  }

  if (publicPoolListQuery.isError) {
    return (
      <div className="flex h-full max-h-full min-h-64 items-center justify-center text-center">
        <p className="typography-body-2 text-white">There was an error loading the list</p>
      </div>
    )
  }

  const userPublicPools =
    publicPoolListQuery.data.pages.map((page) => page.public_pools).flat() ?? []

  if (userPublicPools.length === 0) {
    return <NoOrdersText type={'publicPools'} />
  }

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <HeaderCell title="Public Pool" />
          <HeaderCell title="Leader" />
          <HeaderCell title="TVL" />
          <HeaderCell title="Operator Fee" className="justify-end" />
          <HeaderCell title="Your Deposit" className="justify-end" />
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {userPublicPools.map((publicPool, index) => (
          <TableRow
            key={publicPool.index}
            onClick={() => navigate(`/public-pools/${publicPool.index}`)}
            ref={index === userPublicPools.length - 1 ? lastPoolRef : undefined}
          >
            <PublicPoolNameCell publicPool={publicPool} />
            <PublicPoolLeaderCell publicPool={publicPool} />
            <PublicPoolTVLCell publicPool={publicPool} />
            <PublicPoolOperatorFeeCell publicPool={publicPool} />
            <PublicPoolUserDepositCell publicPool={publicPool} />
          </TableRow>
        ))}
        {publicPoolListQuery.isFetchingNextPage && <TableLoader rows={1} columns={5} />}
      </TableBody>
    </Table>
  )
}
