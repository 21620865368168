import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface PositionAvgEntryPriceCellProps {
  position: Position
}

const PositionAvgEntryPriceCell = ({ position }: PositionAvgEntryPriceCellProps) => {
  const market = useOrderBookMetasQuery().data[position.market_id]!

  return (
    <TableCell>
      <p className="typography-body-1 text-white">
        {formatMarketPrice(position.avg_entry_price, market)}
      </p>
    </TableCell>
  )
}

export default PositionAvgEntryPriceCell
