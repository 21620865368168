import * as RadixDialog from '@radix-ui/react-dialog'
import { type ElementRef, forwardRef, type ComponentPropsWithoutRef } from 'react'

import cn from 'js/util/cn'

export const DialogContent = forwardRef<
  ElementRef<typeof RadixDialog.Content>,
  ComponentPropsWithoutRef<typeof RadixDialog.Content>
>(({ className, ...rest }, forwardedRef) => (
  <RadixDialog.Portal>
    <RadixDialog.Overlay className="fixed inset-0 z-50 animate-fadeIn backdrop-blur-sm transition-all" />
    <RadixDialog.Content
      {...rest}
      aria-describedby={undefined} // needed to shut up a warning
      className={cn(
        'text-white', // not adding text-white here creates a lot of issues
        'fixed z-50 animate-fadeIn max-mobile:origin-bottom max-mobile:data-[state=closed]:animate-growDown max-mobile:data-[state=open]:animate-growUp',
        'bottom-1/2 left-1/2 -translate-x-1/2 translate-y-1/2 max-mobile:bottom-0 max-mobile:left-0 max-mobile:translate-x-0 max-mobile:translate-y-0',
        'rounded-lg border border-white/10 bg-black max-mobile:rounded-none max-mobile:border-0 max-mobile:border-t max-mobile:bg-black/30 max-mobile:backdrop-blur-xl',
        'flex max-h-[calc(100dvh-60px)] w-[440px] flex-col overflow-hidden max-mobile:w-full',
        className,
      )}
      ref={forwardedRef}
    />
  </RadixDialog.Portal>
))

DialogContent.displayName = 'DialogContent'
