import { useSuspenseQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { OrderBookDetail } from 'zklighter-perps'

import { orderApi } from 'js/util/api/sdk'

import { type OrderbookItem } from '../types'

import { useTradePageParams } from './useTradePageParams'

const SYMBOL_MAPPING: { [key: string]: string } = {
  '1000PEPE': 'kPEPE',
}

export const useOrderBookMetasQuery = () =>
  useSuspenseQuery({
    queryKey: ['orderBookDetails'],
    queryFn: () =>
      orderApi
        .orderBookDetails()
        .then(
          ({ order_book_details }) =>
            Object.fromEntries(
              order_book_details.map((detail) => [
                detail.market_id,
                { ...detail, symbol: SYMBOL_MAPPING[detail.symbol] ?? detail.symbol },
              ]),
            ) as Record<string, OrderBookDetail>,
        ),
  })

export const getMarketOrderFromAmount0 = (
  market: OrderBookDetail,
  amount0: string,
  orders: OrderbookItem[],
) => {
  if (Number(amount0) === 0) {
    return { fullyFilled: true, estPrice: '0.00', amount0, amount1: '0' }
  }

  let amount0Left = Number(amount0)
  let matchedAmount0 = 0
  let matchedAmount1 = 0

  for (const order of orders) {
    const price = Number(order.price)
    const size = Number(order.size)
    const matchedSize = Math.min(amount0Left, size)

    matchedAmount0 = matchedAmount0 + matchedSize
    matchedAmount1 = matchedAmount1 + matchedSize * price
    amount0Left = amount0Left - matchedSize

    if (amount0Left === 0) {
      break
    }
  }

  if (matchedAmount0 === 0) {
    return { fullyFilled: false, estPrice: '0.00', amount0, amount1: '0' }
  }

  const estPrice = matchedAmount1 / matchedAmount0

  return {
    fullyFilled: amount0Left === 0,
    estPrice: estPrice.toFixed(market.price_decimals),
    amount0,
    amount1: matchedAmount1.toFixed(market.price_decimals),
  }
}

export const getMarketOrderFromAmount1 = (
  market: OrderBookDetail,
  amount1: string,
  orders: OrderbookItem[],
) => {
  if (Number(amount1) === 0) {
    return { fullyFilled: true, estPrice: '0.00', amount0: '0', amount1 }
  }

  let amount1Left = Number(amount1)
  let matchedAmount0 = 0
  let matchedAmount1 = 0

  for (const order of orders) {
    const price = Number(order.price)
    const size = Number(order.size)
    const matchedSize = Math.min(amount1Left / price, size)

    matchedAmount0 = matchedAmount0 + matchedSize
    matchedAmount1 = matchedAmount1 + matchedSize * price
    amount1Left = amount1Left - matchedSize * price

    const almostZero = 10 ** (-1 * market.price_decimals * market.size_decimals)
    if (Math.abs(amount1Left) < almostZero) {
      amount1Left = 0
      break
    }
  }

  if (matchedAmount0 === 0) {
    return { fullyFilled: false, estPrice: '0.00', amount0: '0', amount1 }
  }

  const estPrice = matchedAmount1 / matchedAmount0

  return {
    fullyFilled: amount1Left === 0,
    estPrice: estPrice.toFixed(market.price_decimals),
    amount0: matchedAmount0.toFixed(market.size_decimals),
    amount1,
  }
}

export const useMaybeCurrentMarketId = () => {
  const { symbol } = useTradePageParams()
  const orderBookMetasQuery = useOrderBookMetasQuery()

  return useMemo(
    () =>
      Object.values(orderBookMetasQuery.data).find((metas) => metas.symbol === symbol)?.market_id,
    [orderBookMetasQuery.data, symbol],
  )
}

export const useCurrentMarketId = () => useMaybeCurrentMarketId()!

export const useCurrentMarket = () => {
  const currentMarketId = useCurrentMarketId()
  const orderBookMetasQuery = useOrderBookMetasQuery()

  return useMemo(
    () => orderBookMetasQuery.data[currentMarketId]!,
    [orderBookMetasQuery.data, currentMarketId],
  )
}

export const useChangeMarket = () => {
  const orderBookMetasQuery = useOrderBookMetasQuery()
  const navigate = useNavigate()

  return (marketId: number) => {
    localStorage.setItem('last_symbol', orderBookMetasQuery.data[marketId]!.symbol)

    return navigate(`/trade/${orderBookMetasQuery.data[marketId]!.symbol}`)
  }
}
