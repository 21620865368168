import type { FundingHistoryItem } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface RateChangeCellProps {
  funding: FundingHistoryItem
}

const FundingRateCell = ({ funding }: RateChangeCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white">
      {Number(funding.rate).toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })}
    </p>
  </TableCell>
)

export default FundingRateCell
