import { useAccountPositions } from 'js/providers/accounts-store'
import { useMarketsStats } from 'js/providers/order-book-store'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatLeverage } from 'js/util/formatting'
import {
  computePositionLeverage,
  marginFractionToLeverage,
  usePortfolioStats,
} from 'js/util/positions'

interface PositionLeverageCellProps {
  position: Position
  accountIndex: number
}

const PositionLeverageCell = ({ position, accountIndex }: PositionLeverageCellProps) => {
  const positions = useAccountPositions(accountIndex)
  const portfolioValue = usePortfolioStats(positions, accountIndex)?.portfolioValue ?? 0
  const markPrice = Number(useMarketsStats()[position.market_id]?.mark_price ?? 0)
  const positionLeverage = formatLeverage(
    computePositionLeverage(Number(position.position), markPrice, portfolioValue),
    2,
  )
  const positionInitialLeverage = formatLeverage(
    marginFractionToLeverage(position.initial_margin_fraction),
  )

  return (
    <TableCell>
      <p className="typography-body-1 whitespace-nowrap text-white">{`${positionLeverage} | Max ${positionInitialLeverage}`}</p>
    </TableCell>
  )
}

export default PositionLeverageCell
