import { useState } from 'react'

import { usePublicAccounts, useSubAccounts } from 'js/providers/hooks/useAccountQuery'
import { BackgroundLights } from 'js/shared-components/BackgroundLights'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'

import { PageContainer } from '../page-container/PageContainer'

import CreateSubAccount from './CreateSubAccount'
import MainAccountList from './MainAccountList'
import { SubAccountsList } from './SubAccountsList'
import { TransferModal } from './TransferModal'

const SubAccounts = () => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const allSubAccounts = [...subAccounts, ...publicAccounts]

  return (
    <PageContainer className=" bg-gradient-to-b">
      <BackgroundLights />
      <TransferModal open={isTransferModalOpen} onOpenChange={setIsTransferModalOpen} />
      <div className="flex flex-col gap-8 px-80 pb-10 pt-20 max-mobile:p-1">
        <div className="flex items-center justify-between max-mobile:p-2">
          <p className="typography-text-8 max-mobile:typography-text-7 text-white">Sub-Accounts</p>
          <CreateSubAccount />
        </div>
        <div className="flex flex-col gap-3">
          <div className="whitespace-nowrap rounded-lg bg-white/5">
            <p className="typography-text-6 p-5 text-white">Main Account</p>
            <div className="overflow-scroll whitespace-nowrap">
              <MainAccountList />
            </div>
          </div>
          <div className="flex flex-col gap-3 overflow-x-hidden rounded-lg bg-gradient-to-b from-white/5 to-transparent">
            <div className="flex justify-between whitespace-nowrap  p-5">
              <p className="typography-text-6 text-white">
                Sub-Accounts {allSubAccounts.length > 0 && `(${allSubAccounts.length})`}
              </p>
              {subAccounts.length > 0 && (
                <Clickable
                  color="blue"
                  onClick={() => setIsTransferModalOpen(true)}
                  className="flex items-center gap-1"
                >
                  <p className="typography-label-1">Transfer</p>
                  <Icon icon="swap" className="size-4 rotate-90" />
                </Clickable>
              )}
            </div>
            <div className="flex min-h-[200px] flex-col items-center justify-start gap-9">
              <SubAccountsList openTransferModalOpen={() => setIsTransferModalOpen(true)} />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default SubAccounts
