import { useQuery } from '@tanstack/react-query'
import { useState, useMemo } from 'react'
import type { LeaderboardRequest } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { BackgroundLights } from 'js/shared-components/BackgroundLights'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { accountApi } from 'js/util/api/sdk'

import { PageContainer } from '../page-container/PageContainer'
import { PageSelector } from '../trade/components/orders/user-orders/PageSelector'

const PAGE_SIZE = 10

const Leaderboard = () => {
  const [page, setPage] = useState(0)

  const userAddress = useUserAddress()

  const params: LeaderboardRequest = useMemo(
    () => ({ l1_address: userAddress, type: 'all' }),
    [userAddress],
  )

  const leaderboardQuery = useQuery({
    queryKey: ['leaderboard', params],
    queryFn: () => accountApi.leaderboard(params),
    select: (data) => data.entries,
  })

  const leaderboard = useMemo(() => leaderboardQuery.data ?? [], [leaderboardQuery.data])

  const userPoints = useMemo(
    () => leaderboard.find((d) => d.l1_address === userAddress)?.points,
    [leaderboard, userAddress],
  )

  const rows = useMemo(
    () => leaderboard.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE),
    [leaderboard, page],
  )

  const totalPages = useMemo(() => Math.ceil(leaderboard.length / PAGE_SIZE), [leaderboard.length])

  return (
    <PageContainer>
      <BackgroundLights />
      <div className="flex flex-col gap-8 px-80 pb-10 pt-20 max-mobile:h-full max-mobile:p-0 max-mobile:px-1">
        <div className="flex flex-col gap-6 max-mobile:flex-row max-mobile:items-center max-mobile:justify-between max-mobile:p-5">
          <p className="typography-text-8 max-mobile:typography-text-7 text-white">Leaderboard</p>
          <div className="flex flex-col gap-2">
            <p className="typography-label-1 max-mobile:typography-body-2 text-white-opaque">
              Your Points
            </p>
            <p className="typography-text-8 max-mobile:typography-text-7 text-white">
              {userPoints}
            </p>
          </div>
        </div>
        <div className="whitespace-nowrap rounded-lg bg-gradient-to-b from-white/5 to-transparent max-mobile:h-full">
          <div className="w-full max-w-full gap-9 whitespace-nowrap max-mobile:flex max-mobile:h-full max-mobile:flex-col max-mobile:justify-between">
            <Table>
              <TableHeader>
                <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
                  <TableCell>
                    <p className="typography-body-2 text-white">Rank</p>
                  </TableCell>
                  <TableCell>
                    <p className="typography-body-2 text-white">Trader</p>
                  </TableCell>
                  <TableCell>
                    <p className="typography-body-2 text-white">Points</p>
                  </TableCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {rows.map(({ entry, points, l1_address, entryId }) => (
                  <TableRow key={entryId}>
                    <TableCell>
                      <p className="typography-body-2 text-white">{entry}</p>
                    </TableCell>
                    <TableCell>
                      <p className="typography-body-2 text-white">
                        {l1_address.substring(0, 6).concat('...').concat(l1_address.slice(-4))}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="typography-body-2 text-white">{points}</p>
                    </TableCell>
                  </TableRow>
                ))}
                {page + 1 === totalPages &&
                  new Array(
                    (PAGE_SIZE - ((leaderboard.length % PAGE_SIZE) % PAGE_SIZE)) % PAGE_SIZE,
                  )
                    .fill(null)
                    .map((_, index) => (
                      <TableRow key={`${index}-fake`} className="opacity-0">
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {totalPages > 1 && (
              <PageSelector
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                hasNextPage={page + 1 !== totalPages}
              />
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default Leaderboard
