import type { FundingHistoryItem } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import cn from 'js/util/cn'

interface FundingPaymentCellProps {
  funding: FundingHistoryItem
}

const FundingPaymentCell = ({ funding }: FundingPaymentCellProps) => {
  const isGreen = Number(funding.rate) < 0 === (funding.position_side === 'long')

  return (
    <TableCell>
      <p
        className={cn('typography-body-1', {
          'text-green-main': isGreen,
          'text-red-main': !isGreen,
        })}
      >
        {Number(funding.change).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 6,
          maximumFractionDigits: 6,
        })}
      </p>
    </TableCell>
  )
}

export default FundingPaymentCell
