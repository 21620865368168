import { useLatestDepositQuery } from 'js/pages/deposit/hooks'

import { useAccountIndex } from '../user-store'

import {
  useAccountsQuery,
  useIsGeoLocBlockedQuery,
  useIsRegisteredQuery,
  useIsWhitelistedQuery,
  useUserAddress,
} from './useAccountQuery'

type AccountExistence =
  | 'GeoBlocked'
  | 'NoWallet'
  | 'Deciding'
  | 'NotWhitelisted'
  | 'KeysDontMatch'
  | 'ShouldDeposit'
  | 'DepositInProgress'
  | 'Creating'
  | 'Exists'

export const useAccountExistence = (): AccountExistence => {
  const userAddress = useUserAddress()
  const accountIndex = useAccountIndex()
  const accountQuery = useAccountsQuery()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const latestDepositQuery = useLatestDepositQuery()
  const isRegistered = useIsRegisteredQuery().data
  const geoLocationQuery = useIsGeoLocBlockedQuery()

  if (geoLocationQuery.data) return 'GeoBlocked'

  if (!userAddress) return 'NoWallet'

  if (isWhitelistedQuery.isPending) return 'Deciding'
  if (isWhitelistedQuery.data === false) return 'NotWhitelisted'

  if (accountQuery.isPending) return 'Deciding'
  // handles the 1 render where we didn't se the accountIndex yet
  if (!accountIndex && accountQuery.isSuccess) return 'Deciding'

  if (!accountIndex) {
    if (latestDepositQuery.isPending) return 'Deciding'

    if (latestDepositQuery.data?.status === 'completed') return 'Creating'

    if (
      latestDepositQuery.data?.status === 'bridging' ||
      latestDepositQuery.data?.status === 'waitForTransfer'
    )
      return 'DepositInProgress'

    const isNoDepositError =
      !latestDepositQuery.data ||
      latestDepositQuery.error?.code === 29_404 ||
      latestDepositQuery.error?.message === 'not foundUser has no pending deposit'

    if (isNoDepositError) return 'ShouldDeposit'

    return 'Deciding'
  }

  if (isRegistered === null) return 'Deciding'
  if (isRegistered === false) return 'KeysDontMatch'

  return 'Exists'
}
