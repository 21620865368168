import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const OrderSizeInput = ({ value }: { value: string }) => {
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const onChange = (e: string) =>
    orderInputs.isAmountInUSD ? orderInputs.update('usd', e) : orderInputs.update('size', e)

  const symbol = orderInputs.isAmountInUSD ? 'USD' : currentMarket.symbol
  const decimal = orderInputs.isAmountInUSD
    ? currentMarket.price_decimals
    : currentMarket.size_decimals

  return (
    <UIKitCurrencyInput
      label="Amount"
      helperText={`Amount of ${symbol} to short or long. This is the amount your position will increase or decrease when the order is filled, not  the final position amount.`}
      symbol={symbol}
      value={value}
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
      hasCoinSelector
    />
  )
}
