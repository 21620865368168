import { forwardRef, type HTMLAttributes } from 'react'

import cn from 'js/util/cn'

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, onClick, ...rest }, forwardedRef) => (
    <tr
      ref={forwardedRef}
      className={cn(
        'h-10 max-h-10 min-h-10 border-b first:border-t last:border-b-0',
        {
          'hover:cursor-pointer hover:bg-grey-dark hover:[&>p]:[text-shadow:0_0_1px_currentColor]':
            !!onClick,
        },
        className,
      )}
      onClick={onClick}
      {...rest}
    />
  ),
)

TableRow.displayName = 'TableRow'

export default TableRow
