import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { OrderTypeEnum } from 'zklighter-perps'

import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { DropdownMenuContent, DropdownMenuItem } from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'

const stopButtonTypes = [
  { label: 'Stop Limit', value: OrderTypeEnum.StopLossLimit, shortName: 'SL Limit' },
  { label: 'Stop Market', value: OrderTypeEnum.StopLoss, shortName: 'SL Market' },
  { label: 'Take Profit Limit', value: OrderTypeEnum.TakeProfitLimit, shortName: 'TP Limit' },
  { label: 'Take Profit Market', value: OrderTypeEnum.TakeProfit, shortName: 'TP Market' },
  { label: 'TWAP', value: OrderTypeEnum.Twap, shortName: 'TWAP' },
] as const

export const OrderTypeSelector = ({
  orderType,
  setOrderTypeFromButton,
}: {
  orderType: OrderTypeEnum
  setOrderTypeFromButton: (orderType: OrderTypeEnum) => void
}) => (
  <TabBar>
    <TabButton
      isSelected={orderType === OrderTypeEnum.Market}
      onClick={() => setOrderTypeFromButton(OrderTypeEnum.Market)}
    >
      Market
    </TabButton>
    <TabButton
      isSelected={orderType === OrderTypeEnum.Limit}
      onClick={() => setOrderTypeFromButton(OrderTypeEnum.Limit)}
    >
      Limit
    </TabButton>
    <RadixDropdownMenu.DropdownMenu>
      <TabButton isSelected={!!stopButtonTypes.find((button) => button.value === orderType)}>
        <RadixDropdownMenu.Trigger className="group" asChild>
          <div className="flex items-center justify-center gap-2">
            {stopButtonTypes.find((button) => button.value === orderType)?.shortName ?? 'Advanced'}
            <Icon
              icon="chevron"
              className="mt-[2px] size-2.5 transition-transform group-data-[state=open]:rotate-180"
            />
          </div>
        </RadixDropdownMenu.Trigger>
      </TabButton>
      <DropdownMenuContent>
        {stopButtonTypes.map((button) => (
          <DropdownMenuItem
            className={cn({
              'bg-semi-transparent text-white': orderType === button.value,
              'bg-transparent text-white-opaque': orderType !== button.value,
            })}
            key={button.value}
            onClick={() => setOrderTypeFromButton(button.value)}
          >
            {button.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </RadixDropdownMenu.DropdownMenu>
    <SelectorIndicator
      numButtons={3}
      selectedElemIdx={
        orderType === OrderTypeEnum.Market ? 0 : orderType === OrderTypeEnum.Limit ? 1 : 2
      }
    />
  </TabBar>
)
