import { formatDate } from 'date-fns'
import React, { useMemo, useState } from 'react'

import { USDC_SYMBOL } from 'js/constants/shared'
import { ORDER_SIDE_TABS, type OrderSide } from 'js/constants/trades'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useOrderBookTrades } from 'js/providers/order-book-store'
import HeaderCell from 'js/shared-components/HeaderCell'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import cn from 'js/util/cn'

import { OrderListContainer } from '../styles'

export const TradesList = () => {
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')
  const trades = useOrderBookTrades()
  const currentMarket = useCurrentMarket()

  const tradesToDisplay = useMemo(
    () =>
      (trades ?? [])
        .filter((t) => {
          switch (selectedSide) {
            case 'asks':
              return !t.is_maker_ask
            case 'bids':
              return t.is_maker_ask
            case 'all':
            default:
              return true
          }
        })
        .slice(0, 30),
    [trades, selectedSide],
  )

  return (
    <>
      <div className="flex h-10 min-h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">Market Trades</p>
        <SelectorDropdown
          selectedOption={selectedSide}
          options={ORDER_SIDE_TABS.map((tab) => ({
            ...tab,
            onClick: () => setSelectedSide(tab.key),
          }))}
        />
      </div>
      <OrderListContainer>
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <HeaderCell title="Time" />
              <HeaderCell title="Size" symbol={currentMarket.symbol} className="justify-end" />
              <HeaderCell title="Price" symbol={USDC_SYMBOL} className="justify-end" />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {tradesToDisplay.map((trade) => (
              <TableRow
                key={trade.trade_id}
                className="h-5 border-0"
                onClick={() =>
                  window.open(`${import.meta.env.VITE_SCANNER_BASE}/tx/${trade.tx_hash}`, '_blank')
                }
              >
                <TableCell>
                  <p className="typography-body-1 text-white">
                    {formatDate(new Date(trade.timestamp), 'HH:mm:ss')}
                  </p>
                </TableCell>
                <TableCell>
                  <p
                    className={cn('typography-body-1 text-end', {
                      'text-green-main': trade.is_maker_ask,
                      'text-red-main': !trade.is_maker_ask,
                    })}
                  >
                    {trade.size}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="typography-body-1 text-white">{trade.price}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </OrderListContainer>
    </>
  )
}
