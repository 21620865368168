import { type PublicPool } from 'zklighter-perps'

import { getYourDeposits } from 'js/pages/public-pool/utils'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface PublicPoolUserDepositCellProps {
  publicPool: PublicPool
}

const PublicPoolUserDepositCell = ({ publicPool }: PublicPoolUserDepositCellProps) => {
  const { isMobile } = useResponsiveness()

  return (
    <TableCell>
      <p className="typography-body-2 text-white">
        {getYourDeposits(
          publicPool.account_share ? [publicPool.account_share] : [],
          publicPool.index,
          Number(publicPool.total_asset_value),
          publicPool.pool_info.total_shares,
          isMobile,
        )}
      </p>
    </TableCell>
  )
}
export default PublicPoolUserDepositCell
