import * as RadixPopover from '@radix-ui/react-popover'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

import { MobileTooltipArrow } from './MobileTooltipArrow'

export const MobileTooltipContent = forwardRef<
  ElementRef<typeof RadixPopover.Content>,
  ComponentPropsWithoutRef<typeof RadixPopover.Content>
>(({ sideOffset = 10, side = 'bottom', arrowPadding = 4, className, ...rest }, forwardedRef) => (
  <RadixPopover.Content
    ref={forwardedRef}
    sideOffset={sideOffset}
    side={side}
    arrowPadding={arrowPadding}
    className={cn(
      'z-20 min-w-[200px] max-w-[350px] whitespace-pre-line rounded-lg bg-blue-grey px-4 py-2',
      'text-start data-[state=closed]:animate-fadeOut data-[state=delayed-open]:animate-fadeIn',
      className,
    )}
    {...rest}
  >
    {rest.children}
    <MobileTooltipArrow />
  </RadixPopover.Content>
))

MobileTooltipContent.displayName = 'MobileTooltipContent'
