import { useState } from 'react'
import { toast } from 'sonner'

import { useShowFeedback, useUserStore } from 'js/providers/user-store'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postFeedback } from 'js/util/api/api'

import { Button } from './Button'
import { Modal } from './Modal'
import Input from './uikit/Input'
import Textarea from './uikit/Textarea'
import Toast from './uikit/Toast'

export const SupportModal = () => {
  const showFeedback = useShowFeedback()
  const [isLoading, setIsLoading] = useState(false)
  const [telegram, setTelegram] = useState('')
  const [feedback, setFeedback] = useState('')

  const onSubmit = async () => {
    setIsLoading(true)
    const res = await postFeedback({ telegram, feedback })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="SupportModal submitted successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      useUserStore.setState({ showFeedback: false })
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
    setIsLoading(false)
  }

  return (
    <Modal
      open={showFeedback}
      title="Support"
      dataTestId="feedback-modal"
      preventClose={isLoading}
      onOpenChange={(newOpen) => {
        useUserStore.setState({ showFeedback: newOpen })

        if (!newOpen) {
          setTelegram('')
          setFeedback('')
        }
      }}
    >
      <div className="flex flex-col gap-6">
        <p className="typography-label-1 text-white">
          We are constantly improving our protocol and would love to hear your feedback. Please feel
          free to reach out to us with any suggestions or comments and we will get back to you
          promptly.
        </p>
        <Input
          className="bg-white-transparent"
          placeholder="Your telegram"
          onChange={(e) => setTelegram(e.target.value)}
        />
        <Textarea
          className="bg-white-transparent pt-2"
          placeholder="Your feedback"
          onChange={(e) => setFeedback(e.target.value)}
        />
        <div className="flex justify-end gap-4">
          <Tooltip open={feedback && telegram ? false : undefined}>
            <TooltipTrigger asChild>
              <Button
                disabled={!telegram.trim() || !feedback.trim()}
                isLoading={isLoading}
                onClick={onSubmit}
                className="w-full"
              >
                Submit
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="typography-body-2 text-white">
                {!telegram
                  ? 'Please provide your telegram'
                  : !feedback
                    ? 'Please provide feedback'
                    : ''}
              </p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}
