import { forwardRef, type HTMLAttributes } from 'react'

import cn from 'js/util/cn'

const SkeletonRectangle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={cn(
        'relative inline-block overflow-hidden',
        'size-full bg-grey-dark',
        'after:absolute after:inset-0',
        className,
      )}
      {...rest}
    >
      <div className="absolute inset-0 -translate-x-full animate-[skeletonShimmer_2s_infinite] bg-gradient-to-r from-grey-dark to-grey-main opacity-50" />
    </div>
  ),
)

SkeletonRectangle.displayName = 'SkeletonRectangle'

export default SkeletonRectangle
