import React, { forwardRef, type ButtonHTMLAttributes } from 'react'

import cn from 'js/util/cn'

interface ClickableProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'green' | 'blue' | 'grey' | 'red' | 'secondaryBlue' | 'white' | 'none'
}

export const Clickable = forwardRef<HTMLButtonElement, ClickableProps>(
  ({ className, onClick, color = 'white', ...rest }, forwardedRef) => (
    <button
      ref={forwardedRef}
      onClick={onClick}
      className={cn(
        'typography-label-1 size-fit overflow-hidden bg-transparent transition-all',
        {
          'text-white hover:text-white-opaque': color === 'white',
          'text-green-main hover:text-green-pale': color === 'green',
          'text-primary-blue-main hover:text-primary-blue-pale': color === 'blue',
          'text-secondary-blue-main hover:text-secondary-blue-pale': color === 'secondaryBlue',
          'text-red-main hover:text-red-pale': color === 'red',
          'text-grey-main hover:text-grey-dark': color === 'grey',
        },
        className,
      )}
      {...rest}
    />
  ),
)

Clickable.displayName = 'Button'
