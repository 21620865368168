export const NoOrdersText = ({
  type,
}: {
  type:
    | 'activeOrders'
    | 'positions'
    | 'orderHistory'
    | 'tradeHistory'
    | 'fundingHistory'
    | 'publicPools'
    | 'deposits'
    | 'withdraws'
}) => {
  const getText = () => {
    switch (type) {
      case 'activeOrders':
        return 'No Open Orders'
      case 'positions':
        return 'No Open Positions'
      case 'orderHistory':
        return 'No Order History'
      case 'tradeHistory':
        return 'No Trades'
      case 'fundingHistory':
        return 'No Funding History'
      case 'publicPools':
        return 'No User Public Pools'
      case 'deposits':
        return 'No Deposits'
      case 'withdraws':
        return 'No Withdrawals'
      default:
        return ''
    }
  }
  return (
    <div className="flex h-full max-h-full min-h-64 items-center justify-center text-center">
      <p className="typography-label-1 m-auto text-white">{getText()}</p>
    </div>
  )
}
