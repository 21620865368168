import { formatDate, fromUnixTime } from 'date-fns'

import TableCell from '../uikit/table/TableCell'

interface DateCellProps {
  timestamp: number
}

const DateCell = ({ timestamp }: DateCellProps) => (
  <TableCell>
    <p className="typography-body-1 text-white mobile:whitespace-nowrap">
      {formatDate(fromUnixTime(timestamp), 'MM-dd HH:mm:ss')}
    </p>
  </TableCell>
)

export default DateCell
