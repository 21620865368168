import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import {
  DynamicContextProvider,
  DynamicWidget,
  useDynamicContext,
} from '@dynamic-labs/sdk-react-core'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, type ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'

import { DepositModal } from './pages/deposit/form'
import ErrorPage from './pages/error'
import { Maintenance } from './pages/maintenance'
import { WithdrawModal } from './pages/withdraw/form'
import { useAccount, useIsGeoLocBlockedQuery } from './providers/hooks/useAccountQuery'
import { useInitWs } from './providers/hooks/useInitWs'
import { useSiteTitleSetter } from './providers/hooks/useSiteTitleSetter'
import { useZklLighterInfoQuery } from './providers/hooks/useZkLighterL1'
import { useUserStore } from './providers/user-store'
import ResponsivenessProvider from './ResponsivenessProvider'
import { LoadingSpinner } from './shared-components'
import useQueryClientConfig from './util/api/useQueryClientConfig'
import cn from './util/cn'
import { useHealthQuery } from './util/queries'
import { isMainnet } from './util/util'

const HealthCheck = ({ children }: { children: ReactNode }) => {
  const healthQuery = useHealthQuery()
  const { sdkHasLoaded } = useDynamicContext()

  if (healthQuery.isLoading || !sdkHasLoaded) {
    return <LoadingSpinner />
  }

  if (!healthQuery.data?.online) {
    return <Maintenance type="no-internet" />
  }

  if (!healthQuery.data?.serverOk) {
    return <Maintenance type="maintenance" />
  }

  return <>{children}</>
}

const DataSync = () => {
  useSiteTitleSetter()
  useZklLighterInfoQuery()
  useIsGeoLocBlockedQuery()
  useAccount()
  useInitWs()
  return null
}

const Wrapper = () => {
  const queryClient = useQueryClientConfig()

  return (
    <ResponsivenessProvider>
      <TooltipProvider delayDuration={0} skipDelayDuration={0} disableHoverableContent>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <DynamicContextProvider
            settings={{
              mobileExperience: 'redirect',
              walletConnectors: [EthereumWalletConnectors],
              appLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Examplelogo.svg',
              initialAuthenticationMode: 'connect-only',
              appName: 'Lighter',
              environmentId: 'de15f0f9-44b8-47c0-871d-6ca54c5e6a54',
              localStorageSuffix: 'v4',
              events: {
                onWalletAdded: () => {
                  if (!isMainnet()) {
                    useUserStore.setState({ showOnboarding: true })
                  }
                },
              },
            }}
          >
            <div className="absolute opacity-0">
              <DynamicWidget />
            </div>
            <Suspense fallback={<LoadingSpinner />}>
              <QueryClientProvider client={queryClient}>
                <HealthCheck>
                  <WithdrawModal />
                  <DepositModal />
                  <DataSync />
                  <Outlet />
                  <ReactQueryDevtools buttonPosition="bottom-left" position="bottom" />
                  <Toaster cn={cn} className="mobile:![--width:240px]" />
                </HealthCheck>
              </QueryClientProvider>
            </Suspense>
          </DynamicContextProvider>
        </Sentry.ErrorBoundary>
      </TooltipProvider>
    </ResponsivenessProvider>
  )
}

export default Wrapper
