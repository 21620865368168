import {
  useIsWhitelistedQuery,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'

export const NotLoggedInText = () => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const isWhitelistedQuery = useIsWhitelistedQuery()

  const getLogInText = () => {
    if (!userAddress) {
      return 'Please connect your wallet'
    } else if (!isWhitelistedQuery.data) {
      return ''
    } else if (!userAccount) {
      return 'Deposit funds to create an account'
    }
  }
  return (
    <div className="flex h-full max-h-full min-h-64 items-center justify-center text-center">
      <span className="typography-label-1 m-auto text-white">{getLogInText()}</span>
    </div>
  )
}
