import { useCallback, useEffect, useRef } from 'react'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMidPrice } from 'js/providers/order-book-store'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const PriceInput = () => {
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const midPrice = useMidPrice()
  const value = orderInputs.limitPrice
  const onChange = useCallback((e: string) => orderInputs.update('limitPrice', e), [orderInputs])
  const decimal = currentMarket.price_decimals
  const didSetRef = useRef(false)

  useEffect(() => {
    didSetRef.current = false
  }, [currentMarket.symbol])

  useEffect(() => {
    const notReady = !midPrice
    const notEmpty = !!value
    if (notReady || notEmpty || !orderInputs.isLimit() || didSetRef.current) return
    onChange(midPrice.toFixed(decimal))
    didSetRef.current = true
  }, [midPrice, onChange, decimal, currentMarket.symbol, value, orderInputs])

  return (
    <UIKitPriceInput
      label="Limit Price (USD)"
      helperText="This order can only be filled at the specified limit price or better. If your order crosses at placement, it will fill any crossing orders at the most favorable price."
      value={value}
      onClick={() => onChange(midPrice.toFixed(decimal))}
      buttonText="Mid"
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
