import * as RadixPopover from '@radix-ui/react-popover'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

export const PopoverContent = forwardRef<
  ElementRef<typeof RadixPopover.Content>,
  ComponentPropsWithoutRef<typeof RadixPopover.Content>
>(({ sideOffset = 8, className, ...rest }, forwardedRef) => (
  <RadixPopover.Content
    ref={forwardedRef}
    sideOffset={sideOffset}
    className={cn(
      'z-[100] overflow-hidden rounded-lg border bg-black shadow-dark',
      'data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn',
      className,
    )}
    {...rest}
  />
))

PopoverContent.displayName = 'PopoverContent'
