import { cloneDeep, partition, uniqBy } from 'lodash-es'
import { OrderStatusEnum, OrderTypeEnum, TradeTypeEnum } from 'zklighter-perps'

import { useAccountsStore } from '../accounts-store'
import { useOrderBookStore } from '../order-book-store'
import {
  type Liquidation,
  type Trade,
  type AccountStoreData,
  type Share,
  type FundingHistoryItem,
  type Tx,
} from '../types'

import {
  type WsSubscribedMarketStatsMessage,
  type WsSubscribedOrderBookMessage,
  type WsSubscribedTradeMessage,
  type WsSubscribedAccountMessage,
  type WsUpdateMarketStatsMessage,
  type WsUpdateOrderBookMessage,
  type WsUpdateTradeMessage,
  type WsOrderbook,
  type WsUpdateAccountMessage,
  type WsSubscribedUserStatsMessage,
  type WsUpdateUserStatsMessage,
  type WsTrade,
  type WsMarketStats,
  type WsOrder,
  type WsPosition,
  type WsUpdateExecutedTransactionMessage,
  type WsSubscribedPoolInfoMessage,
  type WsUpdatePoolInfoMessage,
  type WsSubscribedHeightMessage,
  type WsUpdateHeightMessage,
  type WsSubscribedPoolDataMessage,
  type WsUpdatePoolDataMessage,
  type WsSubscribedAccountOrderMessage,
  type WsUpdateAccountOrderMessage,
  type WsMessage,
} from './types'

import { useWsSubStore } from '.'

const checkMarketIndexFromChannel = (channel: string, currentId: number) =>
  channel.split(':')[1] !== 'all' && currentId !== Number(channel.split(':')[1])

const getAccountIndexFromChannel = (channel: string) => {
  const accountIndex = Number(channel.split(':')[1])

  return Number.isNaN(accountIndex) ? -1 : accountIndex
}

type AccountTxCallback = (tx: Tx) => void

const accountTxCallbacks: AccountTxCallback[] = []

export const subscribeToAccountTx = (callback: AccountTxCallback) =>
  accountTxCallbacks.push(callback)
export const unsubscribeToAccountTx = (callback: AccountTxCallback) =>
  accountTxCallbacks.filter((cb) => cb !== callback)

export const handleWsMessage = (data: WsMessage) => {
  switch (data.type) {
    case 'ping':
      return handlePing()
    case 'subscribed/order_book':
      return handleSubscribedOrderBook(data)
    case 'update/order_book':
      return handleUpdateOrderBook(data)
    case 'subscribed/trade':
      return handleSubscribedTrade(data)
    case 'update/trade':
      return handleUpdateTrade(data)
    case 'subscribed/market_stats':
      return handleSubscribedMarketStats(data)
    case 'update/market_stats':
      return handleUpdateMarketStats(data)
    case 'subscribed/account_all':
      return handleSubscribedAccount(data)
    case 'update/account_all':
      return handleUpdateAccount(data)
    case 'subscribed/account_orders':
      return handleSubscribedAccountOrders(data)
    case 'update/account_orders':
      return handleUpdateAccountOrders(data)
    case 'subscribed/pool_data':
      return handleSubscribedPoolData(data)
    case 'update/pool_data':
      return handleUpdatePoolData(data)
    case 'subscribed/user_stats':
      return handleSubscribedUserStats(data)
    case 'update/user_stats':
      return handleUpdateUserStats(data)
    case 'update/account_tx':
      return handleUpdateAccountTx(data)
    case 'subscribed/pool_info':
      return handleSubscribedPoolInfo(data)
    case 'update/pool_info':
      return handleUpdatePoolInfo(data)
    case 'subscribed/height':
      return handleSubscribedHeight(data)
    case 'update/height':
      return handleUpdatedHeight(data)
    default:
      return
  }
}

class WsReducer {
  orderBook: WsOrderbook
  trades: Trade[]
  marketsStats: Record<string, WsMarketStats>
  accounts: Record<number, AccountStoreData>
  bufferedOrderMessages: Record<number, Array<Record<string, WsOrder[]>>>
  currentMarketId: number

  constructor() {
    this.orderBook = { asks: [], bids: [] }
    this.trades = []
    this.marketsStats = {}
    this.accounts = {}
    this.bufferedOrderMessages = {}
    this.currentMarketId = -1
  }

  reduceOrderbook(newOrderbook: WsOrderbook) {
    const diffAskMap: Record<string, string> = {}
    newOrderbook.asks.forEach((ask) => {
      diffAskMap[ask.price] = ask.size
    })
    this.orderBook.asks.forEach((ask) => {
      const newSize = diffAskMap[ask.price]
      if (newSize !== undefined) {
        ask.size = newSize
        delete diffAskMap[ask.price]
      }
    })
    Object.entries(diffAskMap).forEach(([price, size]) => this.orderBook.asks.push({ price, size }))
    this.orderBook.asks = this.orderBook.asks.filter((ask) => Number(ask.size) !== 0)
    this.orderBook.asks.sort((a, b) => Number(a.price) - Number(b.price))

    const diffBidsMap: Record<string, string> = {}
    newOrderbook.bids.forEach((bid) => {
      diffBidsMap[bid.price] = bid.size
    })
    this.orderBook.bids.forEach((bid) => {
      const newSize = diffBidsMap[bid.price]
      if (newSize) {
        bid.size = newSize
        delete diffBidsMap[bid.price]
      }
    })
    Object.entries(diffBidsMap).forEach(([price, size]) =>
      this.orderBook.bids.push({ price, size }),
    )
    this.orderBook.bids = this.orderBook.bids.filter((bid) => Number(bid.size) !== 0)
    this.orderBook.bids.sort((a, b) => Number(b.price) - Number(a.price))
  }

  reduceTrades(newTrades: WsTrade[]) {
    this.trades = newTrades.filter(
      (trade) => trade.type === TradeTypeEnum.Trade || trade.type === TradeTypeEnum.Liquidation,
    )
  }

  reduceUpdateTrades(newTrades: WsTrade[]) {
    // we only show 30 in TradesList.tsx, but because we do some more filtering
    // there, we'll keep more than enough here so there's always at least 30 there
    // it's necessary to prune some trades here to prevent memory leak
    this.trades = [
      ...newTrades.filter(
        (trade) => trade.type === TradeTypeEnum.Trade || trade.type === TradeTypeEnum.Liquidation,
      ),
      ...this.trades,
    ].slice(0, 100)
  }

  reduceMarketStats(newMarketsStats: Record<string, WsMarketStats>) {
    this.marketsStats = { ...this.marketsStats, ...newMarketsStats }
  }

  reduceAccount(
    accountIndex: number,
    activeOrders: Record<string, WsOrder[]>,
    trades: Record<string, WsTrade[]>,
    positions: Record<string, WsPosition>,
    liquidations: Liquidation[],
    shares: Share[],
    fundingHistory: Record<string, FundingHistoryItem[]>,
  ) {
    this.accounts[accountIndex] = {
      trades,
      activeOrders,
      inactiveOrders: {},
      positions,
      liquidations,
      shares: shares.filter((share) => share.shares_amount !== 0),
      fundingHistory,
    }

    this.bufferedOrderMessages[accountIndex]?.forEach((orders) =>
      this.reduceUpdateAccountOrders(accountIndex, orders),
    )
    delete this.bufferedOrderMessages[accountIndex]
  }

  reduceUpdateAccountOrders(accountIndex: number, orders: Record<string, WsOrder[]>) {
    if (!this.accounts[accountIndex]) {
      if (!this.bufferedOrderMessages[accountIndex]) {
        this.bufferedOrderMessages[accountIndex] = []
      }

      return this.bufferedOrderMessages[accountIndex]!.push(orders)
    }

    const updatedActiveOrders = { ...this.accounts[accountIndex]!.activeOrders }
    const updatedInactiveOrders = { ...this.accounts[accountIndex]!.inactiveOrders }

    Object.keys(orders).forEach((marketId) => {
      const oldMarketActiveOrders = updatedActiveOrders[marketId] ?? []
      const oldMarketInactiveOrders = updatedInactiveOrders[marketId] ?? []
      const newMarketOrders = orders[marketId] ?? []

      const [newMarketActiveOrders, newMarketInactiveOrders] = partition(
        newMarketOrders,
        (order) => {
          if (Number(order.remaining_base_amount) === 0) {
            return false
          }

          if (order.status === OrderStatusEnum.Open) {
            return true
          }

          if (
            (order.type === OrderTypeEnum.StopLoss ||
              order.type === OrderTypeEnum.TakeProfit ||
              order.type === OrderTypeEnum.StopLossLimit ||
              order.type === OrderTypeEnum.TakeProfitLimit ||
              order.type === OrderTypeEnum.Twap ||
              order.type === OrderTypeEnum.TwapSub) &&
            order.status === OrderStatusEnum.Pending
          ) {
            return true
          }

          return false
        },
      )
      const inactiveIDs = new Set(newMarketInactiveOrders.map((order) => order.order_index))

      updatedActiveOrders[marketId] = uniqBy(
        [
          // order of spread matters here: uniqBy keeps first occurence and we want to keep the new ones
          ...newMarketActiveOrders,
          // an order can only go from active -> inactive so this filter is only needed here
          ...oldMarketActiveOrders.filter((order) => !inactiveIDs.has(order.order_index)),
        ],
        'order_index',
      ).slice(0, 300)
      updatedInactiveOrders[marketId] = uniqBy(
        // order of spread matters here: uniqBy keeps first occurence and we want to keep the new ones
        [...newMarketInactiveOrders, ...oldMarketInactiveOrders],
        'order_index',
      ).slice(0, 200)
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      activeOrders: updatedActiveOrders,
      inactiveOrders: updatedInactiveOrders,
    }
  }

  reduceUpdateAccountTrades(accountIndex: number, trades: Record<string, WsTrade[]>) {
    const updatedTrades = { ...this.accounts[accountIndex]!.trades }

    Object.keys(trades).forEach((marketId) => {
      const oldTrades = updatedTrades[marketId] ?? []
      const newTrades = trades[marketId] ?? []
      updatedTrades[marketId] = [...newTrades, ...oldTrades].slice(0, 200)
    })
    this.accounts[accountIndex] = { ...this.accounts[accountIndex]!, trades: updatedTrades }
  }

  reduceUpdateAccountPositions(accountIndex: number, positions: Record<string, WsPosition>) {
    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      positions: { ...this.accounts[accountIndex]!.positions, ...positions },
    }
  }

  reduceUpdateAccountLiquidations(accountIndex: number, liquidations: Liquidation[]) {
    const updatedLiquidations = [...this.accounts[accountIndex]!.liquidations]

    liquidations.forEach((liquidation) => {
      const existentLiquidation = updatedLiquidations.find(
        ({ liquidation_id }) => liquidation_id === liquidation.liquidation_id,
      )

      if (!existentLiquidation) {
        return updatedLiquidations.push(liquidation)
      }

      // TODO: check if this case even makes sense
      existentLiquidation.liquidation_type = liquidation.liquidation_type
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      liquidations: updatedLiquidations,
    }
  }

  reduceUpdateAccountShares(accountIndex: number, shares: Share[]) {
    const updatedShares = [...this.accounts[accountIndex]!.shares]

    shares.forEach((share) => {
      const existentShare = updatedShares.find(
        ({ public_pool_index }) => public_pool_index === share.public_pool_index,
      )
      if (!existentShare) {
        return updatedShares.push(share)
      }

      existentShare.shares_amount = share.shares_amount
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      shares: updatedShares.filter((share) => share.shares_amount !== 0),
    }
  }

  reduceUpdateAccountFundingHistory(
    accountIndex: number,
    fundingHistory: Record<string, FundingHistoryItem[]>,
  ) {
    const updatedFundingHistory = { ...this.accounts[accountIndex]!.fundingHistory }

    Object.keys(fundingHistory).forEach((marketId) => {
      const oldFundingHistory = updatedFundingHistory[marketId] ?? []
      const newFundingHistory = fundingHistory[marketId] ?? []
      updatedFundingHistory[marketId] = [...newFundingHistory, ...oldFundingHistory]
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      fundingHistory: updatedFundingHistory,
    }
  }
}

const handlePing = () => useWsSubStore.getState().ws?.send(JSON.stringify({ type: 'pong' }))

const wsReducer = new WsReducer()
let orderBookIntervalId: NodeJS.Timeout | null = null
let tradeIntervalId: NodeJS.Timeout | null = null

const publishOrderBookChanges = () =>
  // reducer mutates the orderBook for performance reasons
  // but this needs to be cloned when published to zustand store
  useOrderBookStore.setState({ orderBook: cloneDeep(wsReducer.orderBook) })

const handleSubscribedOrderBook = (data: WsSubscribedOrderBookMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceOrderbook(data.order_book)

  publishOrderBookChanges()
  orderBookIntervalId = setInterval(publishOrderBookChanges, 500)
}

const handleUpdateOrderBook = (data: WsUpdateOrderBookMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceOrderbook(data.order_book)
}

const publishTradeChanges = () => useOrderBookStore.setState({ trades: wsReducer.trades })

const handleSubscribedTrade = (data: WsSubscribedTradeMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceTrades(data.trades)

  publishTradeChanges()
  tradeIntervalId = setInterval(publishTradeChanges, 500)
}

const handleUpdateTrade = (data: WsUpdateTradeMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceUpdateTrades(data.trades)
}

const publishMarketStats = () =>
  useOrderBookStore.setState({ marketsStats: wsReducer.marketsStats })

const handleSubscribedMarketStats = (data: WsSubscribedMarketStatsMessage) => {
  wsReducer.reduceMarketStats(data.market_stats)

  publishMarketStats()
  setInterval(publishMarketStats, 500)
}

const handleUpdateMarketStats = (data: WsUpdateMarketStatsMessage) =>
  wsReducer.reduceMarketStats(data.market_stats)

const handleSubscribedAccount = (data: WsSubscribedAccountMessage) => {
  wsReducer.reduceAccount(
    getAccountIndexFromChannel(data.channel),
    {},
    data.trades,
    data.positions,
    data.liquidations,
    data.shares,
    data.funding_histories,
  )

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleUpdateAccount = (data: WsUpdateAccountMessage) => {
  const accountIndex = getAccountIndexFromChannel(data.channel)

  if (!wsReducer.accounts[accountIndex]) {
    return
  }

  wsReducer.reduceUpdateAccountTrades(accountIndex, data.trades)
  wsReducer.reduceUpdateAccountPositions(accountIndex, data.positions)
  wsReducer.reduceUpdateAccountLiquidations(accountIndex, data.liquidations)
  wsReducer.reduceUpdateAccountShares(accountIndex, data.shares)
  wsReducer.reduceUpdateAccountFundingHistory(accountIndex, data.funding_histories)

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleSubscribedAccountOrders = (data: WsSubscribedAccountOrderMessage) => {
  wsReducer.reduceUpdateAccountOrders(data.account, data.orders)

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleUpdateAccountOrders = (data: WsUpdateAccountOrderMessage) => {
  wsReducer.reduceUpdateAccountOrders(data.account, data.orders)

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleSubscribedPoolData = (data: WsSubscribedPoolDataMessage) => {
  wsReducer.reduceAccount(
    getAccountIndexFromChannel(data.channel),
    data.orders,
    data.trades,
    data.positions,
    data.liquidations,
    data.shares,
    data.funding_histories,
  )

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleUpdatePoolData = (data: WsUpdatePoolDataMessage) => {
  const accountIndex = getAccountIndexFromChannel(data.channel)

  wsReducer.reduceUpdateAccountOrders(accountIndex, data.orders)
  wsReducer.reduceUpdateAccountTrades(accountIndex, data.trades)
  wsReducer.reduceUpdateAccountPositions(accountIndex, data.positions)
  wsReducer.reduceUpdateAccountLiquidations(accountIndex, data.liquidations)
  wsReducer.reduceUpdateAccountShares(accountIndex, data.shares)
  wsReducer.reduceUpdateAccountFundingHistory(accountIndex, data.funding_histories)

  useAccountsStore.setState({ accounts: wsReducer.accounts })
}

const handleSubscribedUserStats = (data: WsSubscribedUserStatsMessage) =>
  useAccountsStore.setState((prev) => ({
    ...prev,
    portfolioStats: {
      ...prev.portfolioStats,
      [getAccountIndexFromChannel(data.channel)]: data.stats,
    },
  }))

const handleUpdateUserStats = (data: WsUpdateUserStatsMessage) =>
  useAccountsStore.setState((prev) => ({
    ...prev,
    portfolioStats: {
      ...prev.portfolioStats,
      [getAccountIndexFromChannel(data.channel)]: data.stats,
    },
  }))

const handleUpdateAccountTx = (data: WsUpdateExecutedTransactionMessage) =>
  data.txs.forEach((tx) => accountTxCallbacks.forEach((cb) => cb(tx)))

const handleSubscribedPoolInfo = (data: WsSubscribedPoolInfoMessage) =>
  useAccountsStore.setState((prev) => ({
    ...prev,
    poolInfo: { ...prev.poolInfo, [getAccountIndexFromChannel(data.channel)]: data.pool_info },
  }))

const handleUpdatePoolInfo = (data: WsUpdatePoolInfoMessage) =>
  useAccountsStore.setState((prev) => ({
    ...prev,
    poolInfo: { ...prev.poolInfo, [getAccountIndexFromChannel(data.channel)]: data.pool_info },
  }))

const handleSubscribedHeight = (data: WsSubscribedHeightMessage) =>
  useOrderBookStore.setState({ height: data.height })

const handleUpdatedHeight = (data: WsUpdateHeightMessage) =>
  useOrderBookStore.setState({ height: data.height })

export const handleChangeDesiredMarket = (marketIndex: number) => {
  if (typeof orderBookIntervalId === 'number') {
    clearInterval(orderBookIntervalId)
    orderBookIntervalId = null
  }

  if (typeof tradeIntervalId === 'number') {
    clearInterval(tradeIntervalId)
    tradeIntervalId = null
  }

  wsReducer.trades = []
  wsReducer.orderBook = { asks: [], bids: [] }
  wsReducer.currentMarketId = marketIndex

  useOrderBookStore.setState({ orderBook: null, trades: null })
}
