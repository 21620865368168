import { USDC_SYMBOL } from 'js/constants/shared'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { useUserAccountPortfolioStats } from 'js/util/positions'

export const AmountInput = ({
  value,
  onChange,
  decimal,
}: {
  value: string
  onChange: (value: string) => void
  decimal: number
}) => {
  const availableToTransfer = useUserAccountPortfolioStats()?.portfolioValue ?? 0

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

    if (newValue === '') {
      return onChange(newValue)
    }

    if (!/^[0-9]*\.?[0-9]{0,2}$/.test(newValue)) {
      return
    }

    if (Number(newValue) > availableToTransfer) {
      return onChange(availableToTransfer.toFixed(2))
    }

    onChange(newValue)
  }

  return (
    <UIKitCurrencyInput
      label="Amount"
      value={value}
      onChange={onInputChange}
      placeholder={Number(0).toFixed(decimal)}
      symbol={USDC_SYMBOL}
    />
  )
}
