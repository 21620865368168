import React, { useMemo } from 'react'

import { useOrderInputStore } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMidPrice } from 'js/providers/order-book-store'
import { type OrderbookItem } from 'js/providers/types'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import cn from 'js/util/cn'
import { formatMarketPrice, formatMarketSize } from 'js/util/formatting'

import { ProgressBar } from './ProgressBar'

export const OrderBookList: React.FC<{
  rows: OrderbookItem[]
  isAsk: boolean
  reverseCols?: boolean
  maxTotalSize: number
  isSizeInUsd: boolean
  isTotalSelected?: boolean
  className?: string
  dataTestId?: string
}> = React.memo(
  ({
    rows,
    isAsk,
    maxTotalSize,
    className,
    reverseCols,
    isSizeInUsd,
    isTotalSelected,
    dataTestId,
  }) => {
    const cumulativeSizes = useMemo(() => {
      const elems = isAsk && !reverseCols ? rows.toReversed() : rows
      const r = elems.reduce<number[]>((acc, row) => {
        acc.push((acc[acc.length - 1] ?? 0) + Number(row.size))

        return acc
      }, [])
      return isAsk && !reverseCols ? r.toReversed() : r
    }, [rows, reverseCols, isAsk])

    return (
      <div
        className={cn(
          'flex flex-col gap-0.5 max-mobile:flex-[1]',
          {
            'max-mobile:flex-col max-mobile:justify-end': reverseCols,
            'max-mobile:flex-col-reverse max-mobile:justify-start': !reverseCols,
          },
          className,
        )}
        data-testid={dataTestId}
      >
        {rows.map((row, i) => {
          const currentTotal = cumulativeSizes[i]!
          const total = (currentTotal / maxTotalSize) * 100
          const progress = (Number(row.size) / currentTotal) * 100

          return (
            <OrderRow
              key={i}
              isAsk={isAsk}
              order={row}
              total={total}
              currentTotal={currentTotal}
              progress={progress}
              reversed={reverseCols}
              isSizeInUsd={isSizeInUsd}
              isTotalSelected={isTotalSelected}
            />
          )
        })}
      </div>
    )
  },
)

OrderBookList.displayName = 'OrderBookList'

interface OrderRowProps {
  order: OrderbookItem
  isAsk: boolean
  showDate?: boolean
  reversed?: boolean
  total: number
  progress: number
  currentTotal: number
  isSizeInUsd: boolean
  isTotalSelected?: boolean
}

const OrderRow = ({
  order,
  isAsk,
  reversed,
  total,
  progress,
  currentTotal,
  isSizeInUsd,
  isTotalSelected,
}: OrderRowProps) => {
  const currentMarket = useCurrentMarket()
  const { isMobile } = useResponsiveness()
  const midPrice = useMidPrice()

  const getSize = () => {
    if (!isTotalSelected || !isMobile) {
      return isSizeInUsd
        ? formatMarketPrice(Number(order.size) * Number(order.price), currentMarket, false)
        : formatMarketSize(order.size, currentMarket)
    }

    return isSizeInUsd
      ? formatMarketPrice(currentTotal * midPrice, currentMarket, false)
      : formatMarketSize(currentTotal, currentMarket)
  }

  return (
    <div
      className={cn(
        'relative grid h-5 min-h-5 w-full cursor-pointer grid-cols-[25%_35%_40%] items-center px-2 text-right text-white-opaque hover:bg-grey-dark',
        'max-mobile:h-[25px] max-mobile:min-h-[25px] max-mobile:grid-cols-2',
      )}
    >
      <ProgressBar reversed={reversed} progress={progress} total={total} isGreen={!isAsk} />
      <p
        className={cn('typography-body-1 z-[1] text-white', {
          'order-1 text-end max-mobile:order-2': !reversed,
          'order-3 text-end max-mobile:order-1 max-mobile:text-start': reversed,
        })}
        onClick={(e) => {
          e.stopPropagation()
          useOrderInputStore.getState().update('limitPrice', order.price)
        }}
      >
        {formatMarketPrice(order.price, currentMarket, false)}
      </p>
      <p
        className={cn('typography-body-1 z-[1] text-white', {
          'order-2 text-end max-mobile:order-1 max-mobile:text-start': !reversed,
          'order-2 text-end max-mobile:order-2': reversed,
        })}
        onClick={(e) => {
          e.stopPropagation()
          useOrderInputStore.getState().update('size', order.size)
        }}
      >
        {getSize()}
      </p>
      {!isMobile && (
        <p
          className={cn('typography-body-1 z-[1] text-white', {
            'order-3': !reversed,
            'order-1': reversed,
          })}
        >
          {isSizeInUsd
            ? formatMarketPrice(Number(currentTotal) * Number(order.price), currentMarket, false)
            : formatMarketSize(currentTotal, currentMarket)}
        </p>
      )}
    </div>
  )
}
