import { useMutation } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { toast } from 'sonner'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useAccountIndex } from 'js/providers/user-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { formatLeverage } from 'js/util/formatting'
import {
  useGetInitialMarginFraction,
  marginFractionToLeverage,
  leverageToMarginFraction,
} from 'js/util/positions'
import { updateLeverage } from 'js/zklighter-js-sdk/sdk'

import { LeverageLimiter } from '../account/LeverageLimiter'

export const MaxLeverage = () => {
  const [inputMaxLeverage, setInputMaxLeverage] = useState(0)
  const [isMaxLeverageModalOpen, setIsMaxLeverageModalOpen] = useState(false)
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarket = useCurrentMarket()
  const accountIndex = useAccountIndex()
  const { isMobile } = useResponsiveness()
  const screenWidth = window.innerWidth
  const accountExistence = useAccountExistence()

  const isDisabled =
    accountExistence === 'NoWallet' ||
    accountExistence === 'KeysDontMatch' ||
    accountExistence === 'ShouldDeposit' ||
    accountExistence === 'GeoBlocked'

  const maxMarketLeverage = marginFractionToLeverage(currentMarket.min_initial_margin_fraction)

  const currentMarketLeverage = marginFractionToLeverage(
    getInitialMarginFraction(currentMarket.market_id),
  )

  useEffect(() => {
    setInputMaxLeverage(currentMarketLeverage)
  }, [currentMarketLeverage, isMaxLeverageModalOpen])

  const getTooltipCopy = () => {
    switch (accountExistence) {
      case 'NoWallet':
        return 'Connect your wallet to set the maximum leverage.'
      case 'KeysDontMatch':
        return 'Authenticate your account to set the maximum leverage.'
      case 'ShouldDeposit':
        return 'Create account to set the maximum leverage.'
      case 'GeoBlocked':
        return 'You are accessing Lighter from a restricted jurisdiction.'
      default:
        return ''
    }
  }

  const changeLeverageMutation = useMutation({
    mutationFn: updateLeverage,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully updated max leverage"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setIsMaxLeverageModalOpen(false),
  })

  const onConfirmMaxLeverage = async () => {
    if (!accountIndex) return
    changeLeverageMutation.mutate({
      accountIndex,
      marketIndex: currentMarket.market_id,
      initialMarginFraction: leverageToMarginFraction(inputMaxLeverage),
    })
  }

  return (
    <>
      <Modal
        title="Adjust Max Leverage"
        open={isMaxLeverageModalOpen}
        onOpenChange={setIsMaxLeverageModalOpen}
      >
        <div className="flex flex-col items-center gap-6">
          <p className="typography-label-1 text-white max-mobile:hidden">
            Set the maximum leverage you are willing to use. Higher leverage increases the risk of
            liquidation.
          </p>
          <LeverageLimiter
            leverage={Number(inputMaxLeverage)}
            onChange={setInputMaxLeverage}
            limit={maxMarketLeverage}
          />
          {!isDisabled ? (
            <Button
              disabled={!Number(inputMaxLeverage)}
              className="mt-auto w-full max-mobile:mb-6"
              onClick={onConfirmMaxLeverage}
              isLoading={changeLeverageMutation.isPending}
            >
              Confirm
            </Button>
          ) : (
            <Tooltip>
              <TooltipTrigger className="mt-auto w-full max-mobile:mb-6">
                <Button
                  disabled
                  className="w-full"
                  onClick={onConfirmMaxLeverage}
                  isLoading={changeLeverageMutation.isPending}
                >
                  Confirm
                </Button>
                <TooltipContent>
                  <p className="typography-label-1 text-white">{getTooltipCopy()} </p>
                </TooltipContent>
              </TooltipTrigger>
            </Tooltip>
          )}
        </div>
      </Modal>
      {!isMobile ? (
        <div className="flex items-center gap-2 rounded-xl border border-semi-transparent bg-white-transparent p-1 pl-3">
          <p className="typography-body-1 whitespace-nowrap text-white-opaque">Max. Leverage</p>
          <Button
            onClick={() => setIsMaxLeverageModalOpen(true)}
            className="group relative flex w-[120px] cursor-pointer items-center justify-center rounded-lg border border-semi-transparent bg-white-transparent px-3 py-2 hover:bg-[#343F52] hover:shadow-button"
          >
            <p className="typography-label-1 text-white">{formatLeverage(currentMarketLeverage)}</p>
            <Icon
              icon="edit"
              className="absolute right-3 size-4 text-white opacity-0 transition-opacity group-hover:opacity-100"
            />
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => setIsMaxLeverageModalOpen(true)}
          className="relative flex h-full min-h-full cursor-pointer items-center justify-center gap-1 rounded-lg border border-semi-transparent bg-white-transparent px-1 max-mobile:py-3"
        >
          <p className="typography-label-1 text-white">{formatLeverage(currentMarketLeverage)}</p>
          {screenWidth > 375 && <Icon icon="edit" className="size-4 text-white" />}
        </Button>
      )}
    </>
  )
}
