import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { toast } from 'sonner'
import { OrderTypeEnum } from 'zklighter-perps'

import { DepositBtnStatusStyle, useDepositStore } from 'js/pages/deposit/form'
import { useLatestDepositQuery } from 'js/pages/deposit/hooks'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import {
  useAccountsQuery,
  useIsWhitelistedQuery,
  useUserAccount,
} from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex, useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { useCreateAccountMutation } from 'js/shared-components/ConnectWalletModal'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import type { TxEventInfo } from 'js/types/api-types'
import { constants } from 'js/util/api/constants'
import cn from 'js/util/cn'
import {
  marginFractionToLeverage,
  useGetInitialMarginFraction,
  useUserAccountPortfolioStats,
} from 'js/util/positions'
import { isMainnet, isZero } from 'js/util/util'
import { createOrderBatch, createOrder } from 'js/zklighter-js-sdk/sdk'

import { OrderNotification } from './components/OrderNotification'
import { useOrderInputStore, useOrderToPlaceUpdater } from './PlaceOrder'
import {
  getOrderExpiry,
  getOrderPrice,
  getOrderTimeInForce,
  getOrderType,
  getPlaceOrderButtonLabel,
  getTimestampDiff,
} from './utils'

interface PlaceOrderMutationParams {
  accountIndex: number
  marketId: number
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const usePlaceOrderMutation = () => {
  const currentMarketId = useCurrentMarketId()
  const toastIdRef = useRef<number | string | null>(null)
  const {
    isShort,
    timeInForce,
    timeInForceUnit,
    timeInForceValue,
    limitPrice,
    runtimeMinutes,
    runtimeHours,
    postOnly,
    triggerPrice,
    kind,
    isStop,
  } = useOrderInputStore()
  const orderInputs = useOrderInputStore()

  const orderData = useOrderToPlaceUpdater()
  const { stopLossInfo, takeProfitInfo } = orderData
  const currentMarket = useCurrentMarket()
  const tpKind = OrderTypeEnum.TakeProfit
  const slKind = OrderTypeEnum.StopLoss
  const reduceOnly = isStop() ? 1 : orderInputs.reduceOnly

  return useMutation({
    mutationFn: ({
      accountIndex,
      amount0,
      worstExecutionPrice,
      marketId,
    }: PlaceOrderMutationParams) => {
      const userOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount: Math.floor(Number(amount0) * 10 ** currentMarket.size_decimals),
        price: getOrderPrice(
          kind,
          isShort(),
          Number(limitPrice) * 10 ** currentMarket.price_decimals,
          worstExecutionPrice * 10 ** currentMarket.price_decimals,
        ),
        isAsk: Number(isShort()),
        reduceOnly: Number(reduceOnly),
        orderType: getOrderType(kind),
        timeInForce: getOrderTimeInForce(kind, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          kind,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Number(triggerPrice) * 10 ** currentMarket.price_decimals,
      }
      const tpOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount: Math.floor(Number(amount0) * 10 ** currentMarket.size_decimals),
        price: getOrderPrice(
          tpKind,
          isShort(),
          Number(limitPrice) * 10 ** currentMarket.price_decimals,
          worstExecutionPrice * 10 ** currentMarket.price_decimals,
        ),
        isAsk: Number(!isShort()),
        reduceOnly: 1,
        orderType: getOrderType(tpKind),
        timeInForce: getOrderTimeInForce(tpKind, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          tpKind,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Math.floor(
          Number(takeProfitInfo.takeProfitPrice) * 10 ** currentMarket.price_decimals,
        ),
      }
      const slOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount: Math.floor(Number(amount0) * 10 ** currentMarket.size_decimals),
        price: getOrderPrice(
          slKind,
          isShort(),
          Number(limitPrice) * 10 ** currentMarket.price_decimals,
          worstExecutionPrice * 10 ** currentMarket.price_decimals,
        ),
        isAsk: Number(!isShort()),
        reduceOnly: 1,
        orderType: getOrderType(slKind),
        timeInForce: getOrderTimeInForce(slKind, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          slKind,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Math.floor(
          Number(stopLossInfo.stopLossPrice) * 10 ** currentMarket.price_decimals,
        ),
      }
      const orders = [
        takeProfitInfo.takeProfitPrice ? tpOrder : [],
        stopLossInfo.stopLossPrice ? slOrder : [],
        userOrder,
      ].flat()
      return orders.length > 1 ? createOrderBatch(orders) : createOrder(orders[0]!)
    },
    onMutate: ({ amount0, estimatedPrice, marketId }) => {
      toastIdRef.current = toast.custom(
        (toastId) => (
          <OrderNotification
            orderType={kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(kind)
                ? Number(limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={isShort()}
            marketId={marketId}
            onClose={() => toast.dismiss(toastId)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )
    },
    onSuccess: (txHashes, { amount0, estimatedPrice }) => {
      if (!txHashes || !txHashes.hash) return
      const eventInfo = JSON.parse(txHashes.event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i

      toast.custom(
        (toastId) => (
          <OrderNotification
            orderType={kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(kind)
                ? Number(limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={isShort()}
            marketId={currentMarketId}
            orderIndex={orderIndex}
            onClose={() => toast.dismiss(toastId)}
          />
        ),
        { id: toastIdRef.current! },
      )
    },
    onError: (err) => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={
            err.message === constants.FAT_FINGER_ERROR
              ? 'The order price flagged as an accidental price. Please verify and try again.'
              : err.message === constants.LIMIT_PRICE_FAR_ERROR
                ? 'Your limit order price is too far from the current market price. Please adjust it closer to the market price to proceed.'
                : err.message === constants.SLTP_PRICE_FAR_ERROR
                  ? 'Your limit order price is too far from the trigger price. Please adjust it closer to the trigger price to proceed.'
                  : 'Something went wrong, please try again later'
          }
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      toast.dismiss(toastIdRef.current!)
    },
  })
}

interface PlaceOrderButtonProps {
  notEnoughLiquidity: boolean
  liquidationWarning: boolean
  triggerPriceInvalid: boolean
  passesLimitOrderChecks: boolean
  passesSLTPOrderChecks: boolean
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const PlaceOrderButton = ({
  notEnoughLiquidity,
  liquidationWarning,
  triggerPriceInvalid,
  passesLimitOrderChecks,
  passesSLTPOrderChecks,
  amount0,
  worstExecutionPrice,
  estimatedPrice,
}: PlaceOrderButtonProps) => {
  const userAccount = useUserAccount()
  const accountsQuery = useAccountsQuery()
  const currentMarket = useCurrentMarket()
  const portfolioStats = useUserAccountPortfolioStats()
  const { setShowAuthFlow } = useDynamicContext()
  const orderInputs = useOrderInputStore()
  const orderData = useOrderToPlaceUpdater()
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const leverage = marginFractionToLeverage(getInitialMarginFraction(currentMarket.market_id))

  const placeOrderMutation = usePlaceOrderMutation()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const latestDepositQuery = useLatestDepositQuery()

  const depositModal = useDepositStore()
  const queryClient = useQueryClient()
  const accountIndex = useAccountIndex()
  const didTriggerRef = useRef(false)

  const accountExistence = useAccountExistence()

  const depositStore = useDepositStore()
  const createAccountMutation = useCreateAccountMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })

  useEffect(() => {
    if (
      isMainnet() &&
      !didTriggerRef.current &&
      latestDepositQuery.data?.status === 'bridging' &&
      !accountIndex
    ) {
      createAccountMutation.mutate()
      didTriggerRef.current = true
    }
  }, [accountIndex, latestDepositQuery.data, createAccountMutation])

  switch (accountExistence) {
    case 'GeoBlocked':
      return null
    case 'NoWallet':
      return (
        <Button onClick={() => setShowAuthFlow(true)} className="flex w-full gap-2">
          <Icon className="size-5" icon="wallet" />
          Connect Wallet to Trade
        </Button>
      )
    case 'NotWhitelisted':
      return (
        <Button
          className="w-full"
          isLoading={isWhitelistedQuery.isLoading}
          onClick={() => useUserStore.setState({ showWhitelist: true })}
        >
          Request Access
        </Button>
      )
    case 'KeysDontMatch':
      return (
        <Button className="w-full" onClick={() => useUserStore.setState({ showOnboarding: true })}>
          Authenticate
        </Button>
      )
    case 'ShouldDeposit':
      if (!isMainnet()) {
        return (
          <Button
            className="w-full"
            isLoading={accountsQuery.isLoading}
            onClick={() => useUserStore.setState({ showOnboarding: true })}
          >
            Create Account
          </Button>
        )
      }
      return (
        <Button className="w-full" onClick={() => depositModal.openModal()}>
          Create Account
        </Button>
      )
    case 'DepositInProgress':
      return (
        <Button
          className={cn('animate-pulse gap-2', DepositBtnStatusStyle('pending'))}
          onClick={depositStore.openModal}
        >
          {depositStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
          Deposit in progress
        </Button>
      )
    case 'Creating':
      return (
        <Button className="animate-pulse cursor-default gap-2 hover:bg-primary-blue-main">
          <Icon icon="spinner" className="size-5" />
          Creating Account
        </Button>
      )
    case 'Deciding':
      return <Button className="w-full" isLoading />
  }

  if (!userAccount || portfolioStats === null) {
    return <Button className="w-full" isLoading />
  }

  if (isZero(orderInputs.value)) {
    return (
      <Button className="w-full" disabled>
        Enter Amount
      </Button>
    )
  }

  if (!orderInputs.isLimit() && notEnoughLiquidity) {
    return (
      <Button className="w-full" disabled>
        Not Enough Liquidity
      </Button>
    )
  }

  if (orderInputs.isLimit() && isZero(orderInputs.limitPrice)) {
    return (
      <Button className="w-full" disabled>
        Enter Limit Price
      </Button>
    )
  }

  if (orderInputs.isStop() && isZero(orderInputs.triggerPrice)) {
    return (
      <Button className="w-full" disabled>
        Enter Stop Price
      </Button>
    )
  }

  if (
    orderInputs.isTwap() &&
    isZero(orderInputs.runtimeHours) &&
    isZero(orderInputs.runtimeMinutes)
  ) {
    return (
      <Button className="w-full" disabled>
        Enter Running Time
      </Button>
    )
  }

  const runningTime =
    getTimestampDiff(Number(orderInputs.runtimeMinutes), 'm') +
    getTimestampDiff(Number(orderInputs.runtimeHours), 'h')

  if (
    orderInputs.isTwap() &&
    (runningTime < getTimestampDiff(5, 'm') || runningTime > getTimestampDiff(24, 'h'))
  ) {
    return (
      <Button className="w-full" disabled>
        Invalid Running Time
      </Button>
    )
  }

  // TODO: take a deeper look into this case
  if (portfolioStats.availableBalance * leverage < 1) {
    return (
      <Button className="w-full" disabled>
        Insufficient Balance
      </Button>
    )
  }

  if (liquidationWarning) {
    return (
      <Button className="w-full" disabled>
        Not Enough Margin
      </Button>
    )
  }

  if (!orderInputs.isLimit() && orderData.slippage > orderInputs.maxSlippage)
    return (
      <Button className="w-full" disabled>
        Too Much Slippage
      </Button>
    )

  if (
    orderInputs.isLimit() &&
    orderInputs.timeInForce === 'gtd' &&
    (orderInputs.timeInForceValue === '' ||
      (orderInputs.timeInForceUnit === 'm' && Number(orderInputs.timeInForceValue) < 10))
  ) {
    return (
      <Button className="w-full" disabled>
        Minimum Duration is 10 minutes
      </Button>
    )
  }

  if (triggerPriceInvalid) {
    return (
      <Button className="w-full" disabled>
        Trigger Price Invalid
      </Button>
    )
  }

  return (
    <Button
      className="w-full"
      isLoading={placeOrderMutation.isPending && !placeOrderMutation.isError}
      disabled={!passesLimitOrderChecks || !passesSLTPOrderChecks}
      onClick={() =>
        placeOrderMutation.mutate({
          accountIndex: userAccount.index,
          marketId: currentMarket.market_id,
          amount0,
          worstExecutionPrice,
          estimatedPrice,
        })
      }
    >
      {getPlaceOrderButtonLabel(orderInputs.kind)}
    </Button>
  )
}

export default PlaceOrderButton
