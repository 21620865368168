import { isBefore, differenceInMilliseconds, add as addDuration, formatDate } from 'date-fns'
import { useRef, useState, useEffect, useMemo } from 'react'

import { USDC_SYMBOL } from 'js/constants/shared'
import { MarketSelector } from 'js/pages/trade/components/info-box/market-selector/MarketSelector'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats } from 'js/providers/order-book-store'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import Icon from 'js/shared-components/uikit/Icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import cn from 'js/util/cn'
import { formatMarketPrice, formatMarketSize, formatOpenInterest } from 'js/util/formatting'

import { MaxLeverage } from './MaxLeverage'

type InfoItem = {
  key: string
  title: string
  value: string
  explanation?: string
}

export const InfoBox = () => {
  const currentMarket = useCurrentMarket()
  const infoBoxRef = useRef<HTMLDivElement>(null)
  const currentMarketStats = useCurrentMarketStats()

  const indexPrice = currentMarketStats
    ? formatMarketPrice(currentMarketStats.index_price, currentMarket)
    : '-'
  const markPrice = currentMarketStats
    ? formatMarketPrice(currentMarketStats.mark_price, currentMarket)
    : '-'
  const fundingRate = currentMarketStats ? `${currentMarketStats.funding_rate}%` : '-'
  const dayLow = currentMarketStats
    ? formatMarketPrice(currentMarketStats.daily_price_low, currentMarket)
    : '-'
  const dayHigh = currentMarketStats
    ? formatMarketPrice(currentMarketStats.daily_price_high, currentMarket)
    : '-'
  const dayVolume0 = currentMarketStats
    ? formatMarketSize(currentMarketStats.daily_base_token_volume, currentMarket)
    : '-'
  const dayVolume1 = currentMarketStats
    ? formatMarketPrice(currentMarketStats.daily_quote_token_volume, currentMarket)
    : '-'

  const openInterest = currentMarketStats
    ? formatOpenInterest(currentMarketStats.open_interest)
    : '-'
  const roundedDiffPct = parseFloat((currentMarketStats?.daily_price_change ?? 0).toFixed(2))
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date())
  const nextFunding = useMemo(() => {
    const fundingTime = addDuration(
      currentMarketStats ? new Date(currentMarketStats.funding_timestamp) : new Date(),
      { hours: 1 },
    )

    if (isBefore(currentTimestamp, fundingTime)) {
      return formatDate(differenceInMilliseconds(fundingTime, currentTimestamp), 'mm:ss')
    }

    return '00:00'
  }, [currentMarketStats, currentTimestamp])

  useEffect(() => {
    const t = setInterval(() => setCurrentTimestamp(new Date()), 1000)

    return () => clearInterval(t)
  }, [])

  const infoItems: InfoItem[] = [
    {
      key: 'markPrice',
      title: 'Mark Price',
      value: markPrice,
      explanation: `Mark price is the fair price of a perpetual contract, based on the order book state and index price. It is used for calculating margin requirements, unrealized PnL, and liquidations.`,
    },
    {
      key: 'indexPrice',
      title: 'Index Price',
      value: indexPrice,
      explanation: `Index price represents the real-time market price of the underlying asset in spot markets.`,
    },
    {
      key: 'openInterest',
      title: 'Open Interest',
      value: openInterest,
      explanation: `Open interest is the total value of contracts held by traders on this contract.`,
    },
    { key: 'high', title: '24h High', value: dayHigh },
    { key: 'low', title: '24h Low', value: dayLow },
    { key: 'change', title: '24h Change', value: `${roundedDiffPct}%` },
    { key: 'volumeToken0', title: `24h Volume (${currentMarket.symbol})`, value: dayVolume0 },
    { key: 'volumeToken1', title: `24h Volume (${USDC_SYMBOL})`, value: dayVolume1 },
    {
      key: 'fundingRate',
      title: '1hr Funding',
      value: fundingRate,
      explanation: `Funding payments occur hourly based on the contract and index price difference. Longs pay shorts if the rate is positive, and shorts pay longs if negative. Funding is peer-to-peer, with no fees taken by the exchange.`,
    },
    {
      key: 'nextFunding',
      title: 'Next Funding',
      value: nextFunding,
      explanation: `Remaining time until the next funding payment, which occurs hourly.`,
    },
  ]

  return (
    <div className="flex w-full items-center gap-6 py-2" ref={infoBoxRef}>
      <MarketSelector />
      <div className="w-full max-w-full overflow-x-hidden">
        <div
          className="flex h-10 w-full flex-[3] justify-start gap-6 overflow-x-scroll"
          data-testid="stats"
        >
          {infoItems.map((item) => (
            <InfoItem
              item={item}
              key={item.title}
              isLoading={!currentMarketStats}
              roundedDiffPct={roundedDiffPct}
            />
          ))}
        </div>
      </div>
      <MaxLeverage />
    </div>
  )
}

interface InfoItemProps {
  item: InfoItem
  isLoading: boolean
  roundedDiffPct: number
}

const InfoItem = ({ item, isLoading, roundedDiffPct }: InfoItemProps) => (
  <div className="flex max-h-full items-center gap-6 py-1 ">
    <div className="flex max-h-full min-w-14 flex-col whitespace-nowrap">
      <Tooltip open={!item.explanation ? false : undefined}>
        <TooltipTrigger asChild>
          <p
            className={cn('typography-body-1 text-white-opaque', {
              'underline decoration-dotted': !!item.explanation,
            })}
          >
            {item.title}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">{item.explanation}</p>
        </TooltipContent>
      </Tooltip>
      <div className="flex items-center gap-1">
        {!isLoading ? (
          <p className="typography-body-1 text-white">{item.value}</p>
        ) : (
          <SkeletonRectangle className="h-3.5 w-16 min-w-16 rounded-sm" />
        )}
        {!isLoading && item.key === 'change' && roundedDiffPct !== 0 && (
          <Icon
            icon="triangle"
            className={cn('size-2', {
              'text-green-main rotate-180': roundedDiffPct > 0,
              'text-red-main rotate-0': roundedDiffPct < 0,
            })}
          />
        )}
      </div>
    </div>
  </div>
)
