import { useMemo, useState } from 'react'

import { USDC_SYMBOL } from 'js/constants/shared'
import { useChangeMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useTradePageParams } from 'js/providers/hooks/useTradePageParams'
import { useCurrentMarketStats } from 'js/providers/order-book-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'

import { Markets } from '../info-box/market-selector/Markets'
import { MarketSelectorTrigger } from '../info-box/market-selector/MarketSelector'
import { MaxLeverage } from '../info-box/MaxLeverage'

export const MobileMarketSelector = () => {
  const { symbol } = useTradePageParams()

  const currentMarketStats = useCurrentMarketStats()
  const markPrice = currentMarketStats?.mark_price ?? '-'
  const dayChange = currentMarketStats?.daily_price_change

  const dayVolume0 = useMemo(
    () =>
      currentMarketStats?.daily_base_token_volume !== undefined
        ? currentMarketStats.daily_base_token_volume > 1000
          ? Intl.NumberFormat('en', { notation: 'compact' }).format(
              currentMarketStats.daily_base_token_volume,
            )
          : currentMarketStats.daily_base_token_volume.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 1,
            })
        : 'N/A',
    [currentMarketStats],
  )
  const dayVolume1 = useMemo(
    () =>
      currentMarketStats?.daily_quote_token_volume !== undefined
        ? currentMarketStats.daily_quote_token_volume > 1000
          ? Intl.NumberFormat('en', { notation: 'compact' }).format(
              currentMarketStats.daily_quote_token_volume,
            )
          : currentMarketStats.daily_quote_token_volume.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 1,
            })
        : 'N/A',
    [currentMarketStats],
  )

  const roundedDiffPct = parseFloat((dayChange ?? 0).toFixed(2))

  return (
    <div className="flex items-center justify-between gap-2 p-1">
      <MarketSelector />
      <MaxLeverage />
      <div className="flex gap-2">
        <div className="flex h-fit flex-col border-r pr-2">
          <div className="flex items-center justify-end gap-1">
            <p className="typography-body-2 text-white">{dayVolume0}</p>
            {symbol}
          </div>
          <div className="flex items-center justify-end gap-1">
            <p className="typography-body-2 text-white">${dayVolume1}</p>
            {USDC_SYMBOL}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="typography-body-2 text-white"> ${markPrice}</p>
          <div className="flex items-center gap-1">
            <p
              className={cn('typography-body-2', {
                'text-white': roundedDiffPct === 0,
                'text-green-main': roundedDiffPct > 0,
                'text-red-main': roundedDiffPct < 0,
              })}
            >
              {roundedDiffPct}%
            </p>
            {roundedDiffPct !== 0 && (
              <Icon
                icon="triangle"
                className={cn('size-2', {
                  'text-green-main rotate-180': roundedDiffPct > 0,
                  'text-red-main rotate-0': roundedDiffPct < 0,
                })}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const MarketSelector = () => {
  const { isMobile } = useResponsiveness()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const changeMarket = useChangeMarket()
  return (
    <>
      <MarketSelectorTrigger onClick={() => setPopoverOpen(true)} />
      <Backdrop isVisible={isMobile && popoverOpen} onClick={() => setPopoverOpen(false)} />
      <BottomAnimatedDropdown
        isOpen={popoverOpen}
        className="top-0 max-h-[80vh] w-screen overflow-y-scroll rounded-none border-0 bg-black/30 backdrop-blur-xl max-mobile:absolute"
      >
        <Markets
          closeSelector={() => setPopoverOpen(false)}
          onClick={(marketId) => {
            setPopoverOpen(false)
            changeMarket(marketId)
          }}
        />
      </BottomAnimatedDropdown>
    </>
  )
}
