import { useState } from 'react'
import { toast } from 'sonner'

import { useIsWhitelistedQuery, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useShowWhitelist, useUserStore } from 'js/providers/user-store'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postWaitlist } from 'js/util/api/api'

import { Button } from './Button'
import { Modal } from './Modal'
import Input from './uikit/Input'
import Toast from './uikit/Toast'

export const WhitelistModal = () => {
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const showWhitelist = useShowWhitelist()
  const userAddress = useUserAddress()
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [l1address, setL1Address] = useState(userAddress)

  const onSubmit = async () => {
    const res = await postWaitlist({ telegram: telegram, l1address, discord, subject: 'whitelist' })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Request submitted successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      useUserStore.setState({ showWhitelist: false })
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
  }

  return (
    <Modal
      title="Request Access"
      open={isWhitelistedQuery.data === false && showWhitelist}
      onOpenChange={(newOpen) => {
        useUserStore.setState({ showWhitelist: newOpen })

        if (!newOpen) {
          setTelegram('')
          setDiscord('')
          setL1Address(userAddress)
        }
      }}
    >
      <div className="flex flex-col gap-6">
        <p className="typography-label-1 text-white">
          We are currently in Private Beta! To request access, please submit your Telegram or
          Discord and we will get back to you.
        </p>
        <Input
          value={telegram}
          placeholder="Telegram"
          onChange={(e) => setTelegram(e.target.value)}
          required
          className="bg-white-transparent"
        />
        <Input
          value={discord}
          placeholder="Discord"
          onChange={(e) => setDiscord(e.target.value)}
          required
          className="bg-white-transparent"
        />
        <Input
          value={l1address}
          placeholder="L1 Address"
          onChange={(e) => setL1Address(e.target.value)}
          className="bg-white-transparent"
        />
        <p className="typography-label-1 text-white">
          Join our{' '}
          <a
            className="border-b border-b-primary-blue-main text-primary-blue-main"
            href="https://discord.gg/sUzWrdWxy3"
          >
            Discord
          </a>
          .
        </p>
        <div className="flex justify-end gap-4">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                disabled={!l1address || (!telegram && !discord)}
                onClick={onSubmit}
                className="w-fit"
              >
                Submit
              </Button>
            </TooltipTrigger>
            {(!l1address || (!telegram && !discord)) && (
              <TooltipContent>
                <p className="typography-body-2 text-white">Please fill out all fields</p>
              </TooltipContent>
            )}
          </Tooltip>
          <Button
            color="grey"
            className="w-fit"
            onClick={() => useUserStore.setState({ showWhitelist: false })}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
