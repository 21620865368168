import { OrderStatusEnum } from 'zklighter-perps'

import type { Order } from 'js/providers/types'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface OrderStatusCellProps {
  order: Order
}
const ORDER_STATUS_COPY = {
  [OrderStatusEnum.Open]: {
    title: 'Open',
    explanation: '',
  },
  [OrderStatusEnum.Pending]: {
    title: 'Pending',
    explanation: '',
  },
  [OrderStatusEnum.Filled]: {
    title: 'Filled',
    explanation: '',
  },
  [OrderStatusEnum.Canceled]: {
    title: 'Canceled',
    explanation: 'Order canceled by user.',
  },
  [OrderStatusEnum.InProgress]: {
    title: 'In Progress',
    explanation: '',
  },
  [OrderStatusEnum.CanceledPostOnly]: {
    title: 'Canceled',
    explanation:
      'Order canceled due to crossing with the top order in the book, violating the post-only rule.',
  },
  [OrderStatusEnum.CanceledReduceOnly]: {
    title: 'Canceled',
    explanation: 'Order canceled to prevent increasing position.',
  },
  [OrderStatusEnum.CanceledPositionNotAllowed]: {
    title: 'Canceled',
    explanation: 'Order canceled to prevent a not allowed position.',
  },
  [OrderStatusEnum.CanceledMarginNotAllowed]: {
    title: 'Canceled',
    explanation: 'Order canceled due to insufficient available margin.',
  },
  [OrderStatusEnum.CanceledTooMuchSlippage]: {
    title: 'Canceled',
    explanation: 'Order canceled due to excessive slippage beyond the allowed limit.',
  },
  [OrderStatusEnum.CanceledNotEnoughLiquidity]: {
    title: 'Canceled',
    explanation: 'Order canceled due to insufficient liquidity.',
  },
  [OrderStatusEnum.CanceledSelfTrade]: {
    title: 'Canceled',
    explanation: 'Order canceled to prevent self-trade.',
  },
  [OrderStatusEnum.CanceledExpired]: {
    title: 'Canceled',
    explanation: 'Order expired.',
  },
} as const

const OrderStatusCell = ({ order }: OrderStatusCellProps) => {
  const { title, explanation } = ORDER_STATUS_COPY[order.status]

  return (
    <TableCell>
      <div className="flex justify-end gap-1">
        <p className="typography-body-1 text-white">{title}</p>
        {!!explanation && <Explanation explanation={explanation} side="left" />}
      </div>
    </TableCell>
  )
}
export default OrderStatusCell
