import { useEffect } from 'react'

import { useAccountIndex } from '../user-store'
import { useWsSubStore } from '../wsStore'

import { useMaybeCurrentMarketId } from './order-book-metas-hooks'

export const useInitWs = () => {
  const ws = useWsSubStore((state) => state.ws)

  const accountIndex = useAccountIndex()
  const currentMarketId = useMaybeCurrentMarketId()

  useEffect(() => {
    const interval = setInterval(useWsSubStore.getState().actions.init, 500)

    return () => clearInterval(interval)
  }, [ws])

  useEffect(() => {
    if (!ws || currentMarketId === undefined) {
      return
    }

    useWsSubStore.getState().actions.switchMarket(currentMarketId)
  }, [currentMarketId, ws])

  useEffect(() => {
    if (!ws) {
      return
    }

    useWsSubStore.getState().actions.switchAccount(accountIndex)
  }, [ws, accountIndex])
}
