import './js/sentry'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import App from 'js/App'
import 'css/index.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
