import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { useMainAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import Toast from 'js/shared-components/uikit/Toast'
import WarningContainer from 'js/shared-components/WarningContainer'
import { updatePublicPool } from 'js/zklighter-js-sdk/sdk'

import { usePublicPoolQuery } from '../utils'

interface FreezePublicPoolModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const FreezePublicPoolModal = ({ open, onOpenChange }: FreezePublicPoolModalProps) => {
  const mainAccount = useMainAccount()!
  const publicPoolQuery = usePublicPoolQuery()
  const freezePublicPoolMutation = useMutation({
    mutationFn: () =>
      updatePublicPool({
        accountIndex: mainAccount.index,
        publicPoolIndex: publicPoolQuery.data.index,
        status: 1,
        operatorFee: Number(publicPoolQuery.data.pool_info.operator_fee) * 10000,
        minOperatorShareRate: Number(publicPoolQuery.data.pool_info.min_operator_share_rate) * 100,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully frozen pool"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => onOpenChange(false),
  })

  return (
    <Modal open={open} title="Freeze Public Pool" onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-4">
        <WarningContainer>You are about to freeze this public pool</WarningContainer>
        <div className="flex justify-end gap-2">
          <Button className="w-fit" color="grey" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            className="w-fit"
            color="red"
            onClick={() => freezePublicPoolMutation.mutate()}
            isLoading={freezePublicPoolMutation.isPending}
          >
            Freeze Public Pool
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default FreezePublicPoolModal
