import { PageContainer } from 'js/pages/page-container/PageContainer'
import { useAccountIndex } from 'js/providers/user-store'
import { BackgroundLights } from 'js/shared-components/BackgroundLights'
import { PnlBalanceCharts } from 'js/shared-components/PnlBalanceCharts'

import { AccountDetails } from './AccountDetails'
import { UserTransactions } from './UserTransactions'

const Portfolio = () => {
  const accountIndex = useAccountIndex()

  return (
    <PageContainer className="bg-gradient-to-b">
      <BackgroundLights />
      <div className="flex flex-col gap-5 p-2 px-6 max-mobile:p-1 mobile:min-h-full">
        <div className="flex flex-1 flex-col gap-6 mobile:flex-row">
          <AccountDetails />
          <div className="flex flex-[2] flex-col overflow-hidden rounded-lg max-mobile:flex-none mobile:min-h-[300px]">
            <PnlBalanceCharts accountIndex={accountIndex} isPublicPool={false} />
          </div>
        </div>
        <UserTransactions />
      </div>
    </PageContainer>
  )
}

export default Portfolio
