import { useEffect, useMemo, useState } from 'react'
import { OrderTypeEnum } from 'zklighter-perps'
import { create } from 'zustand'

import { OrderDirections } from 'js/constants/shared'
import {
  getMarketOrderFromAmount0,
  getMarketOrderFromAmount1,
  useCurrentMarket,
} from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats, useMidPrice, useOrderBook } from 'js/providers/order-book-store'
import WarningContainer from 'js/shared-components/WarningContainer'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import {
  marginFractionToLeverage,
  useGetInitialMarginFraction,
  useRealBuyingPower,
} from 'js/util/positions'
import { isZero } from 'js/util/util'

import { AccountOverview } from './components/AccountOverview'
import { AdvancedLimitDropdown } from './components/AdvancedLimitDropdown'
import { BuyingStats } from './components/BuyingStats'
import { ClosePosition } from './components/ClosePosition'
import { LeverageSlider } from './components/LeverageSlider'
import { OrderDirectionSelector } from './components/OrderDirectionSelector'
import { OrderSizeInput } from './components/OrderSizeInput'
import { OrderTypeSelector } from './components/OrderTypeSelector'
import { PriceImpact } from './components/PriceImpact'
import { PriceInput } from './components/PriceInput'
import { ReduceOnly } from './components/ReduceOnly'
import { RunningTime } from './components/RunningTime'
import { StopLossTakeProfit } from './components/Tp-sl/StopLossTakeProfit'
import { TriggerPriceInput } from './components/TriggerPriceInput'
import { TwapStats } from './components/TwapStats'
import { Warning } from './components/Warning'
import PlaceOrderButton from './PlaceOrderButton'
import { validateAndParseTIFValue, type TimeInForce, type TimeInForceUnit } from './utils'

type OrderPlacementInput = 'size' | 'usd' | 'leverage'
type StopLossInput = 'limitPrice' | 'stopLossPrice' | 'lossUsd' | 'lossPercent'
type TakeProfitInput = 'limitPrice' | 'takeProfitPrice' | 'gainUsd' | 'gainPercent'

interface OrderInputStore {
  kind: OrderTypeEnum
  pinnedInput: OrderPlacementInput
  stopLossInput: StopLossInput | null
  takeProfitInput: TakeProfitInput | null
  value: string
  limitPrice: string
  triggerPrice: string
  orderDirection: OrderDirections
  timeInForce: TimeInForce
  timeInForceValue: string
  timeInForceUnit: TimeInForceUnit
  postOnly: boolean
  isAmountInUSD: boolean
  reduceOnly: boolean
  runtimeHours: string
  runtimeMinutes: string
  maxSlippage: number
  stopLossTakeProfit: {
    stopLossValue: string
    stopLossInput: string
    takeProfitValue: string
    takeProfitInput: string
  }
  isClosingPosition: boolean
  isShort: () => boolean
  isLimit: () => boolean
  isStop: () => boolean
  isTwap: () => boolean
  update: (type: OrderPlacementInput | 'limitPrice', value: string | undefined) => void
  setTriggerPrice: (value: string) => void
  changeOrderKind: (newKind: OrderTypeEnum) => void
  changeDirection: (newDirection: OrderDirections) => void
  changeTimeInForce: (newTimeInForce: TimeInForce) => void
  changeTimeInForceValue: (newTimeInForceValue: string) => void
  changeTimeInForceUnit: (newTimeInForceUnit: TimeInForceUnit) => void
  changePostOnly: (newPostOnly: boolean) => void
  changeIsAmountInUSD: (newAmountInUSD: boolean) => void
  changeRuntimeMinutes: (newRuntimeMinutes: string) => void
  changeRuntimeHours: (newRuntimeHours: string) => void
  changeReduceOnly: (newReduceOnly: boolean) => void
  changeMaxSlippage: (newMaxSlippage: number) => void
  changeStopLossInputs: (input: StopLossInput, value: string) => void
  changeTakeProfitInputs: (input: TakeProfitInput, value: string) => void
  changeIsClosingPosition: (value: boolean) => void
}

export const useOrderInputStore = create<OrderInputStore>((set, get) => ({
  kind: OrderTypeEnum.Market,
  pinnedInput: (() => (readSSUserSelections().data?.isAmountInUSD ? 'usd' : 'size'))(),
  stopLossInput: null,
  takeProfitInput: null,
  value: '',
  limitPrice: '',
  triggerPrice: '',
  orderDirection: OrderDirections.Long,
  timeInForce: 'gtd',
  timeInForceValue: '28',
  timeInForceUnit: 'd',
  postOnly: false,
  isAmountInUSD: (() => readSSUserSelections().data?.isAmountInUSD ?? false)(),
  reduceOnly: false,
  runtimeHours: '',
  runtimeMinutes: '30',
  maxSlippage: (() => {
    const storageMaxSlippage = readSSUserSelections().data?.maxSlippage

    return typeof storageMaxSlippage === 'string' ? Number(storageMaxSlippage) : 0.5
  })(),
  stopLossTakeProfit: {
    stopLossValue: '',
    stopLossInput: '',
    takeProfitValue: '',
    takeProfitInput: '',
  },
  isClosingPosition: false,
  isShort: () => get().orderDirection === OrderDirections.Short,
  isLimit: () => {
    const orderType = get().kind

    return (
      orderType === OrderTypeEnum.Limit ||
      orderType === OrderTypeEnum.StopLossLimit ||
      orderType === OrderTypeEnum.TakeProfitLimit
    )
  },
  isStop: () => {
    const orderType = get().kind

    return (
      orderType === OrderTypeEnum.StopLoss ||
      orderType === OrderTypeEnum.StopLossLimit ||
      orderType === OrderTypeEnum.TakeProfit ||
      orderType === OrderTypeEnum.TakeProfitLimit
    )
  },
  isTwap: () => get().kind === OrderTypeEnum.Twap,
  update: (type: OrderPlacementInput | 'limitPrice', value: string | undefined) => {
    if (value === undefined) {
      return
    }

    if (type === 'limitPrice') {
      set({ limitPrice: value })
      return
    }

    set({ pinnedInput: type, value: value })
  },
  setTriggerPrice: (value: string) => set({ triggerPrice: value }),
  changeOrderKind: (newKind: OrderTypeEnum) => {
    if (newKind === get().kind) {
      return
    }

    set({ kind: newKind, pinnedInput: 'size', value: '', limitPrice: '', triggerPrice: '' })
  },
  changeDirection: (newDirection: OrderDirections) =>
    set({
      orderDirection: newDirection,
      stopLossTakeProfit: {
        stopLossInput: '',
        stopLossValue: '',
        takeProfitInput: '',
        takeProfitValue: '',
      },
    }),
  changeTimeInForce: (newTimeInForce: TimeInForce) => set({ timeInForce: newTimeInForce }),
  changeTimeInForceValue: (newTimeInForceValue: string) =>
    set({
      timeInForceValue: validateAndParseTIFValue(newTimeInForceValue, get().timeInForceUnit),
    }),
  changeTimeInForceUnit: (newTimeInForceUnit: TimeInForceUnit) =>
    set({
      timeInForceUnit: newTimeInForceUnit,
      timeInForceValue: validateAndParseTIFValue(get().timeInForceValue, newTimeInForceUnit),
    }),
  changePostOnly: (newPostOnly: boolean) => set({ postOnly: newPostOnly }),
  changeIsAmountInUSD: (newAmountInUSD: boolean) => set({ isAmountInUSD: newAmountInUSD }),
  changeRuntimeMinutes: (newRuntimeMinutes: string) => set({ runtimeMinutes: newRuntimeMinutes }),
  changeRuntimeHours: (newRuntimeHours: string) => set({ runtimeHours: newRuntimeHours }),
  changeReduceOnly: (newReduceOnly: boolean) => set({ reduceOnly: newReduceOnly }),
  changeMaxSlippage: (newMaxSlippage: number) => set({ maxSlippage: newMaxSlippage }),
  changeStopLossInputs: (input: StopLossInput, value: string) =>
    set({
      stopLossTakeProfit: {
        ...get().stopLossTakeProfit,
        stopLossInput: input,
        stopLossValue: value,
      },
    }),
  changeTakeProfitInputs: (input: TakeProfitInput, value: string) =>
    set({
      stopLossTakeProfit: {
        ...get().stopLossTakeProfit,
        takeProfitInput: input,
        takeProfitValue: value,
      },
    }),
  changeIsClosingPosition: (value: boolean) => {
    if (value) {
      return set({ isClosingPosition: true, kind: 'market' })
    }

    set({ isClosingPosition: false, value: '' })
  },
}))

const selectIsClosingPosition = (state: OrderInputStore) => state.isClosingPosition
export const useIsClosingPosition = () => useOrderInputStore(selectIsClosingPosition)

const useOrderBookOrders = (isShort: boolean) => {
  const orderBook = useOrderBook()

  if (!orderBook) {
    return []
  }

  return isShort ? orderBook.bids : orderBook.asks
}

// maybe this should use mark price instead to prevent extreme edge cases
const useBestPrice = (isShort: boolean) => {
  const orders = useOrderBookOrders(isShort)

  return Number(orders[0]?.price)
}

const slTpExecutionPrice = (
  price: number,
  value: number,
  orderSize: number,
  isShort: boolean,
  isSL: boolean,
  isDolars: boolean,
) => {
  // isShort -> if the order we want to close with a SL/TP is a short order
  const sideSign = (isShort ? -1 : 1) * (isSL ? 1 : -1)

  if (isDolars) {
    // (price - executionPrice) * orderSize * sideSign = value_dolars
    // futurePrice = price - value_dolars / (sideSign * orderSize)
    return price - value / (sideSign * orderSize)
  }

  // price * (1 - sideSign * value_percent) = executionPrice
  return price * (1 - sideSign * (value / 100))
}

export const useOrderToPlaceUpdater = () => {
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarket = useCurrentMarket()
  const orderInputStore = useOrderInputStore()
  const isShort = orderInputStore.isShort()
  const orderBookOrders = useOrderBookOrders(isShort)
  const stopLossValue = orderInputStore.stopLossTakeProfit.stopLossValue
  const stopLossInput = orderInputStore.stopLossTakeProfit.stopLossInput
  const takeProfitValue = orderInputStore.stopLossTakeProfit.takeProfitValue
  const takeProfitInput = orderInputStore.stopLossTakeProfit.takeProfitInput
  const kind = orderInputStore.kind
  const isLimit = kind === OrderTypeEnum.Limit
  const limitPrice = orderInputStore.limitPrice
  const decimal = currentMarket.price_decimals + currentMarket.size_decimals
  const realBuyingPower = useRealBuyingPower()
  const leverage = marginFractionToLeverage(getInitialMarginFraction(currentMarket.market_id))

  const matchInfo = useMemo(() => {
    if (kind === OrderTypeEnum.Limit) {
      switch (orderInputStore.pinnedInput) {
        case 'size': {
          return {
            fullyFilled: true,
            estPrice: '0.00',
            amount0: orderInputStore.value,
            amount1: isZero(orderInputStore.limitPrice)
              ? ''
              : (Number(orderInputStore.value) * Number(orderInputStore.limitPrice)).toFixed(
                  currentMarket.price_decimals,
                ),
          }
        }
        case 'usd': {
          return {
            fullyFilled: true,
            estPrice: '0.00',
            amount0: isZero(orderInputStore.limitPrice)
              ? ''
              : (Number(orderInputStore.value) / Number(orderInputStore.limitPrice)).toFixed(
                  currentMarket.size_decimals,
                ),
            amount1: orderInputStore.value,
          }
        }
        case 'leverage': {
          const usd = (realBuyingPower * Number(orderInputStore.value)) / 100
          const amt0 = (usd / Number(orderInputStore.limitPrice)).toFixed(
            currentMarket.size_decimals,
          )
          const amt1 = usd.toFixed(currentMarket.price_decimals)

          return {
            fullyFilled: true,
            estPrice: '0.00',
            amount0: isZero(amt0) ? '' : amt0,
            amount1: isZero(amt1) ? '' : amt1,
          }
        }
      }
    }

    switch (orderInputStore.pinnedInput) {
      case 'size':
        return getMarketOrderFromAmount0(currentMarket, orderInputStore.value, orderBookOrders)
      case 'usd':
        return getMarketOrderFromAmount1(currentMarket, orderInputStore.value, orderBookOrders)
      case 'leverage': {
        const usd = (realBuyingPower * (Number(orderInputStore.value) / 100)).toFixed(2)

        return getMarketOrderFromAmount1(currentMarket, usd, orderBookOrders)
      }
    }
  }, [currentMarket, orderBookOrders, orderInputStore, realBuyingPower, kind])

  const stopLossInfo = useMemo(() => {
    const isLimit = kind === OrderTypeEnum.Limit
    const orderSize = matchInfo.amount0
    const price = isLimit ? Number(limitPrice) : Number(matchInfo.estPrice)

    if (!stopLossValue) {
      return { stopLossPrice: '', lossAmount: '', lossPercentage: '' }
    }

    switch (stopLossInput) {
      case 'stopLossPrice': {
        if (!price || !orderSize) {
          return { stopLossPrice: stopLossValue, lossAmount: '', lossPercentage: '' }
        }

        const priceDiff = (isShort ? 1 : -1) * (Number(stopLossValue) - price)
        const newLossAmount = priceDiff * Number(orderSize)
        const priceDiffPercentage = (1 - Number(stopLossValue) / price) * 100
        const newLossPercentage = priceDiffPercentage * (isShort ? -1 : 1) * leverage

        return {
          stopLossPrice: stopLossValue,
          lossAmount: newLossAmount.toFixed(decimal),
          lossPercentage: newLossPercentage.toFixed(2),
        }
      }
      case 'lossUsd': {
        if (!price || !orderSize) {
          return { stopLossPrice: '', lossAmount: stopLossValue, lossPercentage: '' }
        }

        const newStopLossPrice = slTpExecutionPrice(
          price,
          Number(stopLossValue),
          Number(orderSize),
          isShort,
          true,
          true,
        )
        const priceDiff = (isShort ? -1 : 1) * (price - newStopLossPrice)
        const priceDiffPercentage = (priceDiff / price) * 100

        return {
          stopLossPrice: newStopLossPrice.toFixed(decimal),
          lossAmount: stopLossValue,
          lossPercentage: (priceDiffPercentage * leverage).toFixed(2),
        }
      }
      case 'lossPercent': {
        if (!price || !orderSize) {
          return { stopLossPrice: '', lossAmount: '', lossPercentage: stopLossValue }
        }

        const newStopLossPrice = slTpExecutionPrice(
          price,
          Number(stopLossValue) / leverage,
          Number(orderSize),
          isShort,
          true,
          false,
        )
        const newLossAmount = (price - newStopLossPrice) * (isShort ? -1 : 1) * leverage

        return {
          stopLossPrice: newStopLossPrice.toFixed(decimal),
          lossAmount: newLossAmount.toFixed(decimal),
          lossPercentage: stopLossValue,
        }
      }
      default:
        return { stopLossPrice: '', lossAmount: '', lossPercentage: '' }
    }
  }, [matchInfo, stopLossValue, stopLossInput, leverage, decimal, limitPrice, kind, isShort])

  const takeProfitInfo = useMemo(() => {
    const isLimit = kind === OrderTypeEnum.Limit
    const orderSize = matchInfo.amount0
    const price = isLimit ? Number(limitPrice) : Number(matchInfo.estPrice)

    if (!takeProfitValue) {
      return { takeProfitPrice: '', gainAmount: '', gainPercentage: '' }
    }

    switch (takeProfitInput) {
      case 'takeProfitPrice': {
        if (!price || !orderSize) {
          return { takeProfitPrice: takeProfitValue, gainAmount: '', gainPercentage: '' }
        }

        const priceDiff = (isShort ? 1 : -1) * (price - Number(takeProfitValue))
        const priceDiffPercentage = (1 - Number(takeProfitValue) / price) * 100
        const newGainAmount = priceDiff * Number(orderSize)
        const newGainPercentage = priceDiffPercentage * (isShort ? 1 : -1) * leverage

        return {
          takeProfitPrice: takeProfitValue,
          gainAmount: newGainAmount.toFixed(decimal),
          gainPercentage: newGainPercentage.toFixed(2),
        }
      }
      case 'gainUsd': {
        if (!price || !orderSize) {
          return { takeProfitPrice: '', gainAmount: takeProfitValue, gainPercentage: '' }
        }

        const newTakeProfitPrice = slTpExecutionPrice(
          price,
          Number(takeProfitValue),
          Number(orderSize),
          isShort,
          false,
          true,
        )
        const priceDiff = (isShort ? 1 : -1) * (price - newTakeProfitPrice)
        const priceDiffPercentage = (priceDiff / price) * 100

        return {
          takeProfitPrice: newTakeProfitPrice.toFixed(decimal),
          gainAmount: takeProfitValue,
          gainPercentage: (priceDiffPercentage * leverage).toFixed(2),
        }
      }
      case 'gainPercent': {
        if (!price || !orderSize) {
          return { takeProfitPrice: '', gainAmount: '', gainPercentage: takeProfitValue }
        }

        const newTakeProfitPrice = slTpExecutionPrice(
          price,
          Number(takeProfitValue) / leverage,
          Number(orderSize),
          isShort,
          false,
          false,
        )
        const newGainAmount = (price - newTakeProfitPrice) * (isShort ? 1 : -1) * leverage

        return {
          takeProfitPrice: newTakeProfitPrice.toFixed(decimal),
          gainAmount: newGainAmount.toFixed(decimal),
          gainPercentage: takeProfitValue,
        }
      }
      default:
        return { takeProfitPrice: '', gainAmount: '', gainPercentage: '' }
    }
  }, [matchInfo, takeProfitValue, takeProfitInput, leverage, decimal, limitPrice, kind, isShort])

  const derivedLeverage = useMemo(() => {
    if (orderInputStore.pinnedInput === 'leverage') {
      return orderInputStore.value
    }

    if (realBuyingPower === 0) {
      return '0'
    }

    return Math.min((Number(matchInfo.amount1) / realBuyingPower) * 100, 100).toFixed(0)
  }, [orderInputStore.pinnedInput, orderInputStore.value, matchInfo, realBuyingPower])

  const isStopPriceValid = useMemo(() => {
    const stopLossPrice = stopLossInfo.stopLossPrice
    const price = isLimit ? Number(orderInputStore.limitPrice) : Number(matchInfo.estPrice)

    if (!price || !stopLossPrice) {
      return true
    }

    return isShort ? Number(stopLossPrice) > price : Number(stopLossPrice) < price
  }, [stopLossInfo.stopLossPrice, orderInputStore.limitPrice, isLimit, isShort, matchInfo.estPrice])

  const isTakeProfitPriceValid = useMemo(() => {
    const takeProfitPrice = takeProfitInfo.takeProfitPrice
    const price = isLimit ? Number(orderInputStore.limitPrice) : Number(matchInfo.estPrice)

    if (!price || !takeProfitPrice) {
      return true
    }

    return isShort ? Number(takeProfitPrice) < price : Number(takeProfitPrice) > price
  }, [
    takeProfitInfo.takeProfitPrice,
    orderInputStore.limitPrice,
    isLimit,
    isShort,
    matchInfo.estPrice,
  ])

  const isSizeValid = useMemo(
    () => Number(matchInfo.amount0) >= Number(currentMarket.min_base_amount),
    [matchInfo, currentMarket.min_base_amount],
  )

  const isPriceValid = useMemo(
    () => Number(matchInfo.amount1) >= Number(currentMarket.min_quote_amount),
    [matchInfo, currentMarket.min_quote_amount],
  )

  const slippage = useMemo(() => {
    if (!matchInfo.fullyFilled || Number(matchInfo.amount0) === 0 || orderBookOrders.length === 0) {
      return 0
    }

    const initialPrice = Number(orderBookOrders[0]!.price)

    return Math.abs((Number(matchInfo.estPrice) - initialPrice) / initialPrice) * 100
  }, [orderBookOrders, matchInfo])

  return {
    derivedLeverage,
    matchInfo,
    slippage,
    isSizeValid,
    isPriceValid,
    stopLossInfo,
    takeProfitInfo,
    isStopPriceValid,
    isTakeProfitPriceValid,
  }
}

export const PlaceOrder = () => {
  const [liquidationWarning, setLiquidationWarning] = useState(false)

  const currentMarket = useCurrentMarket()
  const orderInputs = useOrderInputStore()
  const orderType = orderInputs.kind
  const maxSlippage = orderInputs.maxSlippage
  const changeMaxSlippage = orderInputs.changeMaxSlippage

  const orderDirection = orderInputs.orderDirection
  const currentMarketStats = useCurrentMarketStats()

  const orderData = useOrderToPlaceUpdater()
  const isClosingPosition = useIsClosingPosition()

  const markPrice = Number(currentMarketStats?.mark_price)

  const isShort = orderInputs.isShort()
  const isLimit = orderInputs.isLimit()
  const isStop = orderInputs.isStop()
  const isMarket = orderType === OrderTypeEnum.Market
  const isTwap = orderType === OrderTypeEnum.Twap
  const isReduceOnly = orderInputs.reduceOnly
  const bestPrice = useBestPrice(isShort)
  const isStopPriceValid = orderData.isStopPriceValid
  const isTakeProfitPriceValid = orderData.isTakeProfitPriceValid

  // These checks are correct, don't change!
  const isSLTriggerPriceInvalid =
    !!orderInputs.triggerPrice &&
    (orderType === OrderTypeEnum.StopLoss || orderType === OrderTypeEnum.StopLossLimit) &&
    (isShort
      ? Number(orderInputs.triggerPrice) > markPrice
      : Number(orderInputs.triggerPrice) < markPrice)

  const isTPtriggerPriceInvalid =
    !!orderInputs.triggerPrice &&
    (orderType === OrderTypeEnum.TakeProfit || orderType === OrderTypeEnum.TakeProfitLimit) &&
    (isShort
      ? Number(orderInputs.triggerPrice) < markPrice
      : Number(orderInputs.triggerPrice) > markPrice)

  const sizeValue = orderInputs.isAmountInUSD
    ? orderData.matchInfo.amount1
    : orderData.matchInfo.amount0

  const passesLimitOrderChecks = !isLimit || (orderData.isSizeValid && orderData.isPriceValid)
  const passesSLTPOrderChecks = isStopPriceValid && isTakeProfitPriceValid
  const midPrice = useMidPrice()

  useEffect(() => {
    useOrderInputStore.setState({
      isClosingPosition: false,
      value: '',
      limitPrice: '',
      triggerPrice: '',
      stopLossTakeProfit: {
        stopLossInput: '',
        stopLossValue: '',
        takeProfitInput: '',
        takeProfitValue: '',
      },
    })
  }, [currentMarket.symbol])

  const setOrderDirectionFromButton = (dir: OrderDirections) => orderInputs.changeDirection(dir)

  const setOrderTypeFromButton = (type: OrderTypeEnum) => {
    orderInputs.changeOrderKind(type)
    const { data: userSelections = {} } = readSSUserSelections()
    writeSSUserSelection({ ...userSelections, orderType: type })
  }

  return (
    <div className="relative size-full overflow-y-hidden">
      <div className="relative size-full flex-[3] overflow-y-auto max-tablet:min-h-[calc(100dvh-60px-16px)] max-mobile:max-h-dvh max-mobile:overflow-y-scroll">
        {isClosingPosition ? (
          <ClosePosition
            setLiquidationWarning={setLiquidationWarning}
            slippage={orderData.slippage}
            maxSlippage={maxSlippage}
          />
        ) : (
          <div className="flex h-full flex-col">
            <OrderTypeSelector
              orderType={orderType}
              setOrderTypeFromButton={setOrderTypeFromButton}
            />
            <OrderDirectionSelector
              orderDirection={orderInputs.orderDirection}
              setOrderDirectionFromButton={setOrderDirectionFromButton}
            />
            <BuyingStats
              newSize={Number(orderData.matchInfo.amount0)}
              isShort={isShort}
              isLimitOrder={isLimit}
              setLiquidationWarning={setLiquidationWarning}
            />
            <div className="flex size-full flex-col gap-2 px-3 max-tablet:my-4">
              {isStop && <TriggerPriceInput />}
              <OrderSizeInput value={sizeValue} />
              {isLimit ? (
                <>
                  <PriceInput />
                  <LeverageSlider
                    side={orderDirection}
                    leverage={Number(orderData.derivedLeverage)}
                    onChange={(value) => {
                      if (isZero(orderInputs.limitPrice)) {
                        orderInputs.update('limitPrice', midPrice.toFixed(2))
                      }
                      orderInputs.update('leverage', Math.min(Math.max(value, 0), 100).toFixed(0))
                    }}
                    setIsLeverageInput={(focused: boolean) => {
                      if (focused && isZero(orderInputs.limitPrice)) {
                        orderInputs.update('limitPrice', midPrice.toFixed(2))
                      }
                      orderInputs.update('leverage', orderData.derivedLeverage)
                    }}
                  />
                </>
              ) : (
                <LeverageSlider
                  side={orderDirection}
                  leverage={Number(orderData.derivedLeverage)}
                  onChange={(value) =>
                    orderInputs.update('leverage', Math.min(Math.max(value, 0), 100).toFixed(0))
                  }
                  setIsLeverageInput={() => {
                    orderInputs.update('leverage', orderData.derivedLeverage)
                  }}
                />
              )}
              {isTwap && <RunningTime />}
              {!isStop && <ReduceOnly />}
              {!isReduceOnly && !isStop && <StopLossTakeProfit />}
              <div className="flex w-full flex-col gap-2 pb-4">
                {isLimit && <AdvancedLimitDropdown />}
                <PlaceOrderButton
                  notEnoughLiquidity={!orderData.matchInfo.fullyFilled}
                  liquidationWarning={liquidationWarning}
                  triggerPriceInvalid={isSLTriggerPriceInvalid || isTPtriggerPriceInvalid}
                  passesSLTPOrderChecks={passesSLTPOrderChecks}
                  passesLimitOrderChecks={passesLimitOrderChecks}
                  worstExecutionPrice={bestPrice * ((100 + maxSlippage * (isShort ? -1 : 1)) / 100)}
                  amount0={orderData.matchInfo.amount0}
                  estimatedPrice={orderData.matchInfo.estPrice}
                />
                {(!isMarket
                  ? orderInputs.value !== '' && orderInputs.limitPrice !== ''
                  : orderInputs.value !== '') && (
                  <Warning
                    isAmount0Less={isLimit && !orderData.isSizeValid}
                    isAmount1Less={isLimit && !orderData.isPriceValid}
                  />
                )}
                {isSLTriggerPriceInvalid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your trigger price must be {isShort ? 'below' : 'above'} the current mark
                      price.
                    </p>
                  </WarningContainer>
                )}
                {isTPtriggerPriceInvalid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your trigger price must be {isShort ? 'above' : 'below'} the current mark
                      price.
                    </p>
                  </WarningContainer>
                )}
                {!isStopPriceValid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your stop price must be {isShort ? 'above' : 'below'} the{' '}
                      {isLimit ? 'limit' : 'estimated'} price.
                    </p>
                  </WarningContainer>
                )}
                {!isTakeProfitPriceValid && (
                  <WarningContainer>
                    <p className="typography-body-2 text-white">
                      Your take profit price must be {isShort ? 'below' : 'above'} the{' '}
                      {isLimit ? 'limit' : 'estimated'} price.
                    </p>
                  </WarningContainer>
                )}
                {!isTwap && (
                  <PriceImpact
                    isLimit={isLimit}
                    isClosingPosition={false}
                    slippage={orderData.slippage}
                    isLongOrder={!isShort}
                    maxSlippage={maxSlippage}
                    setMaxSlippage={changeMaxSlippage}
                    estPrice={orderData.matchInfo.estPrice}
                    orderSize={orderData.matchInfo.amount0}
                    orderValue={orderData.matchInfo.amount1}
                  />
                )}
                {isTwap && <TwapStats orderSize={orderData.matchInfo.amount0} />}
                <AccountOverview />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
