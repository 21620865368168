import { type ChangeEventHandler } from 'react'

import { Clickable } from 'js/shared-components/Clickable'
import { Explanation } from 'js/shared-components/ExplanationPopup'

import Input from '../Input'

// this should forwardRef and reuse the default input types
// but styled-components doesn't allow for that :/
interface PriceInputProps {
  // input props
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder: string
  buttonText: string
  onClick: () => void
  disabled?: boolean

  // container props
  label: string
  helperText?: string
}

const PriceInput = ({
  label,
  helperText,
  onClick,
  buttonText,
  disabled,
  ...rest
}: PriceInputProps) => (
  <div className="flex w-full flex-col gap-1">
    <div className="flex items-center gap-2">
      <p className="text-sm text-white">{label}</p>
      {!!helperText && <Explanation explanation={helperText} />}
    </div>
    <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
      <Input
        {...rest}
        disabled={disabled}
        className="h-[unset] overflow-hidden rounded-none border-0 indent-0 text-sm placeholder:indent-0 placeholder:text-sm max-mobile:text-base max-mobile:placeholder:text-base"
      />
      {!disabled && (
        <Clickable color="blue" onClick={onClick}>
          <p className="typography-body-1 font-bold">{buttonText}</p>
        </Clickable>
      )}
    </div>
  </div>
)

export default PriceInput
