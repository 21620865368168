import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const TriggerPriceInput = () => {
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const value = orderInputs.triggerPrice
  const onChange = (e: string) => orderInputs.setTriggerPrice(e)
  const decimal = currentMarket.price_decimals + currentMarket.size_decimals

  return (
    <UIKitCurrencyInput
      label="Trigger Price"
      helperText="When the mark price of this market crosses your trigger price, your stop/take profit order will either convert to a limit order or execute as a market order."
      value={value}
      symbol="USD"
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
