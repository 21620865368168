import { create } from 'zustand'

interface UserState {
  accountIndex: number | null
  showOnboarding: boolean
  showWhitelist: boolean
  showFeedback: boolean
}

export const useUserStore = create<UserState>(() => ({
  accountIndex: null,
  showOnboarding: false,
  showWhitelist: false,
  showFeedback: false,
}))

const selectAccountIndex = (state: UserState) => state.accountIndex
export const useAccountIndex = () => useUserStore(selectAccountIndex)

const selectShowOnboarding = (state: UserState) => state.showOnboarding
export const useShowOnboarding = () => useUserStore(selectShowOnboarding)

const selectShowWhitelist = (state: UserState) => state.showWhitelist
export const useShowWhitelist = () => useUserStore(selectShowWhitelist)

const selectShowFeedback = (state: UserState) => state.showFeedback
export const useShowFeedback = () => useUserStore(selectShowFeedback)
