export const constants = {
  FEEDBACK_FORM_URL:
    'https://docs.google.com/forms/d/e/1FAIpQLSfXVk8hRZ__fO7zIgliJhMCkxmOZr_8wMpUWux9-hzkDL-Utg/formResponse?',
  FEEDBACK_NAME_FIELD: 'entry.539674819',
  FEEDBACK_EMAIL_FIELD: 'entry.2076860204',
  FEEDBACK_CONTENT_FIELD: 'entry.160371226',
  WAITLIST_FORM_URL:
    'https://docs.google.com/forms/d/e/1FAIpQLScL77I90giyXJRNkr-nr2wxOrMy9VJehuqA0U3RAJgJQwQZPg/formResponse?',
  WAITLIST_EMAIL_FIELD: 'entry.1612504299',
  WAITLIST_L1_ADDRESS_FIELD: 'entry.1265573806',
  WAITLIST_TELEGRAM_FIELD: 'entry.1612504299',
  WAITLIST_DISCORD_FIELD: 'entry.876597602',
  WAITLIST_SUBJECT_FIELD: 'entry.1643181195',
  FAT_FINGER_ERROR: 'order price flagged as an accidental price',
  LIMIT_PRICE_FAR_ERROR: 'limit order price is too far from the mark price',
  SLTP_PRICE_FAR_ERROR: 'SL/TP order price is too far from the trigger price',
} as const
