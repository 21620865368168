import { useQuery } from '@tanstack/react-query'
import { formatDate, fromUnixTime, getUnixTime, sub as subDuration } from 'date-fns'
import { type EChartsCoreOption } from 'echarts'
import { useMemo, useState } from 'react'
import type { FundingsRequest } from 'zklighter-perps'

import { colors, fonts } from 'css/css'
import { useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { useEchart } from 'js/shared-components/useEchart'
import { candlesticksApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'

const timelines = {
  '1d': { name: '1D', value: 1, timeMeasure: 'days', resolution: '1h' },
  '1w': { name: '1W', value: 1, timeMeasure: 'weeks', resolution: '1h' },
  '1m': { name: '1M', value: 1, timeMeasure: 'months', resolution: '1h' },
} as const

type Timeline = keyof typeof timelines

export const FundingRateChart = () => {
  const [timeline, setTimeline] = useState<Timeline>('1d')
  const currentMarketId = useCurrentMarketId()

  const startTimestamp = useMemo(
    () =>
      getUnixTime(
        subDuration(new Date(), { [timelines[timeline].timeMeasure]: timelines[timeline].value }),
      ),
    [timeline],
  )
  const endTimestamp = useMemo(() => getUnixTime(new Date()), [])
  const params: FundingsRequest = useMemo(
    () => ({
      market_id: currentMarketId,
      resolution: timelines[timeline].resolution,
      start_timestamp: startTimestamp,
      end_timestamp: endTimestamp,
      count_back: 0,
    }),
    [currentMarketId, timeline, startTimestamp, endTimestamp],
  )
  const fundingRateQuery = useQuery({
    queryKey: ['fundingRate', params],
    queryFn: () => candlesticksApi.fundings(params),
    select: ({ fundings }) =>
      fundings.map((item) => [
        item.timestamp,
        item.direction === 'short' ? -(Number(item.rate) * 100) : Number(item.rate) * 100,
      ]),
  })

  const chartOpts = useMemo<EChartsCoreOption>(
    () => ({
      textStyle: {
        fontFamily: fonts.satoshi,
      },
      grid: {
        left: 16,
        right: 16,
        top: 12,
        bottom: 50,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: colors.greyMain,
        borderWidth: 0,
        padding: 3,
        formatter: (d: { data: [number, number] }[]) => {
          if (d.length === 0) {
            return null
          }

          const [ts, val] = d[0]!.data

          const containerClassName = cn(
            'relative flex min-w-[250px] flex-col gap-1 p-4',
            'before:absolute before:left-0 before:top-0 before:h-full before:w-0.5',
            { 'before:bg-green-main': val > 0, 'before:bg-red-main': val < 0 },
          )

          return `
            <div class="${containerClassName}">
              <div class="text-white">
                ${formatDate(fromUnixTime(ts), "dd MMM''yy HH:mm")}
              </div>
              <div class="flex justify-between">
                <span class="text-white-opaque">Funding Rate</span> 
                <span class="text-white">${(val / 100).toFixed(4)}%</span>
              </div>
              <div class="flex justify-between">
                <span class="text-white-opaque">Direction</span>
                <span class="text-white">
                  ${val < 0 ? 'Short &#8594 Long' : 'Long &#8594 Short'}
                </span>
              </div>
            </div>
          `
        },
      },
      xAxis: {
        type: 'time',
        show: false,
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          name: 'funding',
          encode: {
            x: 'timestamp',
            y: 'value',
          },
          type: 'line',
          data: fundingRateQuery.data ?? [],
          lineStyle: {
            color: colors.greenMain,
            width: 1.2,
          },
        },
      ],
      backgroundColor: 'transparent',
    }),
    [fundingRateQuery.data],
  )

  const { chartContainerRef } = useEchart(chartOpts)

  return (
    <div className="relative h-full max-w-full">
      <div className="flex w-full justify-end gap-3 p-3">
        {Object.entries(timelines).map(([key, value]) => (
          <p
            className={cn('typography-body-1 cursor-pointer transition-all hover:text-white', {
              'text-primary-blue-main': timeline === key,
              'text-white-opaque': timeline !== key,
            })}
            key={key}
            // Object.keys/entries/values/etc don't play well with const objects
            onClick={() => setTimeline(key as typeof timeline)}
          >
            {value.name}
          </p>
        ))}
      </div>
      <div className="absolute inset-0 top-10" ref={chartContainerRef} />
    </div>
  )
}
