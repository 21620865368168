import type { ReactNode } from 'react'

import { useIsGeoLocBlockedQuery, useIsWhitelistedQuery } from 'js/providers/hooks/useAccountQuery'

type PublicPoolActionsBlockerButtonProps = {
  children: ReactNode
  className?: string
}

const PublicPoolActionsBlocker = ({ children }: PublicPoolActionsBlockerButtonProps) => {
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const isGeoLocBlockedQuery = useIsGeoLocBlockedQuery()

  if (
    isGeoLocBlockedQuery.isPending ||
    isGeoLocBlockedQuery.data ||
    isWhitelistedQuery.isLoading ||
    isWhitelistedQuery.data === false
  ) {
    return null
  }

  return <>{children}</>
}

export default PublicPoolActionsBlocker
