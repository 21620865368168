import { NotificationPopup } from 'js/shared-components/NotificationPopup'

import Icon from '../Icon'

interface ToastProps {
  level: 'error' | 'success' | 'loading'
  description: string
  onClose?: () => void
}

const Toast = ({ description, level, onClose }: ToastProps) => (
  <NotificationPopup onClose={onClose}>
    <div className="flex w-full items-center gap-2">
      {level === 'success' && (
        <Icon
          icon="check"
          className="size-4 rounded-full border border-green-main text-green-main"
        />
      )}
      {level === 'error' && <Icon icon="x" className="size-4 text-red-main" />}
      {level === 'loading' && <Icon icon="spinner" className="size-4 text-white" />}
      <p className="typography-body-1 text-white">{description}</p>
    </div>
  </NotificationPopup>
)

export default Toast
