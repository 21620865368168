import {
  useAccountPositions,
  usePublicPoolInfo,
  useUserAccountShares,
} from 'js/providers/accounts-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { formatUSD } from 'js/util/formatting'
import { usePortfolioStats } from 'js/util/positions'

import { getYourDeposits, usePublicPoolQuery } from './utils'

const PublicPoolStats = () => {
  const { isMobile } = useResponsiveness()
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const publicPoolPositions = useAccountPositions(publicPoolQuery.data.index)
  const publicPoolStats = usePortfolioStats(publicPoolPositions, publicPoolQuery.data.index)
  const shares = useUserAccountShares()

  return (
    <div className="flex justify-between rounded-xl bg-white/5 max-mobile:gap-0">
      <div className="flex flex-col gap-2 p-6">
        <p className="typography-body-1 text-white">TVL</p>
        <p className="typography-text-8 max-mobile:typography-text-6 text-white">
          {publicPoolStats !== null ? formatUSD(publicPoolStats.portfolioValue) : '-'}
        </p>
      </div>
      <div className="flex flex-col gap-2 border-l p-6">
        <p className="typography-body-1 text-white">Your Deposits</p>
        <p className="typography-text-8 max-mobile:typography-text-6 text-white">
          {getYourDeposits(
            shares,
            publicPoolQuery.data.index,
            Number(publicPoolStats?.portfolioValue ?? 0),
            poolInfo?.total_shares ?? publicPoolQuery.data.pool_info.total_shares,
            isMobile,
          )}
        </p>
      </div>
    </div>
  )
}

export default PublicPoolStats
