import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type { AccountRequest, PublicPool, PublicPoolsRequest } from 'zklighter-perps'

import { useAccountPositions, usePublicPoolInfo } from 'js/providers/accounts-store'
import { SubAccountType, type Share } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/user-store'
import { accountApi, parseErrorFromCodegen } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { usePortfolioStats } from 'js/util/positions'

import { MaxAccountIndexLimit } from '../public-pools/utils'

type PublicPoolParams = {
  public_pool_index: string
}

const usePublicPoolPageParams = () => useParams() as PublicPoolParams

export const useProtocolPoolListQuery = () => {
  const accountIndex = useAccountIndex()
  const params: PublicPoolsRequest = {
    index: MaxAccountIndexLimit,
    limit: 100,
    filter: 'protocol',
    account_index: accountIndex!,
  }

  return useQuery({
    queryKey: ['public_pool', params],
    queryFn: () => accountApi.publicPools(params),
    refetchInterval: 5000,
  })
}
export const usePublicPoolQuery = () => {
  const { public_pool_index } = usePublicPoolPageParams()
  const params: AccountRequest = useMemo(
    () => ({ by: 'index', value: public_pool_index }),
    [public_pool_index],
  )

  return useSuspenseQuery({
    queryKey: ['public_pool', params],
    queryFn: () =>
      accountApi
        .account(params)
        .then(({ accounts }) => accounts[0]!)
        .catch((e) =>
          parseErrorFromCodegen(e).then((err) => {
            throw err
          }),
        ),
    refetchInterval: 5000,
  })
}

export const getYourDeposits = (
  shares: Share[],
  publicPoolIndex: number,
  tvl: number,
  totalShares: number,
  isMobile: boolean,
) => {
  const share = shares.find((share) => share.public_pool_index === publicPoolIndex)

  if (!share) {
    return '-'
  }

  return isMobile
    ? Intl.NumberFormat('en', { notation: 'compact' }).format(
        (share.shares_amount * tvl) / totalShares,
      )
    : formatUSD((share.shares_amount * tvl) / totalShares)
}

export const usePublicPoolSharePrice = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const positions = useAccountPositions(publicPoolQuery.data.index)
  const totalShares = usePublicPoolInfo(publicPoolQuery.data.index)?.total_shares
  const portfolioStats = usePortfolioStats(positions, publicPoolQuery.data.index)

  if (totalShares === undefined || portfolioStats === null) {
    return null
  }

  if (totalShares === 0) {
    return 0.001
  }

  return portfolioStats.portfolioValue / totalShares
}

export const getPublicPoolName = (publicPool: Pick<PublicPool, 'name' | 'account_type'>) =>
  publicPool.name ||
  (publicPool.account_type === SubAccountType.lighterPublic
    ? 'Lighter Liquidity Provider (LLP)'
    : 'Unnamed Pool')
