import { forwardRef, type TdHTMLAttributes } from 'react'

import cn from 'js/util/cn'

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <td
      ref={forwardedRef}
      className={cn('max-h-10 px-2 first:pl-3 last:pr-3 last:text-right', className)}
      {...rest}
    />
  ),
)

TableCell.displayName = 'TableCell'

export default TableCell
