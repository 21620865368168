import type { PropsWithChildren } from 'react'

import {
  useAccountsQuery,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'

import { ChartLoader } from '../../chart/ChartLoader'
import { NotLoggedInText } from '../shared/NotLoggedInText'

const TradeTablesBlocker = ({ children }: PropsWithChildren) => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const accountsQuery = useAccountsQuery()

  if (accountsQuery.isLoading) {
    return <ChartLoader />
  }

  if (!userAddress || !userAccount) {
    return <NotLoggedInText />
  }

  return <>{children}</>
}

export default TradeTablesBlocker
