import { useAccountPositions } from 'js/providers/accounts-store'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { formatLeverage, formatUSD } from 'js/util/formatting'
import {
  useAccountTotalUnrealizedPnL,
  useMaintenanceMarginReq,
  usePortfolioStats,
} from 'js/util/positions'

import { usePublicPoolQuery } from '../utils'

export const PublicPoolPerformance = () => {
  const publicPoolQuery = usePublicPoolQuery()
  const positions = useAccountPositions(publicPoolQuery.data.index)
  const portfolioStats = usePortfolioStats(positions, publicPoolQuery.data.index)
  const maintenanceMarginReq = useMaintenanceMarginReq(positions)

  const unrealizedPnl = useAccountTotalUnrealizedPnL(positions)

  return (
    <div className="flex overflow-hidden rounded-lg bg-white/5">
      <div className="flex min-h-[200px] w-full flex-col gap-3 p-4">
        <div className="flex w-full items-center justify-between">
          <p className="typography-body-2 text-white-opaque">Operator Fee</p>
          <p className="typography-label-1 text-white">
            {Number(publicPoolQuery.data.pool_info.operator_fee).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            %
          </p>
        </div>
        <div className="flex w-full items-center justify-between">
          <p className="typography-body-2 text-white-opaque">Minimum Operator Share</p>
          <p className="typography-label-1 text-white">
            {Number(publicPoolQuery.data.pool_info.min_operator_share_rate).toLocaleString(
              'en-US',
              { maximumFractionDigits: 2, minimumFractionDigits: 0 },
            )}
            %
          </p>
        </div>
        <Stat
          label="Cross Leverage"
          value={portfolioStats === null ? '-' : formatLeverage(portfolioStats.leverage)}
          explanation="Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks."
        />
        <Stat
          label="Cross Margin Usage"
          value={portfolioStats === null ? '-' : `${portfolioStats.initialMarginUsage.toFixed(2)}%`}
          explanation="Margin usage refers to the portion of the pool’s equity used to maintain open positions. High margin usage can lead to partial liquidations."
        />
        <Stat
          label="Maintenance Margin Requirement"
          value={formatUSD(maintenanceMarginReq)}
          explanation="The maintenance margin requirement is the amount of collateral that must be maintained in a pool to support open positions. If the pool value falls below the maintenance margin requirement, the pool will be partially liquidated."
        />
        <Stat
          label="Cross Margin Ratio"
          value={
            portfolioStats === null
              ? '-'
              : `${portfolioStats.maintenanceMarginUsage.toLocaleString('en-US', { maximumFractionDigits: 6 })}%`
          }
          explanation="The cross margin ratio is the ratio of the maintenance margin requirement to the total pool value. A higher ratio indicates a higher risk of liquidation."
        />
        <Stat
          label="Unrealized PnL"
          value={formatUSD(unrealizedPnl)}
          explanation="Unrealized profit and loss is the potential profit or loss on open positions. It is calculated by the difference between the current market price and the average entry price of the position."
        />
      </div>
    </div>
  )
}

const Stat = ({
  label,
  value,
  explanation,
}: {
  label: string
  value: string
  explanation: string
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <Explanation
        explanation={explanation}
        trigger={<p className="typography-body-2 text-white-opaque underline">{label}</p>}
      />
      <p className="typography-label-1 text-white">{value}</p>
    </div>
  )
}
