import { formatDate } from 'date-fns'

import { ServerStatus } from './ServerStatus'

export const Footer = () => {
  const date = new Date()
  const dateString = formatDate(date, 'yyyy-MM-dd')
  return (
    <div className="fixed inset-x-0 bottom-0 z-20 flex items-center justify-between border-t bg-black px-3 py-1">
      <ServerStatus />
      <div className=" text-white">
        rev {dateString}-{import.meta.env.VITE_APP_VERSION_HASH}
      </div>
    </div>
  )
}
