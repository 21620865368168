import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import { accountApi } from 'js/util/api/sdk'
import { useUserAccountPortfolioStats } from 'js/util/positions'

import Toast from './uikit/Toast'

interface FaucetModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const FaucetModal = ({ open, onOpenChange }: FaucetModalProps) => {
  const userAddress = useUserAddress()
  const portfolioStats = useUserAccountPortfolioStats()
  const canRequestFunds = portfolioStats !== null && portfolioStats.portfolioValue <= 100

  const faucetMutation = useMutation({
    mutationFn: () => accountApi.faucet({ l1_address: userAddress }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Funds received"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => onOpenChange(false),
  })

  return (
    <Modal title="Request Funds" open={open} onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-3">
        <p className="typography-label-1 text-white">
          {canRequestFunds
            ? 'Your account will be credited with test funds'
            : 'You can request more funds when your total portfolio value is below 100 USD'}
        </p>
        <Button
          disabled={!canRequestFunds}
          isLoading={faucetMutation.isPending}
          className="w-full"
          onClick={() => faucetMutation.mutate()}
        >
          Request Funds
        </Button>
      </div>
    </Modal>
  )
}
