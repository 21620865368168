import React from 'react'

import { Navbar } from 'js/pages/navbar/Navbar'
import { useIsGeoLocBlockedQuery } from 'js/providers/hooks/useAccountQuery'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { ConnectWalletModal } from 'js/shared-components/ConnectWalletModal'
import { WelcomeModal } from 'js/shared-components/WelcomeModal'
import { WhitelistModal } from 'js/shared-components/WhitelistModal'
import cn from 'js/util/cn'

import { Footer } from './Footer'
import { GeoBanBanner } from './GeoBanBanner'

interface PageContainerProps {
  children: React.ReactNode
  className?: string
}

export const PageContainer = ({ children, className }: PageContainerProps) => {
  const { isMobile } = useResponsiveness()

  const isGeoLocBlockedQuery = useIsGeoLocBlockedQuery()

  return (
    <div
      className={cn(
        'flex max-h-screen min-h-screen w-full flex-col items-center bg-black max-mobile:max-h-none mobile:h-screen',
        className,
      )}
    >
      <Navbar />
      {isGeoLocBlockedQuery.data && <GeoBanBanner />}
      <div className="flex size-full min-h-[calc(100dvh_-_92px)] flex-col overflow-y-scroll pb-8 max-mobile:pb-2">
        <WelcomeModal />
        <ConnectWalletModal />
        <WhitelistModal />
        {children}
      </div>
      {!isMobile && <Footer />}
    </div>
  )
}
