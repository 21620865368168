import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import type { AccountRequest } from 'zklighter-perps'

import { LIGHTER_CHAIN_ID } from 'js/constants/shared'
import {
  useAccountsQuery,
  useSetIsRegistered,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useAccountIndex, useShowOnboarding, useUserStore } from 'js/providers/user-store'
import { accountApi } from 'js/util/api/sdk'
import {
  clientRegistrationDataSchema,
  readLSSignature,
  writeLSSignature,
} from 'js/util/localStorage'
import { isMainnet } from 'js/util/util'
import { changePubKey, createClient, getNonce, initWASM } from 'js/zklighter-js-sdk/sdk'

import { Button } from './Button'
import { Explanation } from './ExplanationPopup'
import { Modal } from './Modal'
import { ToggleSwitch } from './ToggleSwitch'
import Icon from './uikit/Icon'
import WarningContainer from './WarningContainer'

const wait = () => new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))

const useChangePubKeyMutation = ({
  onSuccess,
  shouldRemember,
}: {
  onSuccess: () => void
  shouldRemember: boolean
}) => {
  const setIsRegistered = useSetIsRegistered()
  const { primaryWallet } = useDynamicContext()

  return useMutation({
    mutationFn: async ({
      accountIndex,
      message,
      nonce,
      pk,
    }: {
      accountIndex: number
      message: string
      nonce: number
      pk: string
      seed: string
    }) => {
      const apiKey = await accountApi
        .apikeys({ api_key_index: 0, account_index: accountIndex })
        .then((a) => a.api_keys[0]?.public_key)
        .catch(() => '')

      // verify if the public keys match; if so, consider this a successful recover; if not, change the public key
      if (apiKey === pk) {
        return
      }

      // public key generated from the seed does not match with the one from the server
      // this happens the first time the users logs in (public key is null)
      // or when the user uses changed the PK by force
      // performing a changePubKey replaces the old PK with the one generated by the FE
      const signature = '' + (await primaryWallet!.signMessage(message))
      await changePubKey({ accountIndex, signature, nonce })
    },
    onError: (_, { accountIndex }) => setIsRegistered(accountIndex, false),
    onSuccess: (_, { accountIndex, pk, seed }) => {
      setIsRegistered(accountIndex, true)

      if (shouldRemember) {
        writeLSSignature({
          ...(readLSSignature()?.data ?? {}),
          [accountIndex]: { pk, seed },
        })
      }
      onSuccess()
    },
  })
}

const useSignMessagesMutation = ({
  onSuccess,
}: {
  onSuccess: (
    mutationResult: {
      pk: string
      body: string
      nonce: number
      seed: string
    },
    accountIndex: number,
  ) => void
}) => {
  const userAddress = useUserAddress()
  const { primaryWallet } = useDynamicContext()

  return useMutation({
    mutationFn: async (accountIndex: number) => {
      // The seed is deterministically generated, and it'll serve as the private key of the FE
      const seed = await primaryWallet!.signMessage(
        'Access zkLighter account.\n\nOnly sign this message for a trusted client!\nChain ID: ' +
          LIGHTER_CHAIN_ID +
          '.',
      )

      if (!seed) {
        throw new Error('Something went wrong. Please try again later.')
      }

      const nonce = await getNonce({ accountIndex }).catch(() => null)

      if (nonce === null) {
        throw new Error('Something went wrong. Please try again later.')
      }

      // initialize WASM with private key
      await initWASM()
      const clientResData = await createClient({
        url: '', // url is not used by wasm
        seed,
        address: userAddress,
        chainId: LIGHTER_CHAIN_ID,
        accountIndex,
        nonce,
      })

      const { data, error } = clientRegistrationDataSchema.safeParse(clientResData)

      if (error) {
        throw new Error('Something went wrong. Please refresh the page to try again.')
      }

      return { ...data, seed, nonce }
    },
    onSuccess,
  })
}

export const useCreateAccountMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const userAddress = useUserAddress()

  return useMutation({
    mutationFn: async () => {
      if (!isMainnet()) {
        await accountApi.faucet({ l1_address: userAddress })
      }
      const params: AccountRequest = { by: 'l1_address', value: userAddress }
      let newAccount = await accountApi.account(params).catch(() => null)

      while (!newAccount || newAccount.accounts.length === 0) {
        await wait()
        newAccount = await accountApi.account(params).catch(() => null)
      }
    },
    onSuccess,
  })
}

export const ConnectWalletModal = () => {
  const [shouldRemember, setShouldRemember] = useState(true)
  const queryClient = useQueryClient()
  const accountsQuery = useAccountsQuery()
  const userAccount = useUserAccount()
  const accountIndex = useAccountIndex()
  const showOnboarding = useShowOnboarding()
  const patchyRef = useRef(false)

  const changePubKeyMutation = useChangePubKeyMutation({
    shouldRemember,
    onSuccess: () => setTimeout(closeModal, 1000),
  })
  const signMessagesMutation = useSignMessagesMutation({
    onSuccess: ({ body, pk, nonce, seed }, accountIndex) =>
      changePubKeyMutation.mutate({
        accountIndex,
        nonce,
        message: body,
        pk,
        seed,
      }),
  })
  const createAccountMutation = useCreateAccountMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })

  const closeModal = () => {
    createAccountMutation.reset()
    signMessagesMutation.reset()
    changePubKeyMutation.reset()
    patchyRef.current = false
    useUserStore.setState({ showOnboarding: false })
  }

  const error = (
    createAccountMutation.error ||
    signMessagesMutation.error ||
    changePubKeyMutation.error
  )?.message
    ? 'Something went wrong. Please try again later.'
    : undefined

  const signMessageMutate = signMessagesMutation.mutate
  useEffect(() => {
    if (showOnboarding && accountIndex !== null && !patchyRef.current) {
      patchyRef.current = true
      setTimeout(() => signMessageMutate(accountIndex), 0)
    }
  }, [showOnboarding, accountIndex, signMessageMutate])

  const Steps = [
    createAccountMutation.isPending ||
    createAccountMutation.isSuccess ||
    (!accountsQuery.isPending && !userAccount)
      ? {
          id: 0,
          title: 'Create account',
          description: isMainnet() ? '' : 'Your account will be credited with 500 USDC',
          warning: createAccountMutation.isPending
            ? "This might take a few minutes. Please don't close or refresh the window."
            : undefined,
          isLoading: createAccountMutation.isPending,
          isSigned: !!userAccount || createAccountMutation.isSuccess,
        }
      : {
          id: 0,
          title: 'Checking for an existing account',
          description: 'We are checking if there is any account associated to this wallet.',
          isLoading: accountsQuery.isPending,
          isSigned: accountsQuery.isSuccess,
        },
    {
      id: 1,
      title: 'Access Lighter account',
      description: 'Please sign this message to create your Lighter key and access your account.',
      isLoading: signMessagesMutation.isPending,
      isSigned: signMessagesMutation.isSuccess,
    },
    {
      id: 2,
      title: 'Verify ownership',
      description: 'Sign this message to verify ownership of your Lighter account.',
      isLoading: changePubKeyMutation.isPending,
      isSigned: changePubKeyMutation.isSuccess,
    },
  ]

  const disconnectWallet = useWallet().disconnect

  return (
    <Modal
      open={showOnboarding}
      title={userAccount ? 'Authenticate' : 'Create Account'}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          return
        }

        if (
          createAccountMutation.isPending ||
          signMessagesMutation.isPending ||
          changePubKeyMutation.isPending
        ) {
          return
        }

        closeModal()
      }}
    >
      <div className="flex w-full flex-col gap-4">
        <p className="typography-body-2 text-white">
          Signatures are used to access and verify your Lighter account. New users will receive two
          requests: one for generating a Lighter authentication key and another for registering with
          the Lighter exchange.
        </p>
        {Steps.map((step) => (
          <Step key={step.title} {...step} />
        ))}
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            <p className="typography-label-1 text-white">Remember me</p>
            <Explanation
              explanation={`Select this option only if you’re using a secure device you own. “Remember me” stores Lighter authentication key locally in your browser. The Lighter authentication key is different from the wallet private key, which never leaves the wallet itself. Using “Remember me” on a public computer may expose your Lighter key to others.`}
            />
          </div>
          <ToggleSwitch onClick={() => setShouldRemember((prev) => !prev)} isOn={shouldRemember} />
        </div>
        {!!error && (
          <WarningContainer>
            <p className="typography-body-2 text-white">{error}</p>
          </WarningContainer>
        )}
        <div className="flex w-full gap-4">
          {!userAccount && !isMainnet() && (
            <Button
              className="w-full"
              onClick={() => createAccountMutation.mutate()}
              disabled={accountsQuery.isPending}
              isLoading={createAccountMutation.isPending}
            >
              {createAccountMutation.isError ? 'Try again' : 'Send request'}
            </Button>
          )}
          {userAccount && (
            <Button
              isLoading={
                signMessagesMutation.isPending ||
                changePubKeyMutation.isPending ||
                changePubKeyMutation.isSuccess
              }
              className="w-full"
              onClick={() => signMessagesMutation.mutate(accountIndex!)}
            >
              {signMessagesMutation.isError || changePubKeyMutation.isError
                ? 'Try again'
                : 'Send request'}
            </Button>
          )}
          <Button
            color="grey"
            disabled={
              createAccountMutation.isPending ||
              signMessagesMutation.isPending ||
              changePubKeyMutation.isPending ||
              changePubKeyMutation.isSuccess
            }
            className="w-full"
            onClick={() => {
              disconnectWallet()
              closeModal()
            }}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const Step = ({
  title,
  description,
  warning,
  isLoading,
  isSigned,
}: {
  title: string
  description: string
  warning?: string
  isLoading: boolean
  isSigned: boolean
}) => (
  <div className="flex items-center gap-3 rounded-lg border bg-black-darkest p-4">
    {isLoading ? (
      <Icon icon="spinner" className="size-4" />
    ) : isSigned ? (
      <Icon icon="check" className="size-4 rounded-full border border-green-main text-green-main" />
    ) : (
      <Icon icon="check" className="size-4 rounded-full border-2 text-grey-main" />
    )}
    <div className="flex flex-col">
      <p className="typography-label-1 text-white">{title}</p>
      <p className="typography-label-1 text-white">{description}</p>
      {warning && <p className="typography-body-2 text-green-main">{warning}</p>}
    </div>
  </div>
)
