import { useState } from 'react'

import { WithdrawButton } from 'js/pages/withdraw/form'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useUserStore } from 'js/providers/user-store'
import { Button } from 'js/shared-components'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { FaucetModal } from 'js/shared-components/FaucetModal'
import { formatLeverage, formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'

import RequestFundsButton from '../navbar/wallet/RequestFundsButton'

export const AccountDetails = () => {
  const [isFaucetOpen, setIsFaucetOpen] = useState(false)
  const portfolioStats = useUserAccountPortfolioStats()
  const accountExistence = useAccountExistence()

  const Stats = {
    stat1: {
      title: 'Portfolio Value',
      value: portfolioStats === null ? '-' : formatUSD(portfolioStats.portfolioValue),
      explanation: `Buying power represents the total USD position you can open using your available balance with leverage .`,
    },
    stat2: {
      title: 'Available Balance',
      value: portfolioStats === null ? '-' : formatUSD(portfolioStats.availableBalance),
      explanation:
        'Available balance is the unused portion of your collateral in a trading account. It can be used for new trades or to cover potential losses, reducing the risk of liquidation.',
    },
    stat3: {
      title: 'Leverage',
      value: portfolioStats === null ? '-' : formatLeverage(portfolioStats.leverage),
      explanation: `Leverage allows you to control a larger market position with less capital. It can multiply both profits and losses, increasing the potential for higher returns as well as greater risks.`,
    },
    stat4: {
      title: 'Margin Usage',
      value: portfolioStats === null ? '-' : `${portfolioStats.initialMarginUsage.toFixed(2)}%`,
      explanation: `Margin usage refers to the portion of your account’s equity used to maintain open positions. High margin usage can lead to margin calls or liquidation.`,
    },
  }

  return (
    <>
      <FaucetModal open={isFaucetOpen} onOpenChange={setIsFaucetOpen} />
      <div className="flex flex-1 flex-col gap-3">
        <p className="typography-text-8 text-white max-mobile:px-3">Your Portfolio</p>
        <div className="mb-2 grid h-full grid-cols-[1fr_1fr] gap-2">
          {Object.values(Stats).map((stat) => (
            <div
              className="flex flex-col items-start justify-between gap-1 whitespace-nowrap rounded-lg bg-white/5 px-4 py-3"
              key={stat.title}
            >
              <Explanation
                explanation={stat.explanation}
                trigger={
                  <p className="typography-label-1 border-b border-white-opaque text-white-opaque">
                    {stat.title}
                  </p>
                }
              />
              <p className="typography-label-1 text-white">{stat.value}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2 px-1">
          <RequestFundsButton openModal={() => setIsFaucetOpen(true)} closeDropdown={() => {}} />
          <WithdrawButton />
          {accountExistence === 'KeysDontMatch' && (
            <Button
              className="w-full"
              onClick={() => useUserStore.setState({ showOnboarding: true })}
            >
              Authenticate
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
