import * as RadixTooltip from '@radix-ui/react-tooltip'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

export const TooltipArrow = forwardRef<
  ElementRef<typeof RadixTooltip.Arrow>,
  ComponentPropsWithoutRef<typeof RadixTooltip.Arrow>
>(({ width = 15, height = 9, ...rest }, forwardedRef) => (
  <RadixTooltip.Arrow
    ref={forwardedRef}
    width={width}
    height={height}
    className="fill-blue-grey"
    {...rest}
  />
))

TooltipArrow.displayName = 'TooltipArrow'
