import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface PositionLiquidationPriceCellProps {
  position: Position
}

const PositionLiquidationPriceCell = ({ position }: PositionLiquidationPriceCellProps) => {
  const market = useOrderBookMetasQuery().data[position.market_id]!

  return (
    <TableCell className="w-28 max-w-28">
      {position.liquidation_price ? formatMarketPrice(position.liquidation_price, market) : 'N/A'}
    </TableCell>
  )
}

export default PositionLiquidationPriceCell
