import { forwardRef, useState, type ButtonHTMLAttributes } from 'react'

import { TOKEN_LOGOS } from 'js/constants/shared'
import { useChangeMarket, useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import Icon from 'js/shared-components/uikit/Icon'
import { Popover, PopoverContent, PopoverTrigger } from 'js/shared-components/uikit/Popover'
import { formatLeverage } from 'js/util/formatting'
import { marginFractionToLeverage } from 'js/util/positions'

import { Markets } from './Markets'

export const MarketSelectorTrigger = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props, forwardedRef) => {
  const currentMarket = useCurrentMarket()

  const { isMobile } = useResponsiveness()

  return (
    <button
      ref={forwardedRef}
      {...props}
      className="group flex items-center gap-6 rounded-lg border bg-white-transparent px-4 py-3 max-mobile:gap-2 max-mobile:px-3 max-mobile:py-2"
    >
      <div className="flex items-center gap-2">
        <Icon
          icon={TOKEN_LOGOS[currentMarket.symbol as keyof typeof TOKEN_LOGOS]!}
          className="z-[1] size-6"
        />
        <div className="flex flex-col gap-1 max-mobile:gap-0">
          <div className="flex items-center gap-2">
            <p
              className="max-mobile:typography-body-1 typography-body-2 text-white max-mobile:max-w-12"
              data-testid="market-selector-trigger-market-name"
            >
              {currentMarket.symbol}
            </p>
            {!isMobile ? (
              <div
                className="rounded-full border border-semi-transparent bg-white-transparent bg-origin-border p-1 px-2"
                data-testid="market-selector-trigger-margin-fraction"
              >
                <p className="typography-body-1 !leading-3 text-white">
                  {formatLeverage(
                    marginFractionToLeverage(currentMarket.min_initial_margin_fraction),
                  )}
                </p>
              </div>
            ) : (
              <Icon
                icon="chevron"
                className={
                  'ml-1 size-3 text-white transition-all group-data-[state=open]:rotate-180'
                }
              />
            )}
          </div>
        </div>
      </div>
      {!isMobile && (
        <Icon
          icon="chevron"
          className={'ml-1 size-3 text-white transition-all group-data-[state=open]:rotate-180'}
        />
      )}
    </button>
  )
})

MarketSelectorTrigger.displayName = 'MarketSelectorTrigger'

export const MarketSelector = () => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const changeMarket = useChangeMarket()

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <MarketSelectorTrigger />
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={8}
        className="max-h-[min(500px,_100vh_-_150px)] w-[750px] overflow-y-scroll rounded-xl bg-black/30 shadow-giant-dark backdrop-blur-xl max-mobile:w-screen max-mobile:rounded-none max-mobile:border-0 max-mobile:border-b"
      >
        <Markets
          closeSelector={() => setPopoverOpen(false)}
          onClick={(marketId) => {
            setPopoverOpen(false)
            changeMarket(marketId)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
