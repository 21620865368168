import { type PublicPool } from 'zklighter-perps'

import { getPublicPoolName } from 'js/pages/public-pool/utils'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface PublicPoolNameCellProps {
  publicPool: PublicPool
}

const PublicPoolNameCell = ({ publicPool }: PublicPoolNameCellProps) => (
  <TableCell>
    <p className="typography-body-2 whitespace-nowrap text-white">
      {getPublicPoolName(publicPool)}
    </p>
  </TableCell>
)

export default PublicPoolNameCell
