import { type UseInfiniteQueryResult } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

export const useLastTableItemRef = (infiniteQueryResut: UseInfiniteQueryResult) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const { isFetchingNextPage, hasNextPage, fetchNextPage } = infiniteQueryResut

  return useCallback(
    (node: HTMLTableRowElement) => {
      if (isFetchingNextPage || !hasNextPage) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(([entry]) => {
        if (entry?.isIntersecting) {
          fetchNextPage()
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage],
  )
}
