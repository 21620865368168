import { Clickable } from './Clickable'
import Icon from './uikit/Icon'

interface NotificationPopupProps {
  children: React.ReactNode
  onClose?: () => void
}

export const NotificationPopup = ({ children, onClose }: NotificationPopupProps) => (
  <div className="group w-60 max-mobile:w-full">
    {!!onClose && (
      <Clickable
        className="absolute right-0 top-0 flex size-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full border bg-black-darkest group-hover:flex"
        onClick={onClose}
      >
        <Icon icon="x" className="size-4" />
      </Clickable>
    )}
    <div className="flex w-full flex-col items-center gap-2 rounded-lg border bg-black-darkest p-3 transition-all">
      {children}
    </div>
  </div>
)
