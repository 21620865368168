import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'

import { useUserAccount } from 'js/providers/hooks/useAccountQuery'

export const useIdentifyUser = () => {
  const userAccount = useUserAccount()

  useEffect(() => {
    if (!userAccount) {
      return Sentry.setUser(null)
    }

    Sentry.setUser({
      id: userAccount.index,
      l1_address: userAccount.l1_address,
      username: userAccount.name,
    })
  }, [userAccount])
}
