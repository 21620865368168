import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { enforceNumber } from 'js/util/util'

export const CurrencyInput = ({
  value,
  onChange,
  symbol,
  decimal,
}: {
  value: string
  onChange: (value: string) => void
  symbol: string
  decimal: number
}) => {
  return (
    <UIKitCurrencyInput
      label="Amount"
      helperText={`Amount of ${symbol} to short or long. This is the amount your position will increase or decrease when the order is filled, not  the final position amount.`}
      symbol={symbol}
      value={value}
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
