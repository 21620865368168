import { useEffect, useState } from 'react'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { CheckboxSelector } from 'js/shared-components/CheckboxSelector'
import { Clickable } from 'js/shared-components/Clickable'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'js/shared-components/uikit/Collapsible'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore, useOrderToPlaceUpdater } from '../../PlaceOrder'

import GainLossInput from './GainLossInput'

export const StopLossTakeProfit = () => {
  const [isSelected, setIsSelected] = useState(false)
  const [selectedGainOption, setSelectedGainOption] = useState('%')
  const [selectedLossOption, setSelectedLossOption] = useState('%')

  const currentMarket = useCurrentMarket()
  const decimal = currentMarket.price_decimals + currentMarket.size_decimals
  const orderInputs = useOrderInputStore()
  const { changeTakeProfitInputs, changeStopLossInputs } = orderInputs

  const orderData = useOrderToPlaceUpdater()
  const { stopLossInfo, takeProfitInfo } = orderData

  useEffect(() => {
    return () => {
      useOrderInputStore.setState({
        stopLossTakeProfit: {
          stopLossInput: '',
          stopLossValue: '',
          takeProfitInput: '',
          takeProfitValue: '',
        },
      })
    }
  }, [])

  return (
    <Collapsible
      onOpenChange={() => {
        if (isSelected) {
          useOrderInputStore.setState({
            stopLossTakeProfit: {
              stopLossInput: '',
              stopLossValue: '',
              takeProfitInput: '',
              takeProfitValue: '',
            },
          })
          setIsSelected(false)
        } else {
          setIsSelected(true)
        }
      }}
      open={isSelected}
    >
      <CollapsibleTrigger asChild>
        <Clickable className="flex items-center gap-3">
          <CheckboxSelector isSelected={isSelected} />
          <Tooltip>
            <TooltipTrigger asChild>
              <p className="typography-body-2 text-white">Take Profit / Stop Loss</p>
            </TooltipTrigger>
            <TooltipContent>
              <p className="typography-body-2 text-white">
                Creates simple independent take profit and stop loss market orders not tied to your
                position.
              </p>
            </TooltipContent>
          </Tooltip>
        </Clickable>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col gap-4 py-3">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 *:flex-1">
              <UIKitCurrencyInput
                label="TP Price"
                value={takeProfitInfo.takeProfitPrice}
                symbol="USD"
                onChange={(e) => {
                  if (enforceNumber(e, decimal))
                    changeTakeProfitInputs('takeProfitPrice', e.target.value)
                }}
                placeholder={Number(0).toFixed(decimal)}
              />
              <GainLossInput
                label="Gain"
                value={
                  selectedGainOption === '%'
                    ? takeProfitInfo.gainPercentage
                      ? takeProfitInfo.gainPercentage
                      : ''
                    : takeProfitInfo.gainAmount
                      ? takeProfitInfo.gainAmount
                      : ''
                }
                selectedOption={selectedGainOption}
                setSelectedOption={setSelectedGainOption}
                onChange={(e) => {
                  const inputType = selectedGainOption === '%' ? 'gainPercent' : 'gainUsd'
                  if (e.target.value === '-') {
                    changeStopLossInputs('lossPercent', '')
                    return
                  }
                  changeTakeProfitInputs(inputType, e.target.value)
                }}
                placeholder={Number(0).toFixed(selectedGainOption === '%' ? 2 : decimal)}
              />
            </div>
            <div className="flex items-center gap-2 *:flex-1">
              <UIKitCurrencyInput
                label="SL Price"
                value={stopLossInfo.stopLossPrice}
                symbol="USD"
                onChange={(e) => {
                  if (enforceNumber(e, decimal))
                    changeStopLossInputs('stopLossPrice', e.target.value)
                }}
                placeholder={Number(0).toFixed(decimal)}
              />
              <GainLossInput
                label="Loss"
                value={
                  selectedLossOption === '%'
                    ? stopLossInfo.lossPercentage
                      ? stopLossInfo.lossPercentage
                      : ''
                    : stopLossInfo.lossAmount
                      ? stopLossInfo.lossAmount
                      : ''
                }
                selectedOption={selectedLossOption}
                setSelectedOption={setSelectedLossOption}
                onChange={(e) => {
                  if (e.target.value === '-') {
                    changeStopLossInputs('lossPercent', '')
                    return
                  }
                  const inputType = selectedLossOption === '%' ? 'lossPercent' : 'lossUsd'
                  changeStopLossInputs(inputType, e.target.value)
                }}
                placeholder={Number(0).toFixed(selectedLossOption === '%' ? 2 : decimal)}
              />
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
