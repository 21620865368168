import { useMemo } from 'react'
import { OrderTypeEnum } from 'zklighter-perps'

import { useOrderBookMetasQuery } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface OrderTriggerCellProps {
  order: Order
}

const OrderTriggerCell = ({ order }: OrderTriggerCellProps) => {
  const market = useOrderBookMetasQuery().data[order.market_index]!
  const isShort = order.is_ask
  const formattedTriggerPrice = formatMarketPrice(order.trigger_price, market)

  const label = useMemo(() => {
    switch (order.type) {
      case OrderTypeEnum.StopLossLimit:
      case OrderTypeEnum.StopLoss: {
        return `Price ${isShort ? 'below' : 'above'} ${formattedTriggerPrice}`
      }
      case OrderTypeEnum.TakeProfitLimit:
      case OrderTypeEnum.TakeProfit: {
        return `Price ${isShort ? 'above' : 'below'} ${formattedTriggerPrice}`
      }
      default: {
        return '-'
      }
    }
  }, [isShort, order.type, formattedTriggerPrice])

  return (
    <TableCell>
      <p className="typography-body-1 whitespace-nowrap text-end text-white">{label}</p>
    </TableCell>
  )
}
export default OrderTriggerCell
