import cn from 'js/util/cn'

import Icon from './uikit/Icon'

interface SmallArrowProps {
  direction: 'up' | 'down' | 'right' | 'left'
  className?: string
  onClick?: () => void
}

export const SmallArrow = ({ direction, className, onClick }: SmallArrowProps) => (
  <div
    role="button"
    tabIndex={0}
    onClick={onClick}
    className={cn(
      'flex h-5 w-2.5 items-center text-white',
      { 'justify-end': direction === 'right', 'justify-start': direction !== 'right' },
      className,
    )}
  >
    <Icon
      icon="chevron"
      className={cn('h-5 w-2.5 transition-all', {
        'rotate-90': direction === 'left',
        'rotate-180': direction === 'up',
        '-rotate-90': direction === 'right',
        'rotate-0': direction === 'down',
      })}
    />
  </div>
)
