import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

export const DropdownMenuItem = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Item>,
  ComponentPropsWithoutRef<typeof RadixDropdownMenu.Item>
>(({ children, className, ...rest }, forwardedRef) => (
  <RadixDropdownMenu.Item
    ref={forwardedRef}
    className={cn(
      'typography-label-1 cursor-pointer px-3 py-2 text-white-opaque transition-all data-[highlighted]:bg-white/5 data-[highlighted]:text-white',
      className,
    )}
    {...rest}
  >
    {children}
  </RadixDropdownMenu.Item>
))

DropdownMenuItem.displayName = 'DropdownMenuItem'
