import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

import Icon from '../Icon'

export const DropdownMenuTrigger = forwardRef<
  ElementRef<typeof RadixDropdownMenu.Trigger>,
  ComponentPropsWithoutRef<typeof RadixDropdownMenu.Trigger>
>(({ children, className, ...rest }, forwardedRef) => (
  <RadixDropdownMenu.Trigger
    ref={forwardedRef}
    className={cn(
      'typography-body-2 rounded-lg border text-white',
      'bg-white-transparent hover:bg-grey-tbd/15 data-[state=open]:bg-grey-tbd/10',
      'group flex items-center justify-between gap-2 px-2 py-3 transition-all',
      className,
    )}
    {...rest}
  >
    {children}
    <Icon
      icon="chevron"
      className="size-2.5 rotate-0 transition-all group-data-[state=open]:rotate-180"
    />
  </RadixDropdownMenu.Trigger>
))

DropdownMenuTrigger.displayName = 'DropdownMenuTrigger'
