import ProtocolPublicPoolList from './ProtocolPublicPoolList'
import UserPublicPoolList from './UserPublicPoolList'

const PublicPoolsLists = () => (
  <div className="flex flex-col gap-3">
    <div className="flex flex-col overflow-scroll rounded-lg bg-white/5">
      <ProtocolPublicPoolList />
    </div>
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-9 overflow-scroll rounded-lg bg-gradient-to-b from-white/5 to-transparent max-mobile:min-h-60">
        <UserPublicPoolList />
      </div>
    </div>
  </div>
)

export default PublicPoolsLists
