import { CheckboxSelector } from 'js/shared-components/CheckboxSelector'

import { useOrderInputStore } from '../PlaceOrder'

export const ReduceOnly = () => {
  const { reduceOnly, changeReduceOnly } = useOrderInputStore()

  return (
    <div
      onClick={() => changeReduceOnly(!reduceOnly)}
      className="flex cursor-pointer items-center gap-3"
    >
      <CheckboxSelector isSelected={reduceOnly} />
      <p className="typography-body-2 text-white">Reduce Only</p>
    </div>
  )
}
