import { useEffect } from 'react'
import { toast } from 'sonner'

import { useAccountsStore, useUserAccountLiquidations } from 'js/providers/accounts-store'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useAccountIndex } from 'js/providers/user-store'
import { NotificationPopup } from 'js/shared-components/NotificationPopup'
import { LiquidationType } from 'js/types/user'
import { notifRead } from 'js/zklighter-js-sdk/sdk'

const notifCopy = {
  [LiquidationType.Partial]: {
    title: 'Partial Liquidation',
    text: 'You have been partially liquidated. Please add more funds to your account or decrease the size of your open positions.',
  },
  [LiquidationType.Full]: {
    title: 'Full Liquidation',
    text: 'You have been fully liquidated.',
  },
} as const

export const LiquidationNotifications = () => {
  const liquidations = useUserAccountLiquidations()
  const accountExistence = useAccountExistence()
  const accountIndex = useAccountIndex()

  useEffect(() => {
    if (!accountIndex || accountExistence === 'KeysDontMatch') {
      return
    }

    const lastLiquidation = liquidations[liquidations.length - 1]

    if (!lastLiquidation) {
      return
    }

    toast.custom(
      (toastId) => (
        <NotificationPopup
          onClose={() => {
            notifRead({
              accountIndex: accountIndex,
              liquidationIds: liquidations.slice(0, 100).map((liq) => liq.liquidation_id), // be has 100 limit
            }).catch(() => {})

            useAccountsStore.setState((state) => ({
              ...state,
              accounts: {
                ...state.accounts,
                [accountIndex]: {
                  ...state.accounts[accountIndex]!,
                  liquidations: [],
                },
              },
            }))

            toast.dismiss(toastId)
          }}
        >
          <div className="flex flex-col gap-2">
            <p className="typography-label-1 text-white">
              {notifCopy[lastLiquidation.liquidation_type].title}
            </p>
            <p className="typography-body-1 text-white">
              {notifCopy[lastLiquidation.liquidation_type].text}
            </p>
          </div>
        </NotificationPopup>
      ),
      { duration: Infinity, id: lastLiquidation.liquidation_id, position: 'top-right' },
    )
  }, [accountIndex, accountExistence, liquidations])

  return null
}
