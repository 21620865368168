import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'

interface PageSelectorProps {
  className?: string
  page: number
  setPage: (newPage: number) => void
  totalPages: number
  hasNextPage: boolean
}

export const PageSelector = ({
  className,
  page,
  setPage,
  totalPages,
  hasNextPage,
}: PageSelectorProps) => (
  <div
    className={cn('bottom-0 z-[1] flex flex-row items-center justify-center gap-3 p-2', className)}
  >
    {page > 0 ? (
      <Clickable
        onClick={() => setPage(page - 1)}
        className="rounded-lg border border-white/10 bg-white/10 p-2"
      >
        <Icon icon="chevron" className="size-3 rotate-90" />
      </Clickable>
    ) : (
      <div className="h-1 w-5" />
    )}
    {totalPages > 1 && (
      <>
        <p className="typography-body-1 text-white">{page + 1}</p>
        <p className="typography-body-1 mx-1 text-white">/</p>
        <p className="typography-body-1 text-white">{totalPages}</p>
      </>
    )}
    {hasNextPage ? (
      <Clickable
        onClick={() => setPage(page + 1)}
        className="rounded-lg border border-white/10 bg-white/10 p-2"
      >
        <Icon icon="chevron" className="size-3 -rotate-90" />
      </Clickable>
    ) : (
      <div className="h-1 w-5" />
    )}
  </div>
)
