import { type ChangeEventHandler } from 'react'

import { Explanation } from 'js/shared-components/ExplanationPopup'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Input from 'js/shared-components/uikit/Input'
import cn from 'js/util/cn'

// this should forwardRef and reuse the default input types
// but styled-components doesn't allow for that :/
interface CurrencyInputProps {
  // input props
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  selectedOption: string
  setSelectedOption: (option: string) => void

  // container props
  label: string
  helperText?: string
}

const GainLossInput = ({
  label,
  helperText,
  selectedOption,
  setSelectedOption,
  ...rest
}: CurrencyInputProps) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <p className="typography-body-2 text-white">{label}</p>
        {!!helperText && <Explanation explanation={helperText} />}
      </div>
      <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
        <Input
          {...rest}
          inputMode="decimal"
          className="h-[unset] w-full overflow-hidden rounded-none border-0 indent-0 text-sm placeholder:indent-0 placeholder:text-sm max-mobile:text-base max-mobile:placeholder:text-base"
        />
        <div className="flex items-center gap-2">
          <PercentOrUsdDropdown
            selectedOption={selectedOption}
            onSelect={(v) => setSelectedOption(v)}
          />
        </div>
      </div>
    </div>
  )
}

const PercentOrUsdDropdown = ({
  selectedOption,
  onSelect,
}: {
  selectedOption: string
  onSelect: (option: string) => void
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger className="typography-body-2 justify-normal gap-2 border-none bg-transparent p-0 text-white hover:bg-transparent">
      {selectedOption}
    </DropdownMenuTrigger>
    <DropdownMenuContent
      className={cn(
        'max-h-[200px] min-w-0 gap-1 overflow-y-scroll bg-semi-transparent backdrop-blur-[25px] max-mobile:max-h-[150px]',
      )}
    >
      <DropdownMenuItem
        onClick={() => onSelect('%')}
        className="typography-body-2 flex items-center gap-2 px-2 py-1 text-white data-[highlighted]:bg-white-transparent"
      >
        %
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => onSelect('$')}
        className="typography-body-2 flex items-center gap-2 px-2 py-1 text-white data-[highlighted]:bg-white-transparent"
      >
        $
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
)

export default GainLossInput
