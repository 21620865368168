import { constants } from './constants'
import { parseErrorFromCodegen, transactionApi } from './sdk'

export const postFeedback = async ({
  telegram,
  feedback,
}: {
  telegram: string
  feedback: string
}) => {
  try {
    await fetch(
      constants.FEEDBACK_FORM_URL +
        new URLSearchParams({
          [constants.FEEDBACK_EMAIL_FIELD]: telegram,
          [constants.FEEDBACK_CONTENT_FIELD]: feedback,
        }),
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    return true
  } catch (e) {
    console.error('Failed to submit feedback', e)
    return false
  }
}

export const postWaitlist = async ({
  telegram,
  discord = '',
  l1address,
  subject,
}: {
  telegram: string
  discord?: string
  l1address: string
  subject: string
}) => {
  try {
    if (!l1address) {
      l1address = 'anonymous'
    }

    const res = await fetch(
      constants.WAITLIST_FORM_URL +
        new URLSearchParams({
          [constants.WAITLIST_TELEGRAM_FIELD]: telegram,
          [constants.WAITLIST_L1_ADDRESS_FIELD]: l1address,
          [constants.WAITLIST_DISCORD_FIELD]: discord,
          [constants.WAITLIST_SUBJECT_FIELD]: subject,
        }),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    return res.ok
  } catch (e) {
    console.error('Failed to add waitlist', e)
    return true
  }
}

type Location = {
  country: string
}

export const getLocation = async () => {
  const response = await fetch('https://get.geojs.io/v1/ip/country.json')

  return (await response.json()) as Location
}

// use this to monitor if lighter picked up the tx you expect it to pick up
// and executed it without any errors (eventInfo.ae check)
export const pollLighterForL1Tx = async (l1TxHash: string) => {
  const sleep = () => new Promise((resolve) => setTimeout(resolve, 2000))

  while (true) {
    try {
      const tx = await transactionApi.txFromL1TxHash({ hash: l1TxHash })
      const evt = JSON.parse(tx.event_info) as { ae?: string }

      // Check if ae exists and has content
      if (evt.ae) {
        throw new Error(`TxFailed: ${evt.ae}`)
      }

      return tx
    } catch (e) {
      // Check for our specific error message prefix instead of exact match
      if (e instanceof Error && e.message.startsWith('TxFailed:')) {
        throw e
      }

      const err = await parseErrorFromCodegen(e)
      const isTransactionNotFound = err.code === 21500 || err.message === 'transaction not found'

      if (!isTransactionNotFound) {
        throw err // Throw unexpected errors
      }

      await sleep()
    }
  }
}
