import React, { type ComponentPropsWithoutRef, type MouseEvent } from 'react'

import cn from 'js/util/cn'

interface TabButtonProps {
  isSelected: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
  children: React.ReactNode
  setRef?: (element: HTMLElement | null, key: number) => void
  id?: number
}

export const TabButton = ({
  isSelected,
  onClick,
  children,
  className,
  setRef,
  id,
}: TabButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick != null) {
      onClick(e)
    }
  }

  return (
    <button
      ref={(element) => setRef && id !== undefined && setRef(element, id)}
      onClick={handleClick}
      className={cn(
        'h-10 min-h-10 w-full text-nowrap text-sm font-normal transition-all',
        'hover:text-white active:shadow-[0_0.5rem_1rem_rgb(0_0_0_/20%)]',
        {
          'bg-white-transparent text-white': isSelected,
          'bg-transparent text-white-opaque': !isSelected,
        },
        className,
      )}
    >
      {children}
    </button>
  )
}

export const TabBar = ({ className, ...rest }: ComponentPropsWithoutRef<'div'>) => (
  <div
    className={cn(
      'relative flex h-10 min-h-10 justify-center border-b bg-white-transparent',
      className,
    )}
    {...rest}
  />
)

interface SelectorIndicatorProps {
  numButtons: number
  selectedElemIdx: number
  left?: number | string
  width?: number | string
  className?: string
}

export const SelectorIndicator = ({
  numButtons,
  selectedElemIdx,
  left,
  width,
  className,
}: SelectorIndicatorProps) => (
  <div
    className={cn(
      'absolute bottom-0 transition-all',
      `after:relative after:m-auto after:block after:h-1 after:w-12 after:rounded-t-full after:bg-primary-blue-main`,
      className,
    )}
    style={{
      width: width ?? `calc(100% / ${numButtons})`,
      left: left ?? `calc(100% / ${numButtons} * ${selectedElemIdx})`,
    }}
  />
)
