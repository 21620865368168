import { forwardRef, type HTMLAttributes } from 'react'

import cn from 'js/util/cn'

import TableRow from './TableRow'

const TableHeaderRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...rest }, forwardedRef) => (
    <TableRow
      ref={forwardedRef}
      className={cn(
        'sticky top-0 z-[1] bg-[#10111b3b] backdrop-blur-md first:border-t-0',
        'header',
        className,
      )}
      {...rest}
    />
  ),
)

TableHeaderRow.displayName = 'TableHeaderRow'

export default TableHeaderRow
