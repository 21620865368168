import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'sonner'

import { usePublicPoolInfo } from 'js/providers/accounts-store'
import { useAccountsQuery } from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex } from 'js/providers/user-store'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import Toast from 'js/shared-components/uikit/Toast'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { enforceNumber } from 'js/util/util'
import { mintShares } from 'js/zklighter-js-sdk/sdk'

import { usePublicPoolQuery, usePublicPoolSharePrice } from '../utils'

import ActionButtonBlocker from './ActionButtonBlocker'

const useAvailableSharesToMint = () => {
  const accountsQuery = useAccountsQuery()
  const publicPoolQuery = usePublicPoolQuery()
  const portfolioStats = useUserAccountPortfolioStats()
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)

  // catch all for loading state
  if (
    publicPoolSharePrice === null ||
    !poolInfo ||
    !accountsQuery.data ||
    portfolioStats === null
  ) {
    return 0
  }

  const isOperator = accountsQuery.data.accounts.some(
    (account) => account.index === publicPoolQuery.data.index,
  )

  // operator_share can only increase if the operator is depositing
  if (isOperator) {
    return Math.floor(portfolioStats.availableBalance / publicPoolSharePrice)
  }

  // coming from BE as '5.000' -> 5% and needs to be converted to 0.05
  const minOperatorShareRate = Number(poolInfo.min_operator_share_rate) / 100

  /*
    min_operator_share_rate <= operator_shares / (total_shares + delta)
    min_operator_share_rate * (total_shares + delta) <= operator_shares
    total_shares + delta <= operator_shares / min_operator_share_rate
    delta <= (operator_shares / min_operator_share_rate) - total_shares
  */
  return Math.floor(
    Math.min(
      portfolioStats.availableBalance / publicPoolSharePrice,
      minOperatorShareRate === 0
        ? Infinity
        : poolInfo.operator_shares / minOperatorShareRate - poolInfo.total_shares,
    ),
  )
}

const DepositForm = () => {
  const accountsQuery = useAccountsQuery()
  const accountIndex = useAccountIndex()!
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const [value, setValue] = useState('')
  const portfolioStats = useUserAccountPortfolioStats()
  const { isMobile } = useResponsiveness()
  const mintSharesMutation = useMutation({
    mutationFn: mintShares,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Successfully deposited funds"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setValue(''),
  })
  const availableToMintShares = useAvailableSharesToMint()
  const availableToDeposit = availableToMintShares * (publicPoolSharePrice ?? 0)

  const isOperator =
    accountsQuery.data?.accounts.some((account) => account.index === publicPoolQuery.data.index) ??
    false

  if (!poolInfo) {
    return null
  }

  if (poolInfo.status === 1) {
    return (
      <p className="typography-body-2 text-white">
        This public pool is frozen, deposits are not allowed anymore.
      </p>
    )
  }

  const invalidAmountError = Math.round(Number(value) * 100) > Math.round(availableToDeposit * 100)

  const form = (
    <div className="flex h-full flex-col justify-between gap-10 max-mobile:gap-6">
      <div className="flex flex-col gap-2">
        <p className="typography-body-2 text-white">Deposit funds</p>
        <UIKitPriceInput
          label="Amount (USDC)"
          value={value}
          onChange={(e) => {
            if (enforceNumber(e, 2)) {
              setValue(e.target.value)
            }
          }}
          disabled={availableToDeposit < 1}
          buttonText="Max"
          onClick={() => setValue(availableToDeposit.toFixed(2))}
          placeholder={'0.00'}
        />
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">Available to deposit</p>
          <div className="flex items-center gap-2">
            <p className="typography-body-1 text-white">{formatUSD(availableToDeposit)}</p>
            {!isOperator &&
              portfolioStats !== null &&
              availableToDeposit + 1 <= portfolioStats.availableBalance && (
                <Explanation
                  explanation={`Because of the size of the pool, you can only deposit up to ${formatUSD(
                    availableToDeposit,
                  )}.`}
                />
              )}
          </div>
        </div>
        <p className={cn('typography-body-1 text-red-main', { 'opacity-0': !invalidAmountError })}>
          Insufficient funds
        </p>
      </div>
      <ActionButtonBlocker>
        <Button
          className="w-full"
          color="green"
          disabled={!value || invalidAmountError}
          isLoading={mintSharesMutation.isPending || publicPoolSharePrice === null}
          onClick={() =>
            mintSharesMutation.mutate({
              accountIndex,
              publicPoolIndex: publicPoolQuery.data.index,
              shareAmount: Math.min(
                availableToMintShares,
                Math.floor(Number(value) / (publicPoolSharePrice as number)),
              ),
            })
          }
        >
          Deposit
        </Button>
      </ActionButtonBlocker>
    </div>
  )

  if (!isMobile) {
    return form
  }

  return (
    <BottomAnimatedDropdown isOpen className="z-[101] min-h-[50%] p-6">
      {form}
    </BottomAnimatedDropdown>
  )
}

export default DepositForm
