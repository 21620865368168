import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { formatDate, fromUnixTime } from 'date-fns'
import { useMemo } from 'react'
import {
  WithdrawHistoryItemStatusEnum,
  type WithdrawHistoryCursor,
  type WithdrawHistoryRequest,
} from 'zklighter-perps'

import { useZkLighterL1 } from 'js/providers/hooks/useZkLighterL1'
import { useAccountIndex } from 'js/providers/user-store'
import { Clickable } from 'js/shared-components/Clickable'
import HeaderCell from 'js/shared-components/HeaderCell'
import { NoOrdersText } from 'js/shared-components/tables/NoOrdersText'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { transactionApi } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { useLastTableItemRef } from 'js/util/table'

import { useWithdrawStore } from './form'

const WithdrawsHeader = () => (
  <TableHeader>
    <TableHeaderRow>
      <HeaderCell title="Date" />
      <HeaderCell title="Amount" />
      <HeaderCell title="Status" />
    </TableHeaderRow>
  </TableHeader>
)

const WITHDRAW_STATUS_LABELS = {
  completed: 'Completed',
  claimable: 'Claimable',
  failed: 'Failed',
  pending: 'Pending',
  refunded: 'Refunded',
} as const

const REFRESH_INTERVAL = 30000

const serializeCursor = (cursor: WithdrawHistoryCursor): string => {
  return btoa(JSON.stringify(cursor))
}

export const WithdrawHistory = () => {
  const withdrawStore = useWithdrawStore()
  const accountIndex = useAccountIndex()
  const params: WithdrawHistoryRequest = useMemo(
    () => ({ account_index: accountIndex! }),
    [accountIndex],
  )
  const withdrawHistoryQuery = useInfiniteQuery({
    queryKey: ['withdrawHistory', params],
    queryFn: ({ pageParam }: { pageParam?: WithdrawHistoryCursor }) => {
      const cursorString = pageParam ? serializeCursor(pageParam) : undefined

      return transactionApi.withdrawHistory({
        ...params,
        cursor: cursorString,
      })
    },
    initialPageParam: undefined as WithdrawHistoryCursor | undefined,
    getNextPageParam: (lastPage) => {
      // Don't fetch next page if cursor has -1 values
      if (lastPage.cursor?.secure_id === '-1' && lastPage.cursor?.fast_id === '-1') {
        return undefined
      }
      return lastPage.cursor || undefined
    },
    enabled: !!accountIndex,
    refetchInterval: REFRESH_INTERVAL,
  })
  const lastWithdrawRef = useLastTableItemRef(withdrawHistoryQuery)

  const withdraws = useMemo(
    () => (withdrawHistoryQuery.data?.pages ?? []).map(({ withdraws }) => withdraws).flat(),
    [withdrawHistoryQuery.data],
  )

  const { getPendingBalance } = useZkLighterL1()
  const pendingBalanceQuery = useQuery({
    queryKey: ['pendingBalance'],
    queryFn: () => getPendingBalance(),
    select: (pendingBalance) => Number(pendingBalance),
    refetchInterval: REFRESH_INTERVAL * 2,
    enabled: !!withdraws.length && withdraws.some((withdraw) => withdraw.status === 'claimable'),
  })

  const displayStatuses = useMemo(() => {
    let processedAmount = 0

    return withdraws.reduce(
      (statuses: Record<string, WithdrawHistoryItemStatusEnum | 'completed'>, withdraw) => {
        if (withdraw.status === 'claimable') {
          processedAmount += Number(withdraw.amount)

          if (processedAmount > (pendingBalanceQuery.data ?? Number.MAX_VALUE)) {
            statuses[withdraw.id] = 'completed'
          } else {
            statuses[withdraw.id] = 'claimable'
          }
        } else {
          statuses[withdraw.id] = withdraw.status
        }
        return statuses
      },
      {},
    )
  }, [withdraws, pendingBalanceQuery.data])

  if (withdrawHistoryQuery.isLoading) {
    return (
      <Table>
        <WithdrawsHeader />
        <TableBody>
          <TableLoader rows={4} columns={3} />
        </TableBody>
      </Table>
    )
  }

  if (!withdraws.length) {
    return <NoOrdersText type="withdraws" />
  }

  return (
    <Table>
      <WithdrawsHeader />
      <TableBody>
        {withdraws.map((withdraw, index) => (
          <TableRow
            key={withdraw.id}
            ref={index === withdraws.length - 1 ? lastWithdrawRef : undefined}
          >
            <TableCell>
              <p className="typography-body-1 text-white">
                {formatDate(fromUnixTime(withdraw.timestamp), 'yyyy-MM-dd HH:mm:ss')}
              </p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-white">{formatUSD(withdraw.amount)}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-left text-white">
                {displayStatuses[withdraw.id] === 'claimable' ? (
                  <Clickable color="blue" onClick={() => withdrawStore.openModal()}>
                    {WITHDRAW_STATUS_LABELS['claimable']}
                  </Clickable>
                ) : (
                  WITHDRAW_STATUS_LABELS[
                    (displayStatuses[withdraw.id] ?? 'pending') as WithdrawHistoryItemStatusEnum
                  ]
                )}
              </p>
            </TableCell>
          </TableRow>
        ))}
        {withdrawHistoryQuery.isFetchingNextPage && <TableLoader rows={1} columns={3} />}
      </TableBody>
    </Table>
  )
}
