import { useRef } from 'react'

import { TOKEN_DESCRIPTIONS, TOKEN_LOGOS, USDC_SYMBOL } from 'js/constants/shared'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import Icon from 'js/shared-components/uikit/Icon'
import { formatLeverage } from 'js/util/formatting'
import { marginFractionToLeverage } from 'js/util/positions'

const MARGIN_FRACTION_TICK = 100

export const MarketDetails = () => {
  const currentMarket = useCurrentMarket()
  const {
    symbol,
    min_base_amount,
    min_quote_amount,
    price_decimals,
    default_initial_margin_fraction,
    closeout_margin_fraction,
    maintenance_margin_fraction,
  } = currentMarket
  const description = TOKEN_DESCRIPTIONS[symbol as keyof typeof TOKEN_DESCRIPTIONS]
  const detailsRef = useRef<HTMLDivElement>(null)
  const tradingRules = [
    {
      title: 'Market Name',
      value: `${symbol}`,
    },
    {
      title: `Min ${symbol} Amount`,
      value: parseFloat(min_base_amount),
    },
    {
      title: `Min ${USDC_SYMBOL} Amount`,
      value: parseFloat(min_quote_amount),
    },
    {
      title: 'Price Steps',
      value: (10 ** -price_decimals).toString(),
    },
    {
      title: 'Max Leverage',
      value: formatLeverage(marginFractionToLeverage(default_initial_margin_fraction)),
    },
    {
      title: 'Initial Margin Fraction',
      value: `${default_initial_margin_fraction / MARGIN_FRACTION_TICK}%`,
    },
    {
      title: 'Maintenance Margin Fraction',
      value: `${maintenance_margin_fraction / MARGIN_FRACTION_TICK}%`,
    },
    {
      title: 'Close Out Margin Fraction',
      value: `${closeout_margin_fraction / MARGIN_FRACTION_TICK}%`,
    },
  ]

  return (
    <div className="flex max-h-full flex-col gap-6 p-8 max-mobile:relative max-mobile:max-h-full max-mobile:p-4">
      <div className="flex items-center gap-3">
        <Icon icon={TOKEN_LOGOS[symbol as keyof typeof TOKEN_LOGOS]!} className="size-8" />
        <p className="typography-label-1 text-white">{symbol}</p>
      </div>
      <div className="flex gap-6 overflow-y-scroll max-mobile:flex-col">
        <p className="typography-label-1 flex-[1] text-white">{description}</p>
        <div className="flex w-full flex-[1] flex-col gap-2 mobile:max-mobile:overflow-y-scroll">
          {tradingRules.map((rule, idx) => (
            <div
              className="relative flex w-full justify-between border-b-[0.5px] border-grey-light pb-2"
              key={`${rule.title}-${rule.value}-${idx}`}
            >
              <p className="typography-body-2 text-white-opaque">{rule.title}:</p>
              <p className="typography-body-2 text-white">{rule.value}</p>
            </div>
          ))}
          <div ref={detailsRef} />
        </div>
      </div>
    </div>
  )
}
