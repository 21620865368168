import { useState } from 'react'
import { toast } from 'sonner'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postWaitlist } from 'js/util/api/api'

import { Button } from './Button'
import { Clickable } from './Clickable'
import { Modal } from './Modal'
import Icon from './uikit/Icon'
import Input from './uikit/Input'
import Toast from './uikit/Toast'

export const PointsModal = () => {
  const userAddress = useUserAddress()
  const [open, setOpen] = useState(false)
  const [telegram, setTelegram] = useState('')
  const [l1address, setL1Address] = useState(userAddress)

  const onSubmit = async () => {
    const res = await postWaitlist({ telegram, l1address, subject: 'points' })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Request submitted successfully"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      setOpen(false)
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description="Something went wrong, please try again later"
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
  }

  return (
    <>
      <Clickable className="flex items-center gap-2" onClick={() => setOpen(true)}>
        <Icon icon="trophy" className="size-5" />
        <p className="typography-label-1 underline underline-offset-4 max-mobile:hidden">Points</p>
      </Clickable>
      <Modal
        title="Lighter Points"
        open={open}
        onOpenChange={(newOpen) => {
          setOpen(newOpen)

          if (!newOpen) {
            setTelegram('')
            setL1Address('')
          }
        }}
      >
        <div className="flex flex-col gap-6">
          <p className="typography-label-1 text-white">
            We are currently in Private Beta! To request access and join our Points program as an
            early adopter, please submit your Telegram or Discord and we will get back to you.
          </p>
          <Input
            value={telegram}
            placeholder="Telegram"
            onChange={(e) => setTelegram(e.target.value)}
            required
            className="bg-white-transparent"
          />
          <Input
            value={l1address}
            placeholder="L1 Address"
            onChange={(e) => setL1Address(e.target.value)}
            className="bg-white-transparent"
          />
          <div className="flex justify-end gap-4">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button disabled={!l1address || !telegram} onClick={onSubmit} className="w-fit">
                  Submit
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="typography-body-2 text-white">Please fill out all fields</p>
              </TooltipContent>
            </Tooltip>
            <Button color="grey" className="w-fit" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
