import cn from 'js/util/cn'

import TableCell from './uikit/table/TableCell'

interface HeaderCellProps {
  title?: string
  subTitle?: string
  symbol?: string
  className?: string
}

const HeaderCell = ({ title, subTitle, symbol, className }: HeaderCellProps) => (
  <TableCell>
    <div className={cn('flex items-center gap-1 text-white-opaque', className)}>
      <div className="flex flex-col items-end justify-center">
        <p className="typography-body-1 whitespace-nowrap text-white">{title}</p>
        {!!subTitle && <p className="typography-body-1 whitespace-nowrap text-white">{subTitle}</p>}
      </div>
      {!!symbol && (
        <div className="typography-body-1 h-6 rounded-lg bg-grey-light px-2 py-1 text-white">
          {symbol}
        </div>
      )}
    </div>
  </TableCell>
)

export default HeaderCell
