import cn from 'js/util/cn'

import TableCell from '../uikit/table/TableCell'

interface SideCellProps {
  is_short: boolean
}

const SideCell = ({ is_short }: SideCellProps) => (
  <TableCell>
    <p
      className={cn('typography-body-1', {
        'text-green-main': !is_short,
        'text-red-main': is_short,
      })}
    >
      {is_short ? 'Short' : 'Long'}
    </p>
  </TableCell>
)

export default SideCell
