import * as RadixTooltip from '@radix-ui/react-tooltip'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react'

import cn from 'js/util/cn'

export const TooltipContent = forwardRef<
  ElementRef<typeof RadixTooltip.Content>,
  ComponentPropsWithoutRef<typeof RadixTooltip.Content>
>(({ sideOffset = 10, side = 'bottom', arrowPadding = 4, className, ...rest }, forwardedRef) => (
  <RadixTooltip.Portal>
    <RadixTooltip.Content
      ref={forwardedRef}
      sideOffset={sideOffset}
      side={side}
      arrowPadding={arrowPadding}
      className={cn(
        'z-50 min-w-[200px] max-w-[350px] whitespace-pre-line rounded-lg bg-blue-grey px-4 py-2',
        'text-start data-[state=closed]:animate-fadeOut data-[state=delayed-open]:animate-fadeIn',
        className,
      )}
      {...rest}
    />
  </RadixTooltip.Portal>
))

TooltipContent.displayName = 'TooltipContent'
