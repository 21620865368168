import { useState } from 'react'

import { useResponsiveness } from 'js/ResponsivenessProvider'
import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { FundingHistoryTable } from 'js/shared-components/tables/FundingHistoryTable'
import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { TradeHistoryTable } from 'js/shared-components/tables/TradeHistoryTable'
import cn from 'js/util/cn'

import { usePublicPoolQuery } from '../utils'

const tabs = [
  { title: 'Positions', id: 0 },
  { title: 'Open Orders', id: 1 },
  { title: 'Order History', id: 2 },
  { title: 'Trade History', id: 3 },
  { title: 'Funding History', id: 4 },
]

const PublicPoolTables = () => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(tabs[0]!)

  const publicPoolQuery = usePublicPoolQuery()
  const { isMobile } = useResponsiveness()

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-hidden bg-black">
      <Backdrop
        fullScreen
        className="top-0 h-full"
        isVisible={isSelectorOpen}
        onClick={() => setIsSelectorOpen(false)}
      />
      {!isMobile && (
        <TabBar className="h-[unset] min-h-[unset] rounded-lg border-none bg-white/5 p-1 max-mobile:gap-4 max-mobile:bg-transparent max-mobile:p-0">
          <div className="relative flex w-full">
            <SelectorIndicator
              numButtons={Object.values(tabs).length}
              selectedElemIdx={selectedTab.id}
              className="bottom-px after:h-0.5 after:w-10 after:rounded-full"
            />
            {tabs.map((tab) => (
              <TabButton
                id={tab.id}
                key={tab.id}
                isSelected={false}
                onClick={() => setSelectedTab(tab)}
                className={cn(
                  'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all',
                  {
                    'text-white bg-white/10': selectedTab === tab,
                    'text-white-opaque bg-transparent': selectedTab !== tab,
                  },
                )}
              >
                {tab.title}
              </TabButton>
            ))}
          </div>
        </TabBar>
      )}
      {isMobile && (
        <div className="flex flex-1 gap-6 rounded-lg bg-white/10 px-4">
          <div className="w-full">
            <div
              className="flex w-full items-center justify-between"
              onClick={() => setIsSelectorOpen((prev) => !prev)}
            >
              <TabButton isSelected={false}>
                <p className="typography-label-1 text-left text-white">{selectedTab.title}</p>
              </TabButton>
              <SmallArrow direction="down" />
            </div>
            <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
              {tabs.map((tab) => (
                <TabButton
                  key={tab.id}
                  className="h-[unset] py-4"
                  isSelected={false}
                  onClick={() => {
                    setSelectedTab(tab)
                    setIsSelectorOpen(false)
                  }}
                >
                  <p className="typography-label-1 text-white">{tab.title}</p>
                </TabButton>
              ))}
            </BottomAnimatedDropdown>
          </div>
        </div>
      )}
      <div className="h-[400px] overflow-scroll rounded-lg bg-white/5 max-mobile:h-[200px] max-mobile:border">
        {selectedTab.id === 0 && <PositionsTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 1 && <ActiveOrderTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 2 && <OrderHistoryTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 3 && <TradeHistoryTable accountIndex={publicPoolQuery.data.index} />}
        {selectedTab.id === 4 && <FundingHistoryTable accountIndex={publicPoolQuery.data.index} />}
      </div>
    </div>
  )
}
export default PublicPoolTables
