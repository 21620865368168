import { type Dispatch, type SetStateAction } from 'react'

import { OrderDirections } from 'js/constants/shared'
import { useOrderInputStore } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { Button } from 'js/shared-components'

interface TradeFooterProps {
  setTradeModalOpen: Dispatch<SetStateAction<boolean>>
}

export const TradeFooter = ({ setTradeModalOpen }: TradeFooterProps) => {
  const changeDirection = useOrderInputStore().changeDirection

  return (
    <div className="fixed inset-x-0 bottom-0 z-[2] flex h-20 w-full items-center bg-black p-6 shadow-dark-top max-mobile:gap-2 max-mobile:border-t max-mobile:p-3">
      <div className="flex w-full justify-around gap-2">
        <Button
          color="green"
          onClick={() => {
            setTradeModalOpen(true)
            changeDirection(OrderDirections.Long)
          }}
          className="w-full max-w-[350px]"
        >
          Buy/Long
        </Button>
        <Button
          color="red"
          onClick={() => {
            setTradeModalOpen(true)
            changeDirection(OrderDirections.Short)
          }}
          className="w-full max-w-[350px]"
        >
          Sell/Short
        </Button>
      </div>
    </div>
  )
}
