import { useQuery } from '@tanstack/react-query'

export const useHealthQuery = () =>
  useQuery({
    queryKey: ['health'],
    queryFn: async () => {
      try {
        if (!navigator.onLine) {
          return { online: false, serverOk: false }
        }

        const res = await fetch(import.meta.env.VITE_REST_API_BASE)
        return { online: true, serverOk: res.ok }
      } catch (error) {
        console.log(error)
        await new Promise((resolve) => setTimeout(resolve, 3000)) // wait for navigator to update
        return { online: navigator.onLine, serverOk: false }
      }
    },
    refetchInterval: 5000,
  })
