import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react'

const getIsMobile = () => window.matchMedia(`screen and (max-width: 668px)`).matches

const ResponsivenessContext = createContext({
  isMobile: getIsMobile(),
})

const ResponsivenessProvider = ({ children }: PropsWithChildren) => {
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const responsivenessContextValue = useMemo(() => ({ isMobile }), [isMobile])

  useEffect(() => {
    const onResize = () => setIsMobile(getIsMobile())

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <ResponsivenessContext.Provider value={responsivenessContextValue}>
      {children}
    </ResponsivenessContext.Provider>
  )
}

export const useResponsiveness = () => useContext(ResponsivenessContext)

export default ResponsivenessProvider
